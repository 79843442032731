import React, { useEffect, useState } from 'react';
import styles from './TextArea.module.scss';
import classNames from 'classnames';


const TextArea = (props) => {
  const {
    value,
    label,
    placeholder,
    hint = '',
    hintRight = '',
    disabled,
    maxLength,
    onChange = () => {},
  } = props;

  const [ smallLabel, setSmallLabel ] = useState(false);
  const [ valueInternal, setValueInternal ] = useState(value || '');

  useEffect(() => {
    setValueInternal(value);
    setSmallLabel(Boolean(value));
  }, [ value ]);

  return (
    <div className={styles.textarea}>

      { (label && !placeholder) && (
        <label className={
          classNames(
            styles.label,
            { [styles.small]: smallLabel },
            { [styles.disabled]: disabled },
          )
        }
        >
          { label }
        </label>
      ) }

      <textarea
        placeholder={placeholder}
        className={classNames({
          [styles.hasHintRight]: Boolean(hintRight),
        })}
        disabled={disabled}
        maxLength={maxLength}
        value={valueInternal}
        onChange={(event) => {
          setValueInternal(event.target.value);
          onChange(event.target.value);
        }}
        onFocus={() => {
          setSmallLabel(true);
        }}
        onBlur={() => {
          setSmallLabel(Boolean(valueInternal));
        }}
      />

      { hintRight && (
        <div className={styles.hintRight}>{ hintRight }</div>
      ) }

      { hint && <div className={styles.hint}>{ hint }</div> }
    </div>
  );
};

TextArea.defaultProps = {
  disabled: false,
};

export default TextArea;
