import React, {
  memo, useEffect, useState,
} from 'react';
import styles from './RmpResult.module.scss';

import { createPortal } from 'react-dom';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { initSubmitContentFeedback, submitContentFeedback } from 'store/actions';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { isValid } from 'utils/numbers';
import { sortAlphabetically } from 'utils/strings';
import { useBreakpoint } from 'utils/hooks';
import { setDecimalSeparator } from 'utils/userpreference';
import {
  getAverageResults,
  getStrongResults,
  getWeakResults, resultIsStrong, resultIsWeak, RMP_FIRST_MARKER_POSITION,
} from 'utils/rmp';

import {
  InfoTitle, Button, Modal, BipolarDiagram, Callout, ShowResultsButton,
} from 'ui/basic';
// eslint-disable-next-line import/no-cycle
import { RmpReport } from 'ui/molecules';
import { DownloadResultButton } from '../DownloadResultButton';
import RmpResultItem from './RmpResultItem';
import RmpResultSkeleton from './RmpResultSkeleton';
import RmpResultTable from './RmpResultTable';

const RmpResult = memo((props) => {
  const {
    results = [],
    openReport,
    showReportButton = true,
    footerText,
    noDataText,
    showPendingState,
    requestFeedback = true,
    showExportIcon,
    showInfoTitle = true,
    exportView,
    onExport = () => {},
    userId,
    managerControlled,
    sharedWithUser,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();
  const bp = useBreakpoint();

  const [ showModal, setShowModal ] = useState();
  const [ modalHeader, setModalHeader ] = useState();
  const [ modalSubHeader, setModalSubHeader ] = useState();
  const [ modalScrollable, setModalScrollable ] = useState();
  const [ modalContent, setModalContent ] = useState();
  const [ modalPrimaryButtonTitle, setModalPrimaryButtonTitle ] = useState();
  const [ showModalFeedback, setShowModalFeedBack ] = useState();
  const [ modalData, setModalData ] = useState();
  const [ reportPageVisible, setReportPageVisible ] = useState(false);
  const [ currentDimensionIndex, setCurrentDimensionIndex ] = useState();

  const showRange = results && results[0] && Array.isArray(results[0].range);
  const showResult = results && results[0] && isValid(results[0].result);

  const rmp = useSelector((state) => state.assessments.table[ASSESSMENT_TYPES.RMP]);

  const feedbackSubmittedSuccessfully = useSelector((state) => state.survey?.success);

  const strongResults = getStrongResults(results);
  const weakResults = getWeakResults(results);
  const averageResults = getAverageResults(results);

  const getSubHeader = (dimensionName, dimensionValue) => {
    if (resultIsStrong(dimensionValue)) {
      return translate(
        'rmp_dimension_strong__short_descr',
        [ '{{dimension}}', dimensionName ],
      );
    } if (resultIsWeak(dimensionValue)) {
      return translate(
        'rmp_dimension_weak__short_descr',
        [ '{{dimension}}', dimensionName ],
      );
    }
    return translate(
      'rmp_dimension_average__short_descr',
      [ '{{dimension}}', dimensionName ],
    );
  };

  const getCalloutText = (value, dimensionId) => {
    if (resultIsStrong(value)) {
      return translate(`rmp_report_${dimensionId}_strong_details`);
    } if (resultIsWeak(value)) {
      return translate(`rmp_report_${dimensionId}_weak_details`);
    }
    return translate(`rmp_report_${dimensionId}_average_details`);
  };

  const getResults = () => {
    if (showRange) {
      return results;
    }

    // sort descending
    return results.sort((item1, item2) => sortAlphabetically(
      Math.abs(item2.result),
      Math.abs(item1.result),
    ));
  };

  useEffect(() => {
    if (openReport) {
      setReportPageVisible(true);
    }
  }, [ openReport ]);

  const renderModalContent = (resultItem) => (
    <div className={styles.infoModalResult}>
      <div className={styles.reportDiagram}>
        <div className={styles.header}>
          <span>{ translate('big5_report_facet_result') }</span>
          <span>{ setDecimalSeparator(resultItem.result, 2) }</span>
        </div>

        <BipolarDiagram
          score={resultItem.result}
          showMarkers
        />

        <div className={styles.footer}>
          <span className={resultItem.result < -RMP_FIRST_MARKER_POSITION
            ? styles.blue
            : null}
          >
            { translate('rmp_results_scale_min') }
          </span>
          <span className={resultItem.result > RMP_FIRST_MARKER_POSITION
            ? styles.blue
            : null}
          >
            { translate('rmp_results_scale_max') }
          </span>
        </div>
      </div>

      <div className={styles.callout}>
        <Callout trianglePosition='center'>
          { getCalloutText(resultItem.result, resultItem.id) }
        </Callout>
      </div>

      <RmpResultTable
        dimensionId={resultItem.id}
        score={resultItem.result}
      />
    </div>
  );

  // Rerender modal content on resize
  useEffect(() => {
    if (!modalData?.resultItem) {
      return;
    }

    setModalContent(renderModalContent(modalData.resultItem));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bp.isS, bp.isXs, modalData?.index ]);


  if (!results && (rmp.resultsPending === undefined)) {
    return (
      <div className={styles.rmpResult}>
        <RmpResultSkeleton />
      </div>
    );
  }

  return (
    <div className={classNames(styles.rmpResult)}>
      <div className={styles.container}>
        <div className={styles.mainHeader}>
          { (exportView || !showInfoTitle)
            ? (
              <span className='bluTypeLabelL'>
                { translate('rmp_results_title') }
              </span>
            ) : (
              <InfoTitle
                title={translate('rmp_results_title')}
                onClick={() => {
                  setModalHeader(translate('rmp_results_info_title'));
                  setModalSubHeader(null);
                  setModalScrollable(false);
                  setModalPrimaryButtonTitle(null);
                  setShowModalFeedBack(false);
                  setModalContent(translate('rmp_results_info_description'));

                  setShowModal(true);
                }}
              />
            ) }

          { (!exportView && showExportIcon && !rmp.resultsPending && !noDataText) && (
            <DownloadResultButton onExport={onExport} />
          ) }
        </div>

        { noDataText && <div className={styles.noDataText}>{ noDataText }</div> }

        { showPendingState && rmp.resultsPending && (
          <div className={styles.pendingText}>
            { translate('rmp_results_pending_copy') }
          </div>
        ) }

        { !noDataText && (
          <>
            { managerControlled && (
              <ShowResultsButton
                userId={userId}
                assessmentId={ASSESSMENT_TYPES.RMP}
                sharedWithUser={sharedWithUser}
              />
            ) }

            <div className={styles.rows}>
              { getResults()
              .filter((resultItem) => isValid(resultItem.result) || resultItem.range)
              .map((resultItem, index) => (
                <RmpResultItem
                  key={`dimension-${resultItem.id}`}
                  exportView={exportView}
                  dimensionId={resultItem.id}
                  dimension={resultItem.name}
                  score={resultItem.result}
                  range={resultItem.range}
                  onInfoClick={() => {
                    if (!showReportButton) {
                      setModalHeader(translate(`rmp_results_details_title_${resultItem.id}`));
                      setModalContent(
                        translate(`rmp_results_details_description_${resultItem.id}`),
                      );

                      setShowModal(true);
                      return;
                    }

                    setCurrentDimensionIndex(index);

                    setModalHeader(translate(`rmp_results_details_title_${resultItem.id}`));
                    setModalSubHeader(getSubHeader(translate(`rmp_results_label_${resultItem.id}`)
                        || resultItem.name, resultItem.result));
                    setModalScrollable(true);
                    setModalPrimaryButtonTitle(translate('go_to_report_lbl'));

                    setShowModalFeedBack(true);
                    setModalData({ resultItem, index });
                    setShowModal(true);
                  }}
                />
              )) }
            </div>

            { (showResult && !showRange && showReportButton && !exportView) && (
              <Button
                size='S'
                looks='secondary'
                onClick={() => setReportPageVisible(true)}
              >
                { translate('rmp_results_button_showreport') }
              </Button>
            ) }

            { footerText && <div className={styles.footerText}>{ footerText }</div> }

            <div className={styles.footerText}>
              <span
                role='presentation'
                onClick={() => {
                  setModalHeader(translate('rmp_results_info_title_copyright'));
                  setModalSubHeader(null);
                  setModalScrollable(false);
                  setModalPrimaryButtonTitle(null);
                  setShowModalFeedBack(false);
                  setModalContent(translate('rmp_results_info_description_copyright'));

                  setShowModal(true);
                }}
              >
                { translate('rmp_results_copyrightlink') }
              </span>
            </div>
          </>
        ) }
      </div>

      { showModal && (
        <Modal
          header={modalHeader}
          subHeader={modalSubHeader}
          primaryButtonTitle={modalPrimaryButtonTitle}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
          onClose={() => {
            dispatch(initSubmitContentFeedback());
            setShowModal(false);
            setModalData();
          }}
          onConfirm={modalScrollable
            ? (() => {
              setShowModal(false);
              setModalData();
              setReportPageVisible(true);
            })
            : (() => {
              setShowModal(false);
              setModalData();
            })}
          feedbackSubmitted={feedbackSubmittedSuccessfully}
          onFeedbackSubmit={(requestFeedback && showModalFeedback)
            ? ((feedback) => {
              dispatch(submitContentFeedback({
                contentId: results[currentDimensionIndex].id,
                contentType: 'recommendation',
                helpful: feedback === 'Yes',
              }));
            })
            : null}
        >
          { modalContent }
        </Modal>
      ) }

      { reportPageVisible && createPortal(
        <RmpReport
          strongResults={strongResults}
          weakResults={weakResults}
          averageResults={averageResults}
          onClose={() => setReportPageVisible(false)}
        />,
        document.body,
      ) }
    </div>
  );
});

export default RmpResult;
