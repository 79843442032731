// Adapted from src/features/+balancedYou/pages/MyOrganization
import React, { useEffect } from 'react';
import styles from './MyOrganization.module.scss';

// 3RD PARTY

// OTHER COMPONENTS
import { OrgWellBeingResult } from 'ui/molecules';
import { GarminOrgResult } from 'features/+garmin/ui/molecules/GarminOrgResult';

// UTILS
import { useTranslate } from 'utils/translator';
import { isValid } from 'utils/numbers';
import { MIND_JOURNEY_ORDER } from 'utils/configuration/const/assessment-types';
// import { CONFIGURATION, CONFIGURATION_OPTIONS } from 'utils/configuration';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import {
  getMindJourneyResults,
  getMindJourneyScore,
  getStatistic,
} from 'store/actions';
import { selectFeaturesNext } from 'store/selectors/configuration';
import * as fromGarminSelectors from 'features/+garmin/store/garmin.selectors';
import * as fromGarminActions from 'features/+garmin/store/garmin.actions';
import MindfulnessJourneyResult from 'ui/molecules/MindfulnessJourneyResult';
import { OrganizationOverview } from './OrganizationOverview';


const MyOrganization = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  // SELECTORS
  const featuresNext = useSelector(selectFeaturesNext);
  const orgScore = useSelector((state) => state.organization?.score);
  const orgStatistic = useSelector((state) => state.organization?.statistic);
  const orgResults = useSelector((state) => state.organization?.results);
  const garminOrgScore = useSelector(fromGarminSelectors.selectGarminOrgScore);

  // MY ORGANIZATION: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  useEffect(() => {
    const { balancedYou, garmin } = featuresNext;

    // BALANCED-YOU FETCH
    if (balancedYou) {
      dispatch(getMindJourneyScore());
      dispatch(getStatistic());
      dispatch(getMindJourneyResults());
    }

    // GARMIN FETCH
    if (garmin) {
      dispatch(fromGarminActions.getOrgScore());
      dispatch(fromGarminActions.getOrgHistory());
    }

    // WELL-BEING FETCH: NOT REQUIRED
  }, [ dispatch, featuresNext ]);

  // RENDER: Detail
  const renderDetail = () => {
    const { balancedYou, garmin, wellbeing } = featuresNext;
    const cards = [];

    if (balancedYou) {
      let mappedOrgResults;
      let dimensions;
      let lineValues;
      let rangeValues;

      if (orgResults && Object.keys(orgResults)?.length) {
        mappedOrgResults = Object.keys(MIND_JOURNEY_ORDER)
        .map((order) => ({ id: order, ...orgResults[order] }));
        dimensions = mappedOrgResults
        .map((result) => ({ ...result, label: result.name }));
        lineValues = mappedOrgResults
        .map((result) => Number(result.median).toFixed(2));
        if (isValid(mappedOrgResults[0].lower_quartile)) {
          rangeValues = mappedOrgResults
          .map(({ lower_quartile: lq, upper_quartile: uq }) => ([ lq, uq ]));
        }
      }

      cards.push(
        <MindfulnessJourneyResult
          type='org'
          dimensions={dimensions}
          lineValues={lineValues}
          rangeValues={rangeValues}
          showDimensionInfo
          showInfoTitle
          score={orgScore?.current}
          completedAssessments={orgStatistic?.completed}
          showSpiderDiagramTextLabels={false}
          showPendingState={orgStatistic?.completed === 0}
        />,
      );
    }

    if (garmin) {
      cards.push(
        <GarminOrgResult
          result={garminOrgScore}
          showPendingState
        />,
      );
    }

    if (wellbeing) {
      cards.push(
        <OrgWellBeingResult
          compactMode={false}
        />,
      );
    }

    if (!cards.length) {
      return null;
    }

    return (
      <div className={styles.detailContainer}>
        <div className={styles.title}>
          { translate('my_org_detail_title') }
        </div>
        <div className={styles.detailGrid}>
          <div className={styles.firstCol}>
            { cards[0] }
          </div>
          <div className={styles.secondCol}>
            { cards[1] ?? null }
            { cards[2] ?? null }
          </div>
        </div>
      </div>
    );
  };

  // RENDER: MyOrganization
  return (
    <div className={styles.myOrganization}>
      <div className={styles.overviewContainer}>
        <OrganizationOverview titleKey='org_stats_title_glance' />
      </div>
      { renderDetail() }
    </div>
  );
};

export default MyOrganization;
