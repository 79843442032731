// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './AdditionalInputAndComments.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { StringResults } from 'ui/molecules/CustomReport/Blocks/StringResults';


// COMPONENT: AdditionalInputAndComments
const AdditionalInputAndComments = (props) => {
  // PROPS
  const { assessmentResults = [] } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: AdditionalInputAndComments
  return (
    <div className={classNames(styles.additionalInputAndComments)}>
      <span className={classNames('bluTypeS', styles.blue)}>
        { translate('peer_360_report_additional_input') }
      </span>
      <div className={classNames('bluTypeCopy', 'marginTopM')}>
        { translate('peer_360_report_additional_input_copy') }
      </div>

      <StringResults
        assessmentResults={assessmentResults}
        configuration={{
          showDimensionDescription: true,
        }}
      />
    </div>
  );
};

export default AdditionalInputAndComments;
