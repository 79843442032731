import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import * as api from 'api';
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';


// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [ value, delay ],
  );

  return debouncedValue;
};


// https://usehooks.com/useWindowSize/
export const useWindowWidth = () => {
  // Initialize state with undefined width so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [ windowWidth, setWindowWidth ] = useState(undefined);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);
    // orientationchange: iOS not resizing properly on iPad in Home Screen App
    // possible related: https://hackernoon.com/onresize-event-broken-in-mobile-safari-d8469027bf4d
    window.addEventListener('orientationchange', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount

  return windowWidth;
};

// https://usehooks.com/useWindowSize/
export const useWindowHeight = () => {
  // Initialize state with undefined width so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [ windowHeight, setWindowHeight ] = useState(undefined);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowHeight(window.innerHeight);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);
    // orientationchange: iOS not resizing properly on iPad in Home Screen App
    // possible related: https://hackernoon.com/onresize-event-broken-in-mobile-safari-d8469027bf4d
    window.addEventListener('orientationchange', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount

  return windowHeight;
};


/**
 * Animates the height of a component after the contents or viewport change.
 * Use the returned values on a wrapper tag to see the animation on change.
 *
 * @returns {[Object, Object]} A tuple containing (in order):
 *    - the object reference to be passed to the 'ref' attribute
 *    - the 'style' object listing all required properties to make the component dynamic.
 */
export const useHeightAnimationStyle = () => {
  const [ height, setHeight ] = useState();
  const componentRef = useRef();
  const { current: component } = componentRef;

  useEffect(() => {
    if (!component) {
      return undefined;
    }

    const setClientHeight = () => setHeight(component.firstChild?.clientHeight);
    const mutationObserver = new MutationObserver(setClientHeight);
    const resizeObserver = new ResizeObserver(setClientHeight);

    mutationObserver.observe(component, { childList: true, subtree: true });
    resizeObserver.observe(component);

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [ component ]);

  return [
    componentRef,
    {
      overflow: 'hidden',
      transition: 'all 0.3s ease-in-out',
      maxHeight: height,
      minHeight: height,
    },
  ];
};


export const breakpoints = {
  Xs: {
    bpName: 'Xs',
    bpWidth: 759,
    isXs: true,
    gridCellCount: 4,
  },
  S: {
    bpName: 'S',
    bpWidth: 959,
    isS: true,
    gridCellCount: 8,
  },
  M: {
    bpName: 'M',
    bpWidth: 1279,
    isM: true,
    gridCellCount: 12,
  },
  L: {
    bpName: 'L',
    bpWidth: 1439,
    isL: true,
    gridCellCount: 12,
  },
  Xl: {
    bpName: 'Xl',
    bpWidth: Infinity,
    isXl: true,
    gridCellCount: 12,
  },
};

const breakpointsArray = Object.values(breakpoints).sort((a, b) => a.bpWidth - b.bpWidth);

/**
 * Determine breakpoint for current screen size (via resize event listener).
 *
 * @returns {Object} Object containing info on previous and current breakpoints
 */
export const useBreakpoint = () => {
  // set largest breakpoint initially
  const [ breakpoint, setBreakpoint ] = useState({
    ...breakpointsArray[breakpointsArray.length - 1],
    bps: breakpoints,
  });

  const handleResize = useCallback(() => {
    const newBreakpoint = breakpointsArray.find(({ bpWidth }) => window.innerWidth <= bpWidth);

    if (newBreakpoint.bpName !== breakpoint.bpName) {
      // console.log(newBreakpoint);
      setBreakpoint({
        ...newBreakpoint,
        scalingUp: newBreakpoint.bpWidth > breakpoint.bpWidth,
        scalingDown: newBreakpoint.bpWidth < breakpoint.bpWidth,
        prevBp: { ...breakpoints[breakpoint.bpName] },
        bps: breakpoints,
      });
    }
  }, [ breakpoint ]);

  useEffect(() => {
    // determine initial resize
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ handleResize ]);

  return breakpoint;
};


// resets a value to its initialValue after [delay]
export const useAutoReset = (initialValue, delay = 150) => {
  // set currentValue to initialValue
  const [ currentValue, setCurrentValue ] = useState(initialValue);
  // console.log('set initially', value);

  useEffect(() => {
    // set timeout when currentValue changes
    if (currentValue !== initialValue) {
      // console.log('set dynamically', currentValue);

      // currentValue is reset to initialValue after timeout
      const handler = setTimeout(() => {
        // console.log('reset to', initialValue);
        setCurrentValue(initialValue);
      }, delay);

      // clearTimeout when useEffect is called again
      return () => {
        clearTimeout(handler);
      };
    }
    return undefined;
  }, [ currentValue, initialValue, delay ]);

  // expose the currentValue and its setter function
  return [
    currentValue,
    setCurrentValue,
  ];
};


/**
 * For text fields triggering several searches in rapid succession (eg. slow typing).
 * It prevents storing data returned in previous fetches by canceling pending calls.
 *
 * @param {String} entrypoint The entrypoint to call
 * @param {Function} [mapResults] Callback to map results automatically before returning them
 * @param {Object} [extraParams] Other call parameters (aside from 'search')
 * @returns {[Boolean, Array, Function]} A tuple of 3 items containing (in order):
 *    - the loading state for the call
 *    - the fetch results (processed by mapResults cb)
 *    - setState function for search string
 *
 * Note: if mapResults is passed, it must be wrapped in 'useCallback' to prevent looping.
 */
export const useLatestCall = (
  entrypoint,
  mapResults = null,
  extraParams = {},
  ignoreEmptyString = true,
  reFetch = false,
) => {
  const [ searchString, setSearchString ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ results, setResults ] = useState();

  // Stringify extra search params to ease useEffect comparison
  let jsonExtraParams;
  if (extraParams instanceof URLSearchParams) {
    jsonExtraParams = extraParams.toString();
  } else {
    jsonExtraParams = JSON.stringify(extraParams);
  }

  // Stores the cancel token referring to the previous call
  // https://github.com/axios/axios#canceltoken-deprecated
  const source = useRef(null);
  const count = useRef(null);

  useEffect(() => {
    count.current = null;
  }, [ searchString ]);

  useEffect(() => {
    // Cancel previous call if still pending
    if (source.current) {
      source.current.cancel();
    }

    setResults();

    // Reset state for empty strings (no search performed)
    if (ignoreEmptyString && !searchString) {
      setLoading(false);
      return;
    }

    setLoading(true);

    // Set cancel token for current call
    source.current = axios.CancelToken.source();

    let params;
    if (extraParams instanceof URLSearchParams) {
      params = extraParams;
    } else {
      params = { ...JSON.parse(jsonExtraParams), search: searchString };
    }

    // Fetch data
    api.get(
      entrypoint,
      params,
      undefined,
      { cancelToken: source.current.token },
    )
    .then(({
      problem,
      ok,
      data,
      headers,
    }) => {
      // 'problem' is set to 'CANCEL_ERROR' for calls aborted via CancelToken.
      // In any other case, the search has been completed, successfully or not
      // (that is, it was not interrupted by subsequent searches).
      if (problem !== 'CANCEL_ERROR') {
        source.current = null;
        setLoading(false);
      }

      if (ok) {
        if (Number(headers['x-more-results-available']) === 1) {
          count.current += extraParams.limit + !count.current;
        } else {
          count.current = Number(headers['x-total-result-count']);
        }

        setResults(mapResults?.(data) ?? data);
      }
    })
    .catch(() => {
      source.current = null;
      setLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    entrypoint,
    mapResults,
    ignoreEmptyString,
    jsonExtraParams,
    searchString,
    reFetch,
  ]);

  return [ loading, results, setSearchString, count.current ];
};

/**
 * Calculate footer offset based on window height.
 * Replaces the "bottom: 0" css prop with positioning from the top
 * (because of iOS/iPadOS dynamic top/bottom bars).
 * @param {Number} [extra] additional adjustment
 * @returns {String} position of footer from top (in pixels)
 */
export const useFooterOffset = (extra = 0) => {
  const windowHeight = useWindowHeight();
  const isAppleMobile = /iPad|iPhone|iPod/.test(window.navigator.platform);
  const appleOffset = isAppleMobile ? 58 : 60;
  return `${windowHeight - (appleOffset + extra)}px`;
};
