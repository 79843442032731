// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useState} from 'react';
import { createPortal } from 'react-dom';
import styles from './GarminOrgReport.module.scss';

// ASSETS
import {Icons} from 'assets/icons';
import {ReactComponent as BodyBatteryKeyVisual} from 'assets/balanced-you/garmin/keyvisuals/org-body-battery.svg';

// 3RD PARTY
import classNames from 'classnames';
import moment from 'moment';

// OTHER COMPONENTS
import {CollapsibleNext, Icon, Scrollable} from 'ui/basic';
import GarminBarDiagram from '../../basic/GarminBarDiagram';
import GarminIcon from '../../basic/GarminIcon';

// UTILS
import {disableScrollingOnBody, enableScrollingOnBody} from 'utils/scrolling';
import {useTranslate} from 'utils/translator';
import {isValid} from 'utils/numbers';
import {useBreakpoint} from 'utils/hooks';
import {timestampToDate} from 'utils/dateTools';

// STORE

const STATISTICS_NUMBER_PER_PAGE = 5;


// CONFIG & DATA
// const Config = {};

// COMPONENT: GarminOrgReport
const GarminOrgReport = (props) => {
  // PROPS
  const { result, history, onClose } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const bp = useBreakpoint();

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    }
  }, [bp]);

  const [mappedOrgHistory, setMappedOrgHistory] = useState();
  useEffect(() => {
    if (history && !mappedOrgHistory) {
      const internalMappedHistory = {};

      history.bodyBattery.forEach(historyItem => {
        if (!historyItem || !historyItem.score.timestamp || !historyItem.score.distribution) {
          return;
        }

        const distribution = historyItem.score.distribution;
        const totalNumber = distribution.good + distribution['medium-good'] + distribution['medium-bad'] + distribution.bad;

        const goodPercentage = Math.round(distribution['medium-good'] * 100 / totalNumber);
        const veryGoodPercentage = Math.round(distribution.good * 100 / totalNumber);
        const badPercentage = Math.round(distribution['medium-bad'] * 100 / totalNumber);
        const veryBadPercentage = Math.round(distribution.bad * 100 / totalNumber);

        if (!internalMappedHistory[historyItem.score.timestamp]) {
          internalMappedHistory[historyItem.score.timestamp] = {
            goodPercentage,
            veryGoodPercentage,
            badPercentage,
            veryBadPercentage
          }
          return;
        }

        if (!internalMappedHistory[historyItem.score.timestamp].bodyBatteryClass) {
          internalMappedHistory[historyItem.score.timestamp].bodyBatteryClass = historyItem.score.class;
        }
      })

      setMappedOrgHistory(internalMappedHistory);
    }
  }, [history, mappedOrgHistory]);


  // BODY BATTERY: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [bodyBatteryDistribution, setBodyBatteryDistribution] = useState();
  const [bodyBatteryVeryGoodPercentage, setBodyBatteryVeryGoodPercentage] = useState();
  const [bodyBatteryGoodPercentage, setBodyBatteryGoodPercentage] = useState();
  const [bodyBatteryBadPercentage, setBodyBatteryBadPercentage] = useState();
  const [bodyBatteryVeryBadPercentage, setBodyBatteryVeryBadPercentage] = useState();
  useEffect(() => {
    if (!result || !result.bodyBattery || !result.bodyBattery.score || !result.bodyBattery.score.distribution) {
      return;
    }

    const distribution = result.bodyBattery.score.distribution;
    const totalNumber = distribution.good + distribution['medium-good'] + distribution['medium-bad'] + distribution.bad;

    setBodyBatteryGoodPercentage(Math.round(distribution['medium-good'] * 100 / totalNumber));
    setBodyBatteryVeryGoodPercentage(Math.round(distribution.good * 100 / totalNumber));
    setBodyBatteryBadPercentage(Math.round(distribution['medium-bad'] * 100 / totalNumber));
    setBodyBatteryVeryBadPercentage(Math.round(distribution.bad * 100 / totalNumber));

    setBodyBatteryDistribution(distribution);
  }, [result]);

  const [bodyBatteryPrevWeekVeryGoodPercentage, setBodyBatteryPrevWeekVeryGoodPercentage] = useState();
  const [bodyBatteryPrevWeekGoodPercentage, setBodyBatteryPrevWeekGoodPercentage] = useState();
  const [bodyBatteryPrevWeekBadPercentage, setBodyBatteryPrevWeekBadPercentage] = useState();
  const [bodyBatteryPrevWeekVeryBadPercentage, setBodyBatteryPrevWeekVeryBadPercentage] = useState();
  useEffect(() => {
    if (!mappedOrgHistory || Object.keys(mappedOrgHistory).length === 0) {
      return;
    }

    const keys = Object.keys(mappedOrgHistory);
    const thisTimeStampKey = keys[keys.length - 2];

    const now = new Date().getTime();
    const daysDiff = (now - (thisTimeStampKey * 1000)) / 1000 / 60 / 60 / 24;

    if (daysDiff > 7 && daysDiff < 14) {
      const prevWeekBodyBattery = mappedOrgHistory[thisTimeStampKey];

      setBodyBatteryPrevWeekVeryGoodPercentage(prevWeekBodyBattery.veryGoodPercentage);
      setBodyBatteryPrevWeekGoodPercentage(prevWeekBodyBattery.goodPercentage);
      setBodyBatteryPrevWeekBadPercentage(prevWeekBodyBattery.badPercentage);
      setBodyBatteryPrevWeekVeryBadPercentage(prevWeekBodyBattery.veryBadPercentage);
    }
  }, [mappedOrgHistory, bodyBatteryPrevWeekVeryGoodPercentage]);


  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Insight box
  const renderInsightBox = (scoreClass, value) => {
    return (
      <>
        <span className={'bluTypeLabel'}>{translate(`org_body_battery_insight_${scoreClass}`, ['{{value}}', value])}</span>
        <div className={classNames('bluTypeLabel', 'marginTopXxs')}>{translate('garmin_insights_label')}</div>
        <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
          <div>{translate(`sleep_insight_${scoreClass}_bullets`)}</div>
          <div>{translate(`stress_insight_${scoreClass}_bullets`)}</div>
        </div>
      </>
    )
  }

  // RENDER: Bar diagram footer text
  const renderFooterText = (currentPercentage, prevWeekPercentage, employeesNumber) => {
    let footerText = `${currentPercentage} % (${employeesNumber} ${translate('employees_lbl')})`;

    if (isValid(prevWeekPercentage)) {
      const diff = currentPercentage - prevWeekPercentage;
      const prefix = diff >= 0 ? '+' : '-';
      footerText = `${footerText}  ${prefix}${Math.abs(diff)} % zur Vorwoche`;
    }

    return footerText;
  }


  // RENDER: GarminOrgReport
  return createPortal(
    <div className={classNames(styles.garminOrgReport)}>
      {/*HEADER*/}
      <div className={styles.header}>
        <span>{translate('org_battery_trend')}</span>
        <div
          className={styles.close}
          onClick={onClose}
        >
          <Icon icon={Icons.CloseBig}/>
        </div>
      </div>

      {/* CONTENT */}
      <div className={styles.scrollableContent}>
        <div className={styles.gridContent}>
          <div className={styles.mainContentSmall}>
            {/*GENERAL SECTION*/}
            <section className={styles.general}>
              <div className={styles.text}>
                <div className='bluTypeS'>
                  {translate('org_battery_trend')}
                </div>
                <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                  {translate('org_body_battery_copy')}
                </div>
              </div>

              <BodyBatteryKeyVisual />
            </section>
          </div>

          <div className={styles.mainContentLarge}>
            <div className={styles.cohortDiagrams}>
              <div className={styles.left}>
                <span className='bluTypeLabel'>{translate('org_body_battery_trend_good_title')}</span>
                <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                  {translate('org_body_battery_trend_good_copy')}
                </div>
                <GarminBarDiagram
                  looks={'blue'}
                  value={bodyBatteryVeryGoodPercentage}
                  footerText={renderFooterText(
                    bodyBatteryVeryGoodPercentage,
                    bodyBatteryPrevWeekVeryGoodPercentage,
                    bodyBatteryDistribution ? bodyBatteryDistribution.good : 0
                  )}
                />
              </div>
              <div className={styles.right}>
                <div className={classNames(styles.insightBox, {[styles.blue]: true, [styles.red]: false})}>
                  {renderInsightBox('good', bodyBatteryVeryGoodPercentage)}
                </div>
              </div>
            </div>
            <div className={styles.cohortDiagrams}>
              <div className={styles.left}>
                <span className='bluTypeLabel'>{translate('org_body_battery_trend_medium-good_title')}</span>
                <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                  {translate('org_body_battery_trend_medium-good_copy')}
                </div>
                <GarminBarDiagram
                  looks={'lightBlue'}
                  value={bodyBatteryGoodPercentage}
                  footerText={renderFooterText(
                    bodyBatteryGoodPercentage,
                    bodyBatteryPrevWeekGoodPercentage,
                    bodyBatteryDistribution ? bodyBatteryDistribution['medium-good'] : 0
                  )}
                />
              </div>
              <div className={styles.right}>
                <div className={classNames(styles.insightBox, {[styles.blue]: true, [styles.red]: false})}>
                  {renderInsightBox('medium-good', bodyBatteryGoodPercentage)}
                </div>
              </div>
            </div>
            <div className={styles.cohortDiagrams}>
              <div className={styles.left}>
                <span className='bluTypeLabel'>{translate('org_body_battery_trend_medium-bad_title')}</span>
                <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                  {translate('org_body_battery_trend_medium-bad_copy')}
                </div>
                <GarminBarDiagram
                  looks={'lightRed'}
                  value={bodyBatteryBadPercentage}
                  footerText={renderFooterText(
                    bodyBatteryBadPercentage,
                    bodyBatteryPrevWeekBadPercentage,
                    bodyBatteryDistribution ? bodyBatteryDistribution['medium-bad'] : 0
                  )}
                />
              </div>
              <div className={styles.right}>
                <div className={classNames(styles.insightBox, {[styles.blue]: false, [styles.red]: true})}>
                  {renderInsightBox('medium-bad', bodyBatteryBadPercentage)}
                </div>
              </div>
            </div>
            <div className={styles.cohortDiagrams}>
              <div className={styles.left}>
                <span className='bluTypeLabel'>{translate('org_body_battery_trend_bad_title')}</span>
                <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                  {translate('org_body_battery_trend_bad_copy')}
                </div>
                <GarminBarDiagram
                  looks={'red'}
                  value={bodyBatteryVeryBadPercentage}
                  footerText={renderFooterText(
                    bodyBatteryVeryBadPercentage,
                    bodyBatteryPrevWeekVeryBadPercentage,
                    bodyBatteryDistribution ? bodyBatteryDistribution.bad : 0
                  )}
                />
              </div>
              <div className={styles.right}>
                <div className={classNames(styles.insightBox, {[styles.blue]: false, [styles.red]: true})}>
                  {renderInsightBox('bad', bodyBatteryVeryBadPercentage)}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.mainContentSmall}>
            {(history && mappedOrgHistory) &&
              // STATISTICS
              <div className={styles.statistics}>
                <span className='bluTypeXxs'>{translate('org_battery_statistics')}</span>

                <div className={styles.table}>
                  {/*TABLE HEADER*/}
                  <div className={styles.tHeader}>
                    <div className={styles.tHeaderItem}>{translate('mind_journey_result__range_lbl')}</div>
                    <div className={styles.tHeaderItem}>
                      <GarminIcon size={'S'} looks={'body-battery'} scoreClass={'good'} />
                    </div>
                    <div className={styles.tHeaderItem}>
                      <GarminIcon size={'S'} looks={'body-battery'} scoreClass={'medium-good'} />
                    </div>
                    <div className={styles.tHeaderItem}>
                      <GarminIcon size={'S'} looks={'body-battery'} scoreClass={'medium-bad'} />
                    </div>
                    <div className={styles.tHeaderItem}>
                      <GarminIcon size={'S'} looks={'body-battery'} scoreClass={'bad'} />
                    </div>
                  </div>

                  <Scrollable
                    pagination drag
                    showPaginationNumbers
                    showPagerButtons
                  >
                    {new Array(Math.ceil(Object.keys(mappedOrgHistory).length / STATISTICS_NUMBER_PER_PAGE)).fill(1)
                      .map((value, index) => {
                        return (
                          <div className={styles.tRows} key={index}>
                            {Object.keys(mappedOrgHistory)
                              .sort((a, b) => b - a)
                              .slice(index * STATISTICS_NUMBER_PER_PAGE, (index * STATISTICS_NUMBER_PER_PAGE) + 5)
                              .map((timestamp, index) => {
                                let weekLabel = `${translate('week_lbl')} ${moment(timestamp * 1000).week()} (${timestampToDate(timestamp).getFullYear()})`;

                                if (index === 0) {
                                  const now = new Date().getTime();
                                  const daysDiff = (now - (timestamp * 1000)) / 1000 / 60 / 60 / 24;

                                  if (daysDiff < 7) {
                                    weekLabel = translate('last_n_days', ['{{days}}', 7])
                                  }
                                }

                                return (
                                  <div className={styles.tRow} key={index}>
                                    <span className={'bluTypeCopy'}>{weekLabel}</span>
                                    <span className={'bluTypeCopy'}>{`${mappedOrgHistory[timestamp].veryGoodPercentage} %`}</span>
                                    <span className={'bluTypeCopy'}>{`${mappedOrgHistory[timestamp].goodPercentage} %`}</span>
                                    <span className={'bluTypeCopy'}>{`${mappedOrgHistory[timestamp].badPercentage} %`}</span>
                                    <span className={'bluTypeCopy'}>{`${mappedOrgHistory[timestamp].veryBadPercentage} %`}</span>
                                  </div>
                                )
                              })
                            }
                          </div>
                        )
                      })}
                  </Scrollable>
                </div>

              </div>
            }

            {/*LEGAL*/}
            <section className={styles.legal}>
              <CollapsibleNext
                header={<span className='bluTypeLabel'>{translate('body_battery_data_collection_goals_title')}</span>}
                withBorders
              >{translate('body_battery_data_collection_goals_copy')}</CollapsibleNext>

              <CollapsibleNext
                header={<span className='bluTypeLabel'>{translate('body_battery_measurement_calculation_title')}</span>}
                withBorders
              >{translate('body_battery_measurement_calculation_copy')}</CollapsibleNext>

              <CollapsibleNext
                header={<span className='bluTypeLabel'>{translate('body_battery_data_protection_title')}</span>}
                withBorders
              >{translate('body_battery_data_protection_copy')}</CollapsibleNext>
            </section>
          </div>

        </div>

      </div>

    </div>,
    document.body,
  );
};

export default GarminOrgReport;
