export const ASSESSMENT_TYPES = {
  BALANCED_YOU: 'balanced-you',
  BIG5: 'big5',
  RMP: 'rmp',
  NINE_LEVELS: '9levels',
  PEER_360: 'peer-360',
  WORK_PREFERENCES: 'work-preference',
  POTENTIAL: 'potential',
  KEY_COMPETENCIES: 'key-comp',
  LEADERSHIP_COMPETENCIES: 'lead-comp',
  IST: 'ist',
};

export const ASSESSMENT_EVALUATION_TYPES = {
  WEIGHTED_AVERAGE: 'weighted-average',
  SELF_EVALUATION: 'self-evaluation',
  RANKED: 'ranked',
};

export const HEALTH_ASSESSMENT_IDS = [
  ASSESSMENT_TYPES.BALANCED_YOU,
  ASSESSMENT_TYPES.IST,
  'garmin',
  'well-being',
];

export const DISPLAY_ONLY_RESULT_ASSESSMENTS = [ 'clifton' ];

export const ASSESSMENT_TITLES = {
  [ASSESSMENT_TYPES.BIG5]: 'big5_results_title',
  [ASSESSMENT_TYPES.POTENTIAL]: 'potential_results_title',
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 'workpreferences_results_title',
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 'keycomp_results_title',
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 'leadershipcomp_results_title',
  [ASSESSMENT_TYPES.RMP]: 'rmp_results_title',
  [ASSESSMENT_TYPES.NINE_LEVELS]: '9levels_results_title',
  [ASSESSMENT_TYPES.BALANCED_YOU]: 'mind_journey_ind_result_info_title',
  [ASSESSMENT_TYPES.IST]: 'ist_assessment_title',
};

export const ASSESSMENT_DESCRIPTIONS = {
  [ASSESSMENT_TYPES.BIG5]: 'org_settings_assessments_big5_descr',
  [ASSESSMENT_TYPES.POTENTIAL]: 'org_settings_assessments_potential_descr',
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 'org_settings_assessments_workpref_descr',
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 'org_settings_assessments_keycomp_descr',
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 'org_settings_assessments_leadcomp_descr',
  [ASSESSMENT_TYPES.RMP]: 'org_settings_assessments_rmp_descr',
  [ASSESSMENT_TYPES.NINE_LEVELS]: 'org_settings_assessments_9levels_descr',
  [ASSESSMENT_TYPES.BALANCED_YOU]: 'org_settings_assessments_mind_journey_descr',
  [ASSESSMENT_TYPES.IST]: 'ist_assessment_description',
};

export const ASSESSMENTS_INFO_TITLE_KEYS = {
  [ASSESSMENT_TYPES.BIG5]: 'big5_results_title',
  [ASSESSMENT_TYPES.RMP]: 'rmp_results_title',
  [ASSESSMENT_TYPES.NINE_LEVELS]: '9levels_results_title',
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 'workpreferences_results_title',
  [ASSESSMENT_TYPES.POTENTIAL]: 'potential_results_title',
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 'keycomp_results_title',
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 'leadershipcomp_results_title',
};

export const ASSESSMENTS_INFO_CONTENT_KEYS = {
  [ASSESSMENT_TYPES.BIG5]: 'big5_results_info_description',
  [ASSESSMENT_TYPES.RMP]: 'rmp_results_info_description',
  [ASSESSMENT_TYPES.NINE_LEVELS]: '9levels_results_details_description',
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 'workpreferences_results_info_description',
  [ASSESSMENT_TYPES.POTENTIAL]: 'potential_results_details_description',
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 'keycomp_results_info_description',
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 'leadershipcomp_results_info_description',
};

export const NINE_LEVELS_ORDER = {
  turquoise: 0,
  cyan: 0,
  yellow: 1,
  green: 2,
  orange: 3,
  blue: 4,
  red: 5,
  purple: 6,
};

export const DISC_DATA = [
  {
    id: 'dominance',
    label: 'D',
    color: 'colorDanger1',
  },
  {
    id: 'influence',
    label: 'I',
    color: 'colorYellow1',
  },
  {
    id: 'steadiness',
    label: 'S',
    color: 'colorGreen1',
  },
  {
    id: 'conscientiousness',
    label: 'C',
    color: 'colorBlue1',
  },
];

export const MIND_JOURNEY_MIN_ASSESSMENT_PERIOD = 28;

export const MIND_JOURNEY_ORDER = {
  mjs_pa: 0,
  mjs_sb: 1,
  mjs_mf: 2,
  mjs_rs: 3,
  mjs_gh: 4,
  mjs_em: 5,
  mjs_eb: 6,
};

export const ASSESSMENT_COMPLETED_STATUSES = {
  NO_DATA: 'no-data',
  PARTIAL: 'partial',
  COMPLETED: 'completed',
};

export const ASSESSMENT_MISMATCHING_DIMENSION_IDS = {
  levels_turquoise: 'levels_cyan',
};

export const ASSESSMENT_MIN_VALUES = {
  [ASSESSMENT_TYPES.BIG5]: 1,
  [ASSESSMENT_TYPES.POTENTIAL]: 1,
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: -5,
  [ASSESSMENT_TYPES.RMP]: -2,
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 1,
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 1,
};

export const ASSESSMENT_MAX_VALUES = {
  [ASSESSMENT_TYPES.BIG5]: 1,
  [ASSESSMENT_TYPES.POTENTIAL]: 10,
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: 5,
  [ASSESSMENT_TYPES.RMP]: 2,
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 5,
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 5,
};

export const CORE_ASSESSMENTS_CURRENT_VALUES = {
  [ASSESSMENT_TYPES.BIG5]: [ 1, 10 ],
  [ASSESSMENT_TYPES.POTENTIAL]: [ 1, 10 ],
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: [ -5, 5 ],
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: 1,
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: 1,
  [ASSESSMENT_TYPES.RMP]: [ -2, 2 ],
  [ASSESSMENT_TYPES.NINE_LEVELS]: [ 0, 100 ],
};

export const ASSESSMENT_DELAYED_RESULTS = {
  [ASSESSMENT_TYPES.BIG5]: false,
  [ASSESSMENT_TYPES.POTENTIAL]: false,
  [ASSESSMENT_TYPES.WORK_PREFERENCES]: false,
  [ASSESSMENT_TYPES.KEY_COMPETENCIES]: false,
  [ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES]: false,
  [ASSESSMENT_TYPES.RMP]: true,
  [ASSESSMENT_TYPES.NINE_LEVELS]: true,
};

export const ASSESSMENT_RECURRENCE_TYPES = {
  NONE: 'none',
  RUN: 'run',
  FREQUENCY: 'frequency',
  MANUAL: 'manual',
};
