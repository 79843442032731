// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './LineDiagram.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// CONFIG & DATA
const Config = {
  size: 15,
  dotRadius: 1.5,
  defaultSegmentsCount: 10,
  defaultStyle: {
    score: 'primary',
    score2: 'yellow',
    range: 'primary',
  },
};


// COMPONENT: LineDiagram
const LineDiagram = (props) => {
  // PROPS
  const {
    score = 0,
    score2 = 0,
    range,
    segmentsCount = Config.defaultSegmentsCount,
    style = {},
    distribution = [],
  } = props;

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const step = 100 / (segmentsCount - 1);
  const styleInternal = { ...Config.defaultStyle, ...style };

  let rangeStart;
  let rangeEnd;
  if (range?.length) {
    rangeStart = Math.max(range[0], 1);
    rangeEnd = Math.min(range[1], segmentsCount);
  }


  // RENDER: LineDiagram
  return (
    <div
      className={styles.lineDiagram}
      style={{
        padding: `0 ${Config.size / 2}px`,
        height: Config.size,
      }}
    >
      <svg height={Config.size}>
        { /* RANGES */ }
        { (rangeStart && rangeEnd) && (
          <rect
            x={`${(rangeStart - 1) * step}%`}
            y={(Config.size - 1) / 2}
            width={`${(rangeEnd - rangeStart) * step}%`}
            height={1}
            rx={Config.size / 2}
            strokeWidth={Config.size}
            className={classNames(
              styles.range,
              styles[styleInternal.range],
            )}
          />
        ) }

        { /* GREY LINE */ }
        <rect
          y={Math.floor(Config.size / 2)}
          width='100%'
          height='1'
          className={styles.grey}
        />

        { /* GREY DOTS */ }
        { new Array(segmentsCount).fill().map((_, i) => (
          <circle
            key={i} // eslint-disable-line react/no-array-index-key
            cx={`${i * step}%`}
            cy={Config.size / 2}
            r={Config.dotRadius}
            className={styles.grey}
          />
        )) }

        { /* SCORE MARKER 1 */ }
        { Boolean(score) && (
          <circle
            cx={`${(score - 1) * step}%`}
            cy={Config.size / 2}
            r={Config.size / 2}
            data-test='LineDiagramDot'
            className={classNames(
              styles.marker,
              styles[styleInternal.score],
            )}
          />
        ) }

        { /* SCORE MARKER 2 */ }
        { Boolean(score2) && (
          <circle
            cx={`${(score2 - 1) * step}%`}
            cy={Config.size / 2}
            r={Config.size / 2}
            data-test='LineDiagramDot2'
            className={classNames(
              styles.marker,
              styles[styleInternal.score2],
            )}
          />
        ) }

        { /* DISTRIBUTION MARKERS */ }
        { distribution.map((marker, i) => (
          <circle
            key={i} // eslint-disable-line react/no-array-index-key
            cx={`${(marker.value - 1) * step}%`}
            cy={Config.size / 2}
            r={Config.size / 4}
            className={classNames(
              styles.marker,
              styles.distribution,
              styles[marker.style],
            )}
          />
        )) }
      </svg>
    </div>
  );
};

export default LineDiagram;
