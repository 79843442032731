// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './ModalCardExpiration.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY

// OTHER COMPONENTS
import { Modal } from 'ui/basic';
import ModalCreditCard from 'features/+adminUG/pages/SettingsPayment/components/ModalCreditCard';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useSelector } from 'react-redux';
import { selectPlanPaymentCard } from 'features/framework/storeNext/configurationSlice';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: ModalCardExpiration
const ModalCardExpiration = (props) => {
  // PROPS
  const { userId } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const planPaymentCard = useSelector(selectPlanPaymentCard);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const [ showCreditCardModal, setShowCreditCardModal ] = useState();

  const [ reminderShown, setReminderShown ] = useState();
  useEffect(() => {
    const reminderShownDate = Number(localStorage.getItem(`cardExpirationReminderDateFor${userId}`));

    const date = new Date(reminderShownDate).getDate();
    const month = new Date(reminderShownDate).getMonth();
    const year = new Date(reminderShownDate).getFullYear();

    const today = new Date();
    const todayDate = today.getDate();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();

    if (date === todayDate && month === todayMonth && year === todayYear) {
      setReminderShown(true);
    } else {
      setReminderShown(false);
    }
  }, [userId]);

  const handleClose = () => {
    localStorage.setItem(`cardExpirationReminderDateFor${userId}`, new Date().getTime());
    setReminderShown(true);
  }

  const handleCancel = () => {
    setShowCreditCardModal(false);
    handleClose();
  }

  const handleFinish = () => {
    setReminderShown(true);
    setShowCreditCardModal(false);

    localStorage.removeItem(`cardExpirationReminderDateFor${userId}`);
  }

  if (planPaymentCard.status !== 'expired' || reminderShown === undefined || reminderShown) {
    return null;
  }

  // RENDER: ModalCardExpiration
  return (
    <div className={styles.modalCardExpiration}>
      { !showCreditCardModal && (
        <Modal
          header={translate('credit_card_expires_soon')}
          primaryButtonTitle={translate('update')}
          secondaryButtonTitle={translate('remind_later')}
          onConfirm={() => setShowCreditCardModal(true) }
          onClose={handleClose}
        >
          { translate('credit_card_expires_soon_copy', [
            '{{date}}',
            `${planPaymentCard.expirationMonth}/${planPaymentCard.expirationYear}`
          ])
          }
        </Modal>
      )}

      { showCreditCardModal && (
        <ModalCreditCard
          onCancel={handleCancel}
          onFinish={handleFinish}
        />
      )}
    </div>
  );
};

export default ModalCardExpiration;
