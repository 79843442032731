// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './LoadingMedium.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';
import { ReactComponent as Loader } from './loader_medium.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: LoadingMedium
const LoadingMedium = (props) => {
  // PROPS
  // const { children } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: LoadingMedium
  return (
    <div className={classNames(styles.loadingMedium)}>
      <div className={styles.loader}>
        <Loader />
      </div>
    </div>
  );
};

export default LoadingMedium;
