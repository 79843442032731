import React, { useEffect, useState } from 'react';
import styles from './OrgWellBeingResult.module.scss';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/de';

import { useTranslate } from 'utils/translator';
import * as fromWellBeingSelectors from 'store/selectors/wellBeing';
import { ReactComponent as VeryHappy } from 'assets/well-being/very-happy.svg';
import { ReactComponent as Happy } from 'assets/well-being/happy.svg';
import { ReactComponent as Neutral } from 'assets/well-being/neutral.svg';
import { ReactComponent as Sad } from 'assets/well-being/sad.svg';
import { ReactComponent as VerySad } from 'assets/well-being/very-sad.svg';

import {
  getOrgWellBeingHistory,
  getOrgWellBeingIndex,
  initGetOrgWellBeingHistory,
} from 'store/actions';

import { LANGUAGES } from 'utils/configuration/const/languages';
import { WELL_BEING_MODES } from 'utils/configuration/const/well-being';
import { setDecimalSeparator } from 'utils/userpreference';

import {
  InfoTitle, Callout, ProgressCircle,
  WellbeingDiagram, Card, PillGroup, Modal,
} from 'ui/basic';


const MAX_WELLBEING_INDEX = 5;

const WELL_BEING_EMOJIS = {
  1: <VerySad />,
  2: <Sad />,
  3: <Neutral />,
  4: <Happy />,
  5: <VeryHappy />,
};

let orgWellBeingDailyHistory;

const OrgWellBeingResult = ({ compactMode }) => {
  // SPECIAL HOOKS
  const translate = useTranslate();

  // INFO MODAL
  const [ showModal, setShowModal ] = useState(false);
  const [ modalHeader, setModalHeader ] = useState();
  const [ modalHeaderPosition, setModalHeaderPosition ] = useState('left');
  const [ modalPreHeader, setModalPreHeader ] = useState();
  const [ modalContent, setModalContent ] = useState();
  const [ modalButtonsPosition, setModalButtonsPosition ] = useState('right');
  const [ modalHint, setModalHint ] = useState();

  // CHART
  const [ chartViewMode, setChartViewMode ] = useState(WELL_BEING_MODES.WEEKLY);

  // STATE
  const [ filteredWellBeingHistory, setFilteredWellBeingHistory ] = useState([]);
  const [ currentWellbeingHistory, setCurrentWellbeingHistory ] = useState([]);
  const [ wellBeingYear, setWellBeingYear ] = useState();

  // STORE
  const dispatch = useDispatch();

  const orgWellBeingIndex = useSelector(fromWellBeingSelectors.getOrgWellBeingIndex);
  const orgWellBeingIndexUserCount = useSelector(fromWellBeingSelectors.getOrgWellBeingIndexUserCount);
  const orgWellBeingIndexNotEnoughData = useSelector(fromWellBeingSelectors.getOrgWellBeingIndexNotEnoughData);
  const orgWellBeingHistory = useSelector(fromWellBeingSelectors.getOrgWellBeingHistory);

  // LANGUAGE
  const currentLanguage = useSelector(
    (state) => state.localisation && state.localisation.currentLanguage,
  );

  const getDate = (date) => {
    if (chartViewMode === WELL_BEING_MODES.WEEKLY) {
      return date;
    }

    const m = moment(date);
    m.locale(currentLanguage === LANGUAGES.DE ? 'de' : 'en');
    return m.format('MMMM');
  };

  const getCalloutText = (index) => {
    const currentWellBeing = currentWellbeingHistory[index];
    const prevWellBeing = currentWellbeingHistory[index - 1];

    if (!currentWellBeing || !prevWellBeing) {
      return translate(`wellbeing_org_${chartViewMode}_no_result`);
    }

    const diff = Number((currentWellBeing.index - prevWellBeing.index).toFixed(1));
    if (diff === 0) {
      return translate(`wellbeing_org_${chartViewMode}_result_no_difference`);
    } if (diff > 0 && diff <= 0.9) {
      return translate(`wellbeing_org_${chartViewMode}_result_slight_impr`);
    } if (diff < 0 && diff >= -0.9) {
      return translate(`wellbeing_org_${chartViewMode}_result_slight_deter`);
    } if (diff >= 1 && diff <= 1.9) {
      return translate(`wellbeing_org_${chartViewMode}_result_strong_impr`);
    } if (diff <= -1 && diff >= -1.9) {
      return translate(`wellbeing_org_${chartViewMode}_result_strong_deter`);
    } if (diff >= 2 && diff <= 5) {
      return translate(`wellbeing_org_${chartViewMode}_result_very_strong_impr`);
    }
    return translate(`wellbeing_org_${chartViewMode}_result_very_strong_deter`);
  };

  useEffect(() => {
    dispatch(getOrgWellBeingIndex()); // default: 7 days
    dispatch(getOrgWellBeingHistory()); // default: weekly
  }, [ dispatch ]);

  useEffect(() => {
    const internalOrgWellBeingHistory = [ ...(orgWellBeingHistory || []) ];
    if (internalOrgWellBeingHistory && internalOrgWellBeingHistory.length > 0) {
      // orgWellBeingDailyHistory field is needed for weekly comparison text
      if (!orgWellBeingDailyHistory) {
        orgWellBeingDailyHistory = internalOrgWellBeingHistory;
      }

      setFilteredWellBeingHistory(internalOrgWellBeingHistory);
    }
  }, [ orgWellBeingHistory, chartViewMode ]);

  useEffect(() => {
    let currentWellbeingChartHistory;

    if (!filteredWellBeingHistory || (filteredWellBeingHistory && filteredWellBeingHistory.length === 0)) {
      currentWellbeingChartHistory = [];
    } else {
      currentWellbeingChartHistory = [ ...filteredWellBeingHistory ];
    }

    setCurrentWellbeingHistory(currentWellbeingChartHistory);
  }, [ chartViewMode, filteredWellBeingHistory ]);

  return (
    <Card>
      <div className={classNames(styles.wellBeingResult, { [styles.compact]: compactMode })}>
        <InfoTitle
          title={translate('wellbeing_ind_result_title')}
          onClick={() => {
            setModalHeader(translate('wellbeing_ind_result_title'));
            setModalHeaderPosition('left');
            setModalContent(translate('wellbeing_ind_result_info_modal_description'));
            setModalPreHeader(null);
            setModalButtonsPosition('right');
            setModalHint(null);

            setShowModal(true);
          }}
        />

        <div className={styles.wellBeingIndexPanel}>
          <div className={styles.wellBeingIndex}>
            { /* WBI TITLE OK */ }
            <span className={styles.title}>
              { translate('wellbeing_org_result_body_text') }
              <br />

              { /* USER COUNT */ }
              { !orgWellBeingIndexNotEnoughData && (
                <span className={styles.userCount}>
                  { translate(
                    'wellbeing_org_result_user_count',
                    [ '{{userCount}}', orgWellBeingIndexUserCount ],
                  ) }
                </span>
              ) }
            </span>

            { /* INDEX DISPLAY */ }
            <div className={styles.indexContainer}>
              <div className={styles.index}>
                { `${!orgWellBeingIndex ? '-' : setDecimalSeparator(orgWellBeingIndex, 2)} / ${MAX_WELLBEING_INDEX}` }
              </div>
              <div className={styles.progress}>
                <ProgressCircle
                  size='M'
                  progress={Math.ceil(((orgWellBeingIndex) * 100) / MAX_WELLBEING_INDEX)}
                  showWellBeingIcon
                />
              </div>
            </div>
          </div>
        </div>

        { /* WELLBEING DIAGRAM */ }
        <div className={styles.chart}>
          { /* DATE CONTROLS */ }
          <div className={styles.controls}>
            <PillGroup
              pillItems={[
                { id: WELL_BEING_MODES.WEEKLY, label: translate('wellbeing_ind_result_toggle_weeks') },
                { id: WELL_BEING_MODES.MONTHLY, label: translate('wellbeing_ind_result_toggle_months') },
              ]}
              onPillClick={(pillItem) => {
                if (chartViewMode === pillItem.id) {
                  return;
                }

                setFilteredWellBeingHistory([]);
                dispatch(initGetOrgWellBeingHistory());
                dispatch(getOrgWellBeingHistory(pillItem.id));
                setChartViewMode(pillItem.id);
              }}
            />

            { /* YEAR */ }
            <div>{ wellBeingYear }</div>
          </div>

          <WellbeingDiagram
            points={currentWellbeingHistory.map((d) => d.index)}
            dates={chartViewMode === WELL_BEING_MODES.WEEKLY
              ? currentWellbeingHistory.map((d) => (d.week ? d.week.split('-')[1] : 0))
              : currentWellbeingHistory.map((d) => d.date)}
            mode={chartViewMode}
            infoModalIsOpen={showModal}
            // eslint-disable-next-line no-unused-vars
            onPaginationAvailabilities={([ pageLeftAvailable, pageRightAvailable, pagePosition ]) => {
              if (pagePosition < 0) {
                return;
              }

              const position = 6 * pagePosition;
              const chunk = currentWellbeingHistory.slice(position, position + 6);
              const lastChunk = chunk[chunk.length - 1];

              if (!lastChunk) {
                return;
              }

              // YEAR
              let year = '-';
              if (lastChunk.week) {
                [ year ] = lastChunk.week.split('-');
              } else {
                year = new Date(lastChunk.date).getFullYear();
              }

              setWellBeingYear(year);
            }}
            onClick={(date, wellBeingIndex, index) => {
              setModalPreHeader(
                <div className={styles.wellBeingModalPreHeader}>
                  { WELL_BEING_EMOJIS[Math.ceil(wellBeingIndex)] }
                </div>,
              );

              setModalHeaderPosition('center');
              setModalHeader(
                <div className={styles.wellBeingModalHeader}>
                  <div>
                    { translate(
                      `wellbeing_org_${chartViewMode}_result_info_modal_title`,
                      [ '{{date}}', getDate(date) ],
                    ) }
                  </div>
                  <div className={styles.index}>
                    { `${wellBeingIndex} / ${MAX_WELLBEING_INDEX}` }
                  </div>
                </div>,
              );

              setModalContent(
                <Callout>
                  <div style={{ textAlign: 'center' }}>
                    { getCalloutText(index) }
                  </div>
                </Callout>,
              );

              setModalHint(currentWellbeingHistory?.[index] && translate(
                'wellbeing_org_result_modal_hint',
                [ '{{employees}}', currentWellbeingHistory[index].users ],
              ));
              setModalButtonsPosition('center');
              setShowModal(true);
            }}
          />
        </div>


        { showModal && (
          <Modal
            header={modalHeader}
            headerPosition={modalHeaderPosition}
            preHeader={modalPreHeader}
            secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
            buttonsPosition={modalButtonsPosition}
            hint={modalHint}
            onClose={() => setShowModal(false)}
          >
            { modalContent }
          </Modal>
        ) }
      </div>
    </Card>
  );
};

OrgWellBeingResult.defaultProps = {
  compactMode: true,
};

export default OrgWellBeingResult;
