import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as api from 'api';

import * as fromActionTypes from 'store/actionTypes/admin';
import * as fromActions from 'store/actions/admin';

import { IS_ADMIN_FRONTEND } from 'utils/configuration';
import { PLATFORM_ROLES } from 'utils/configuration/const/roles';

function* deleteCompanyUser({ payload }) {
  try {
    const { userId, companyId, userName } = payload;

    const headerItems = {};
    if (IS_ADMIN_FRONTEND && companyId) {
      headerItems['x-admin-company'] = companyId;
    }

    const { status, ok, data } = yield call(
      api._delete,
      `/admin/users/${userId}`,
      {},
      headerItems,
    );
    if (ok && status === 200) {
      yield put(fromActions.deleteCompanyUserFulfilled({ deletedUserId: userId, deletedUserName: userName }));
    } else {
      yield put(fromActions.deleteCompanyUserRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(fromActions.deleteCompanyUserRejected({ error }));
  }
}

function* removeCompanyUser({ payload }) {
  try {
    const { userId, companyId, userName } = payload;

    const headerItems = {};
    if (IS_ADMIN_FRONTEND && companyId) {
      headerItems['x-admin-company'] = companyId;
    }

    const { status, ok, data } = yield call(
      api._delete,
      `/core/company/users/${userId}`,
      {},
      headerItems,
    );
    if (ok && status === 200) {
      yield put(fromActions.removeCompanyUserFulfilled({ removedUserId: userId, removedUserName: userName }));
    } else {
      yield put(fromActions.deleteCompanyUserRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(fromActions.deleteCompanyUserRejected({ error }));
  }
}

function* addCompanyUsers({ payload }) {
  try {
    const dto = payload.addCompanyUsersDto;
    // Candidates invitations have to go through another entrypoint (different capability)
    const candidateMails = [];
    const userMails = [];
    dto.mails.forEach((mail) => {
      const list = mail.role === PLATFORM_ROLES.CANDIDATE ? candidateMails : userMails;
      list.push(mail);
    });

    const calls = [];
    if (candidateMails.length) {
      calls.push(call(api.post, '/recruiting/candidates', { ...dto, mails: candidateMails }));
    }
    if (userMails.length) {
      calls.push(call(api.put, '/core/company/users', { ...dto, mails: userMails }));
    }

    const responses = yield all(calls);
    if (responses.every(({ status, ok }) => ok && status === 200)) {
      yield put(fromActions.addCompanyUsersFulfilled());
    } else {
      const errorMessage = responses.map(({ data }) => data?.error?.errorMessage)
      .filter(Boolean)
      .join('; ');

      yield put(fromActions.addCompanyUsersRejected({ error: { errorMessage } }));
    }
  } catch (error) {
    yield put(fromActions.addCompanyUsersRejected({ error }));
  }
}

function* editCompanyUserRole({ payload }) {
  try {
    const { userId, role, companyId } = payload;

    const headerItems = {};
    if (IS_ADMIN_FRONTEND && companyId) {
      headerItems['x-admin-company'] = companyId;
    }

    const { status, ok, data } = yield call(
      api.post,
      `/core/company/users/${userId}`,
      { role },
      headerItems,
    );
    if (ok && status === 200) {
      yield put(fromActions.editCompanyUserFulfilled());
    } else {
      yield put(fromActions.editCompanyUserRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(fromActions.editCompanyUserRejected({ error }));
  }
}

export function* watchDeleteCompanyUser() {
  yield takeEvery(fromActionTypes.DELETE_COMPANY_USER, deleteCompanyUser);
}

export function* watchRemoveCompanyUser() {
  yield takeEvery(fromActionTypes.REMOVE_COMPANY_USER, removeCompanyUser);
}

export function* watchAddCompanyUsers() {
  yield takeEvery(fromActionTypes.ADD_COMPANY_USERS, addCompanyUsers);
}

export function* watchEditCompanyUserRole() {
  yield takeEvery(fromActionTypes.EDIT_COMPANY_USER_ROLE, editCompanyUserRole);
}
