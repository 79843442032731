// REACT, STYLE, STORIES & COMPONENT
import React, {useEffect, useRef} from 'react';
import styles from './ProgressBarMock.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS

// CONFIG & DATA

// COMPONENT: ProgressBarMock
const ProgressBarMock = (props) => {
  // PROPS
  const {
    duration = 5000,
    stopAt90 = true
  } = props;

  // COMPONENT/UI STATE and REFS

  // STORE HOOKS
  const progressLabelRef = useRef();

  // https://css-tricks.com/animating-number-counters/
  const animateValue = (obj, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) {
        startTimestamp = timestamp
      }

      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  useEffect(() => {
    const obj = progressLabelRef.current;
    if (!obj) {
      return;
    }

    animateValue(obj, 0, stopAt90 ? 90 : 100, duration);
  }, [duration, stopAt90]);

  // EFFECT HOOKS

  // OTHER HOOKS

  // METHODS

  // HELPERS, HANDLES, RENDERS

  // RENDER: ProgressBarMock
  return (
    <div className={styles.progressBarMock}>
      {/*PROGRESS LABEL*/}
      {<div ref={progressLabelRef} className={styles.progressLabel}>0</div>}

      {/*PROGRESS BAR*/}
      <div className={classNames(styles.progressBar)}>
        <div
          className={classNames(styles.progressIndicator, {
            [styles.stopAt90]: stopAt90
          })}
          style={{animationDuration: `${duration}ms`}}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBarMock;
