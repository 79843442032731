import {ASSESSMENT_TYPES} from 'utils/configuration/const/assessment-types';

export const getTranslationIds = (assessmentType) => {
  switch (assessmentType) {
    case ASSESSMENT_TYPES.BIG5:
      return {
        title: 'big5_teaser_title',
        description: 'big5_teaser_description',
        scope: 'big5_teaser_scope',
        infoTitle: 'big5_teaser_info_title',
        infoDetails: 'big5_teaser_info_details',
        startButtonTitle: 'big5_teaser_button_start'
      };
    case ASSESSMENT_TYPES.POTENTIAL:
      return {
        title: 'potential_teaser_title',
        description: 'potential_teaser_description',
        scope: 'potential_teaser_scope',
        infoTitle: 'potential_teaser_info_title',
        infoDetails: 'potential_teaser_info_details',
        startButtonTitle: 'potential_teaser_button_start'
      };
    case ASSESSMENT_TYPES.KEY_COMPETENCIES:
      return {
        title: 'keycompetencies_teaser_title',
        description: 'keycompetencies_teaser_description',
        scope: 'keycompetencies_teaser_scope',
        infoTitle: 'keycompetencies_teaser_info_title',
        infoDetails: 'keycompetencies_teaser_info_details',
        startButtonTitle: 'keycompetencies_teaser_button_start'
      };
    case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
      return {
        title: 'leadershipcompetencies_teaser_title',
        description: 'leadershipcompetencies_teaser_description',
        scope: 'leadershipcompetencies_teaser_scope',
        infoTitle: 'leadershipcompetencies_teaser_info_title',
        infoDetails: 'leadershipcompetencies_teaser_info_details',
        startButtonTitle: 'leadershipcompetencies_teaser_button_start'
      };
    case ASSESSMENT_TYPES.WORK_PREFERENCES:
      return {
        title: 'workpreferences_teaser_title',
        description: 'workpreferences_teaser_description',
        scope: 'workpreferences_teaser_scope',
        infoTitle: 'workpreferences_teaser_info_title',
        infoDetails: 'workpreferences_teaser_info_details',
        startButtonTitle: 'workpreferences_teaser_button_start'
      };
    case ASSESSMENT_TYPES.RMP:
      return {
        title: 'rmp_teaser_title',
        description: 'rmp_teaser_description',
        scope: 'rmp_teaser_scope',
        infoTitle: 'rmp_teaser_info_title',
        infoDetails: 'rmp_teaser_info_details',
        startButtonTitle: 'rmp_teaser_button_start'
      }
    case ASSESSMENT_TYPES.NINE_LEVELS:
      return {
        title: '9levels_teaser_title',
        description: '9levels_teaser_description',
        scope: '9levels_teaser_scope',
        infoTitle: '9levels_teaser_info_title',
        infoDetails: '9levels_teaser_info_details',
        startButtonTitle: '9levels_teaser_button_start'
      }
    case ASSESSMENT_TYPES.BALANCED_YOU:
      return {
        title: 'mind_journey_teaser_title',
        description: 'mind_journey_teaser_description',
        scope: 'mind_journey_teaser_scope',
        infoTitle: 'mind_journey_teaser_info_title',
        infoDetails: 'mind_journey_teaser_info_details',
        startButtonTitle: 'mind_journey_teaser_button_start'
      }
      default:
      return {};
  }
};
