// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './PersonalData.module.scss';

// 3RD PARTY
import classNames from 'classnames';
import * as moment from 'moment';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';
import { timestampToDate } from 'utils/dateTools';
import { setDecimalSeparator } from 'utils/userpreference';


// COMPONENT: PersonalData
const PersonalData = (props) => {
  // PROPS
  const { ist, user } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  let bmi;
  if (ist?.registrationData?.weight && ist?.registrationData?.height) {
    const heightInMeter = ist.registrationData.height / 100;
    bmi = setDecimalSeparator(ist.registrationData.weight / heightInMeter ** 2);
  }

  const data = [
    {
      label: translate('ist_report_personal_data_patient'),
      value: `${user.firstName} ${user.lastName}`,
    },
    {
      label: translate('ist_report_personal_data_dob'),
      value: ist.registrationData?.age,
    },
    {
      label: translate('ist_report_personal_data_gender'),
      value: translate(`ist_ass_reg_gender_${ist.registrationData?.gender}`),
    },
    {
      label: translate('ist_report_personal_data_date'),
      value: moment(timestampToDate(ist.date)).format('DD.MM.YYYY'),
    },
    {
      label: translate('ist_report_personal_data_bmi'),
      value: bmi,
    },
  ];
  if (ist.registrationData?.abdominal_girth) {
    data.push({
      label: translate('ist_report_personal_data_girth'),
      value: `${ist.registrationData?.abdominal_girth} cm`,
    });
  }
  if (ist.registrationData?.activity) {
    data.push({
      label: translate('ist_report_personal_data_activity'),
      value: translate(`ist_ass_reg_activity_${ist.registrationData.activity}`),
    });
  }

  // RENDER: PersonalData
  return (
    <div className={classNames(styles.personalData)}>
      { /* PersonalData */ }
      <div>
        <h2>{ translate('ist_report_personal_data_title') }</h2>
        <div className={classNames('bluTypeCopy')}>
          { translate('ist_report_personal_data_description') }
        </div>
      </div>

      <div className='marginTopS'>
        { data.map(({ label, value }) => (
          <div className={styles.section} key={label}>
            <span>{ label }</span>
            <span>{ value }</span>
          </div>
        )) }
      </div>
    </div>
  );
};

export default PersonalData;
