import { getIsExternalSystemIntegrationSupported } from 'features/+coachHub/utils/localStorage';
import { useDispatch } from 'react-redux';
import { getAssessmentReportPdf } from 'store/actions';
import { serialiseAssessmentReportPdfParams } from 'utils/assessment';
import {
  ASSESSMENT_TYPES,
  DISPLAY_ONLY_RESULT_ASSESSMENTS,
  HEALTH_ASSESSMENT_IDS,
} from 'utils/configuration/const/assessment-types';
import { MAPPED_PLATFORM_ROLES } from 'utils/configuration/const/roles';


// Returns the (redux) action to download the Pdf report of an assessment, and the downloading state.
// The 'downloading' status is saved to store so it's available after navigating elsewhere.
export const selectAssessmentPdfDownloadHandles = (store, props) => {
  const dispatch = useDispatch(); // eslint-disable-line react-hooks/rules-of-hooks
  const {
    showReportButton = true,
    exportView = false,
    assessmentData,
    historyIndex,
    userId,
  } = props;

  // Coachhub can only download the DISC report (for now)
  const isExternal = getIsExternalSystemIntegrationSupported();

  const canGetDiscReportPdf = isExternal
    && assessmentData?.id === 'disc';
  const canGetCustomReportPdf = !isExternal
    && showReportButton
    && assessmentData?.customReport
    && !exportView;

  if (!(canGetDiscReportPdf || canGetCustomReportPdf)) {
    return null;
  }

  const queryParams = {
    userId,
    id: assessmentData?.id,
    resultId: assessmentData?.history?.results[historyIndex].id,
  };
  const downloadingKey = serialiseAssessmentReportPdfParams(queryParams);

  return {
    downloading: Boolean(store.assessments.downloading?.[downloadingKey]),
    handleDownload: () => dispatch(getAssessmentReportPdf(queryParams)),
  };
};

export const selectAssessmentsLoading = (store) => store.assessments.loading;

export const selectAssessmentsTable = (store) => store.assessments.table;

export const selectAssessments = (store, filters = []) => {
  const assessmentsFromStore = selectAssessmentsTable(store);

  if (!assessmentsFromStore || Object.keys(assessmentsFromStore).length === 0) {
    return [];
  }

  const unorderedCoreAssessments = [];
  const orderedCoreAssessments = [];
  const customAssessments = [];

  // separate core and custom assessments into 2 different arrays
  Object.keys(assessmentsFromStore).forEach((assessmentKey) => {
    if (assessmentsFromStore[assessmentKey]
      && Object.prototype.toString.call(assessmentsFromStore[assessmentKey]) === '[object Object]') {
      if (Object.values(ASSESSMENT_TYPES).includes(assessmentKey)) {
        unorderedCoreAssessments.push(assessmentsFromStore[assessmentKey]);
      } else {
        customAssessments.push(assessmentsFromStore[assessmentKey]);
      }
    }
  });

  // order core assessments
  Object.values(ASSESSMENT_TYPES).forEach((assessmentId) => {
    const thisAssessment = unorderedCoreAssessments.find((a) => a.id === assessmentId);
    if (thisAssessment && !orderedCoreAssessments.find((a) => a.id === assessmentId)) {
      orderedCoreAssessments.push(thisAssessment);
    }
  });

  let assessments = orderedCoreAssessments.concat(customAssessments);

  // filter out unwanted assessments
  filters.forEach?.((filter) => {
    assessments = assessments.filter((a) => filter(a, store));
  });

  return assessments;
};

// List of filters callbacks that can be applied to selectAssessments
export const ASSESSMENT_FILTERS = {
  noClifton: (a) => a.render_results && !DISPLAY_ONLY_RESULT_ASSESSMENTS.includes(a.render_results),
  noUnavailable: (a) => a.userAvailable !== false, // true and nullish are both ok
  noHealth: (a) => !HEALTH_ASSESSMENT_IDS.includes(a.id),
  noAsPeer: (a) => !a.asPeer,
  forUserGroup: (a, s) => {
    const userGroup = s.currentUser?.roleNext;
    const accessibleAssessments = s.currentUser?.accessibleAssessments;
    if (userGroup !== MAPPED_PLATFORM_ROLES.ASSESSMENT_MANAGER) {
      return true;
    }
    return accessibleAssessments.includes(a.id);
  },
};

// Derived selectors
export const selectAssessmentsWithNoClifton = (store) => selectAssessments(store, [
  ASSESSMENT_FILTERS.noClifton,
]);
export const selectAssessmentsNonHealth = (store) => selectAssessments(store, [
  ASSESSMENT_FILTERS.noClifton,
  ASSESSMENT_FILTERS.noUnavailable,
  ASSESSMENT_FILTERS.noHealth,
]);
export const selectAssessmentsFilteredForUserGroup = (store) => selectAssessments(store, [
  ASSESSMENT_FILTERS.noClifton,
  ASSESSMENT_FILTERS.forUserGroup,
]);
export const selectAssessmentsBluquist = (store) => selectAssessments(store, [
  ASSESSMENT_FILTERS.noClifton,
  ASSESSMENT_FILTERS.noHealth,
  ASSESSMENT_FILTERS.noAsPeer,
  ASSESSMENT_FILTERS.forUserGroup,
]);

export const selectShowStrengths = (store) => Boolean(selectAssessmentsNonHealth(store).length);
