export const GET_ASSESSMENT_RESULT = 'GET_ASSESSMENT_RESULT';
export const GET_ASSESSMENT_RESULT_PENDING = 'GET_ASSESSMENT_RESULT_PENDING';
export const GET_ASSESSMENT_RESULT_FULFILLED = 'GET_ASSESSMENT_RESULT_FULFILLED';
export const GET_ASSESSMENT_RESULT_REJECTED = 'GET_ASSESSMENT_RESULT_REJECTED';

export const GET_ASSESSMENT_HISTORY = 'GET_ASSESSMENT_HISTORY';
export const GET_ASSESSMENT_HISTORY_FULFILLED = 'GET_ASSESSMENT_HISTORY_FULFILLED';
export const GET_ASSESSMENT_HISTORY_REJECTED = 'GET_ASSESSMENT_HISTORY_REJECTED';

export const GET_LOCKED_FOR_VALUE = 'GET_LOCKED_FOR_VALUE';
export const GET_LOCKED_FOR_VALUE_FULFILLED = 'GET_LOCKED_FOR_VALUE_FULFILLED';
export const GET_LOCKED_FOR_VALUE_REJECTED = 'GET_LOCKED_FOR_VALUE_REJECTED';

// GET ASSESSMENT RESULT
export const getAssessmentResult = (id) => ({
  type: GET_ASSESSMENT_RESULT,
  payload: id,
});

export const getAssessmentResultFulfilled = ({ id, data }) => ({
  type: GET_ASSESSMENT_RESULT_FULFILLED,
  payload: { id, data },
});
export const getAssessmentResultRejected = ({ id, error }) => ({
  type: GET_ASSESSMENT_RESULT_REJECTED,
  payload: { id, error },
});

// GET ASSESSMENT HISTORY
export const getAssessmentHistory = (id, params) => ({
  type: GET_ASSESSMENT_HISTORY,
  payload: { id, params },
});

export const getAssessmentHistoryFulfilled = (payload) => ({
  type: GET_ASSESSMENT_HISTORY_FULFILLED,
  payload,
});

export const getAssessmentHistoryRejected = (payload) => ({
  type: GET_ASSESSMENT_HISTORY_REJECTED,
  payload,
});

// FETCH "LOCKED FOR" VALUE
export const getLockedForValue = (id) => ({
  type: GET_LOCKED_FOR_VALUE,
  payload: { id },
});

export const getLockedForValueFulfilled = ({ id, lockedFor }) => ({
  type: GET_LOCKED_FOR_VALUE_FULFILLED,
  payload: { id, lockedFor },
});

export const getLockedForValueRejected = ({ id, error }) => ({
  type: GET_LOCKED_FOR_VALUE_REJECTED,
  payload: { id, error },
});
