import React, { useState, useEffect } from 'react';

import * as api from 'api';

import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';

import {
  ImgCircle, InfoTitle, LineDiagram, Card, Modal, TeamDiagramBarsNext, Callout,
} from 'ui/basic';
import styles from './TeamPotentialResult.module.scss';


const LOW = [ 1, 3.9 ];
const MEDIUM = [ 4, 6.9 ];
const MAX_VALUE = 10;


const TeamPotentialResult = (props) => {
  const { profile, referenceProfile, showAverage = true } = props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  const [ showModal, setShowModal ] = useState();
  const [ modalHeader, setModalHeader ] = useState();
  const [ modalHeaderPosition, setModalHeaderPosition ] = useState();
  const [ modalSubHeader, setModalSubHeader ] = useState();
  const [ modalPreHeader, setModalPreHeader ] = useState();
  const [ modalContent, setModalContent ] = useState();
  const [ modalData, setModalData ] = useState();

  // METHODS
  const getDimensionsData = () => {
    if (!profile) {
      return [];
    }

    const potentialDimensions = [];

    profile.results.forEach((potentialResult) => {
      let range;
      if (referenceProfile && referenceProfile.results) {
        const thisResult = referenceProfile.results
        .find((r) => r.dimensionId === potentialResult.id);
        if (thisResult) {
          range = [
            thisResult.minValue,
            thisResult.maxValue ?? MAX_VALUE,
          ];
        }
      }

      potentialDimensions.push({
        id: potentialResult.id,
        label: potentialResult.name,
        range,
        data: potentialResult.values.map((userValue) => {
          const { user, value } = userValue;

          return {
            id: user.id,
            label: user.name,
            img: !user.isAnonymous ? api.getUserImageUrl(user.id) : undefined,
            value,
          };
        }),
      });
    });

    return potentialDimensions;
  };

  const getSubHeader = (value, userName, dimensionName) => {
    if (value >= LOW[0] && value <= LOW[1]) {
      return translate(
        'potential_dimension_low',
        [ '{{dimension}}', dimensionName, '{{username}}', userName ],
      );
    }

    if (value >= MEDIUM[0] && value <= MEDIUM[1]) {
      return translate(
        'potential_dimension_medium',
        [ '{{dimension}}', dimensionName, '{{username}}', userName ],
      );
    }

    return translate(
      'potential_dimension_high',
      [ '{{dimension}}', dimensionName, '{{username}}', userName ],
    );
  };

  const getCalloutContent = (dimensionId, score) => {
    if (score >= LOW[0] && score <= LOW[1]) {
      return translate(`employee_result_low__${dimensionId}`);
    }

    if (score >= MEDIUM[0] && score <= MEDIUM[1]) {
      return translate(`employee_result_medium__${dimensionId}`);
    }

    return translate(`employee_result_high__${dimensionId}`);
  };

  const renderModalContent = (resultItem) => {
    const { value, dimensionId } = resultItem;

    return (
      <div className={styles.infoModalResult}>
        <div className={styles.reportDiagram}>
          <div className={styles.header}>
            <span>{ translate('big5_report_facet_result') }</span>
            <span>{ Number(value).toFixed(1) }</span>
          </div>

          <div className='marginTopXs'>
            <LineDiagram
              score={value}
            />
          </div>

          <div className={styles.footer}>
            <span>{ translate('big5_report_resut_hint1') }</span>
            <span>{ translate('big5_report_resut_hint2') }</span>
          </div>
        </div>

        <div className={styles.copyDescription}>
          { translate(`potential_results_details_description_dim${dimensionId.split('pot_')[1]}`) }
        </div>

        <div className={styles.callout}>
          <Callout>
            { getCalloutContent(dimensionId, value) }
          </Callout>
        </div>
      </div>
    );
  };

  // Rerender modal content on resize
  useEffect(() => {
    if (!modalData) {
      return;
    }

    setModalContent(renderModalContent(modalData));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bp.isS, bp.isXs, modalData ]);

  return (
    <Card>
      <InfoTitle
        title={translate('potential_results_title')}
        onClick={() => {
          setModalHeader(translate('potential_results_details_title'));
          setModalHeaderPosition('left');
          setModalSubHeader(null);
          setModalPreHeader(null);
          setModalContent(translate('potential_results_details_description'));

          setShowModal(true);
        }}
      />

      <div className={styles.diagram}>
        <TeamDiagramBarsNext
          labelAverage={translate('team_profile_average_title')}
          showAverage={showAverage}
          dimensions={getDimensionsData()}
          showRanges={!!referenceProfile}
          labelRange={referenceProfile && (`${translate('subnavigation_tabs_roles_item1')}: `)}
          onItemClick={(item) => {
            const userName = item.label;
            const userImage = item.img;
            const { value } = item;
            const { dimensionId } = item;
            const { dimensionName } = item;

            setModalHeader(userName);
            setModalHeaderPosition('center');
            setModalSubHeader(getSubHeader(value, userName, dimensionName));

            setModalPreHeader(
              <ImgCircle
                src={userImage}
                size='M'
                label1={userName.split(' ')[0]}
                label2={userName.split(' ')[1]}
              />,
            );

            setModalData({ dimensionId, value });

            setShowModal(true);
          }}
        />
      </div>

      { showModal && (
        <Modal
          header={modalHeader}
          headerPosition={modalHeaderPosition}
          subHeader={modalSubHeader}
          subHeaderPosition='center'
          preHeader={modalPreHeader}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
          onClose={() => {
            setShowModal(false);
            setModalData();
          }}
          onConfirm={() => {
            setShowModal(false);
            setModalData();
          }}
        >
          { modalContent }
        </Modal>
      ) }
    </Card>
  );
};

export default TeamPotentialResult;
