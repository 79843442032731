// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useState } from 'react';

// UTILS
import { useTranslate } from 'utils/translator';
import { HotChipsPeopleWithSearch, Modal } from 'ui/basic';
import { getFullName } from 'utils/users';
import { apiNext } from 'apiNext';
import * as api from 'api';

// STORE NEXT
import { useDispatch } from 'react-redux';
import { showToast } from 'store/actions';


// COMPONENT: EditPeerModal
const EditPeerModal = (props) => {
  // PROPS
  const {
    assessmentId,
    employeeId,
    mail,
    group,
    crossValues,
    onClose,
    onUpdate,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const dispatch = useDispatch();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ selectedUser, setSelectedUser ] = useState([]);
  const [ updating, setUpdating ] = useState(false);

  const onConfirm = async () => {
    try {
      setUpdating(true);
      await apiNext.patch(
        `/core/assessments/${assessmentId}/peers`,
        {
          from: mail,
          to: selectedUser[0].value,
        },
        {
          user: employeeId,
        },
      );
      dispatch(showToast(
        translate('team_created_toast_title'),
        translate('change_peer_success'),
      ));
      onUpdate();
      onClose();
    } catch (err) {
      console.error(err.message);

      // https://blueexcellence.atlassian.net/browse/BDE-40
      const errorMessage = err.responseContent?.error?.errorCode === 1419
        ? 'api_error_cannot_edit_peer'
        : 'api_error_generic';

      dispatch(showToast(null, translate(errorMessage)));
    } finally {
      setUpdating(false);
    }
  };

  const mapSearchResults = useCallback(({ users }) => users
  .map((user) => ({
    id: user.id,
    value: user.mail,
    label: getFullName(user),
    img: api.getUserImageUrl(user.id),
    subLabel: user.companyData?.profession,
    disabled: user.id === employeeId,
  })), [ employeeId ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: EditPeerModal
  return (
    <Modal
      header={translate('change_peer_title')}
      primaryButtonTitle={translate('save')}
      secondaryButtonTitle={translate('cancel_lbl')}
      primaryButtonDisabled={!selectedUser.length || updating}
      ignoredKeys={[ 'Enter' ]}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={onClose}
    >
      <div>
        { translate(
          'change_peer_content',
          [ '{{mail}}', mail.replace('@', '&commat;'),
            '{{group}}', group ],
        ) }

        <HotChipsPeopleWithSearch
          label={translate('change_peer_label')}
          placeholder={translate('change_peer_placeholder')}
          noResultsHint={translate('add_roles_emps__no_results')}
          hint={translate('change_peer_hint')}
          values={selectedUser}
          crossValues={crossValues}
          searchConfig={{
            entrypoint: '/core/company/users/search',
            mapResults: mapSearchResults,
            extraParams: { expand: 'user' },
          }}
          onUpdate={(list) => {
            setSelectedUser(list.slice(0, 1));
          }}
        />
      </div>
    </Modal>
  );
};

export default EditPeerModal;
