import React, { useRef, useState } from 'react';
import styles from './QuickAccessMenu.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// UTILS
import { useTranslate } from 'utils/translator';
import { handleRipple } from 'utils/ripple';

// OTHER COMPONENTS
import { BluCSSTransition, Button } from 'ui/basic';

// STORE
import { useSelector } from 'react-redux';
import {
  selectCapabilities,
  selectFeatures,
  selectInstanceIsAdminFrontend,
} from 'features/framework/storeNext/configurationSlice';

const MENU_ITEMS = [
  {
    id: 'inviteUser',
    labelKey: 'subnavigation_qa_item1',
    path: '/user/invite',
    show: (capabilities, features) => (capabilities.employeesInvite && features.employees)
            || (capabilities.candidatesInvite && features.candidates),
  },
  {
    id: 'createRole',
    labelKey: 'subnavigation_qa_item2',
    path: '/roles/new',
    show: (capabilities, features) => capabilities.rolesCreate && features.roles,
  },
  {
    id: 'createTeam',
    labelKey: 'subnavigation_qa_item3',
    path: '/teams/new',
    show: (capabilities, features) => capabilities.teamsCreate && features.teams,
  },
  {
    id: 'createVacancy',
    labelKey: 'create_vacancy_title',
    path: '/vacancies/new',
    show: (capabilities, features) => capabilities.vacanciesCreate && features.candidates,
  },
  {
    id: 'createSurvey',
    labelKey: 'surveys_quick_access_item',
    path: '/surveys/new',
    show: (capabilities, features) => features.surveys,
  },
];


const QuickAccessMenu = () => {
  const translate = useTranslate();
  const menuRef = useRef();
  const history = useHistory();

  const [ quickAccessMenuIsOpen, setQuickAccessMenuIsOpen ] = useState(false);

  const isAdmin = useSelector(selectInstanceIsAdminFrontend);
  const capabilities = useSelector(selectCapabilities);
  const features = useSelector(selectFeatures);

  const buttonClassNames = classNames(
    styles.buttonBlock,
    { [styles.active]: quickAccessMenuIsOpen },
  );

  const handleBlur = ((event) => {
    if (event.relatedTarget !== menuRef.current) {
      setQuickAccessMenuIsOpen(false);
    }
  });

  return (
    <div onBlur={handleBlur}>
      <div className={buttonClassNames}>
        <Button
          size='S'
          onClick={() => setQuickAccessMenuIsOpen(!quickAccessMenuIsOpen)}
          leadingIcon={IconsSvg.Plus}
        >
          { translate('subnavigation_qa_button_label') }
        </Button>
      </div>

      <BluCSSTransition in={quickAccessMenuIsOpen} classNames={{ ...styles }}>
        <div
          tabIndex='0'
          ref={menuRef}
          className={styles.quickAccessMenu}
        >
          { !isAdmin && MENU_ITEMS.map((menuItem) => {
            if (!menuItem.show(capabilities, features)) {
              return undefined;
            }

            return (
              <div
                key={menuItem.id}
                role='presentation'
                className={styles.menuItem}
                onClick={(event) => {
                  handleRipple(event, styles.colorPrimary3);
                  window.setTimeout(() => {
                    setQuickAccessMenuIsOpen(false);
                    history.push(menuItem.path);
                  }, styles.animationDurationShortMs);
                }}
              >
                <span>{ translate(menuItem.labelKey) }</span>
              </div>
            );
          }) }

          { isAdmin && (
            <div
              role='presentation'
              className={styles.menuItem}
              onClick={(event) => {
                handleRipple(event, styles.colorPrimary3);
                window.setTimeout(() => {
                  setQuickAccessMenuIsOpen(false);
                  history.push('/admin/new-instance');
                }, styles.animationDurationShortMs);
              }}
            >

              <span>{ translate('admin_create_instance_btn') }</span>
            </div>
          ) }
        </div>
      </BluCSSTransition>
    </div>
  );
};

export default QuickAccessMenu;
