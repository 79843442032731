// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './QuestionFreeText.module.scss';

// OTHER COMPONENTS
import { Button, InputNext, ResizableTextArea } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';


// COMPONENT: QuestionFreeText
const QuestionFreeText = (props) => {
  // PROPS
  const {
    question,
    selectedValue,
    allowAnswerSkip = false,
    onChange = () => {},
    onAnswer = () => {},
    onBack = () => {},
    onForward = () => {},
    onHelp = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ value, setValue ] = useState('');
  useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [ selectedValue ]);

  const [ errorMessage, setErrorMessage ] = useState('');
  const validateAnswer = useCallback(() => {
    let errorMessageInternal = '';

    if (question.minAnswerLength && value.trim().length < question.minAnswerLength) {
      errorMessageInternal = translate(
        'question_free_text_min_length_error',
        [ '{{minLength}}', question.minAnswerLength ],
      );
    } else if (question.maxAnswerLength && value.trim().length > question.maxAnswerLength) {
      errorMessageInternal = translate(
        'question_free_text_max_length_error',
        [ '{{maxLength}}', question.maxAnswerLength ],
      );
    }

    return errorMessageInternal;
  }, [ translate, question, value ]);

  const handleChange = (valueParam) => {
    setValue(valueParam);
    onChange(valueParam, question);
  };

  const handleSubmit = useCallback(() => {
    const error = validateAnswer();
    if (error) {
      setErrorMessage(error);
    } else {
      setErrorMessage('');

      let valueInternal = value;
      if ((allowAnswerSkip || question.skipAnswer) && value === '') {
        valueInternal = null;
      }
      onAnswer(valueInternal);
    }
  }, [
    value,
    validateAnswer,
    allowAnswerSkip,
    question.skipAnswer,
    onAnswer,
  ]);

  const [ inputFocused, setInputFocused ] = useState(false);
  const handleKey = useCallback((event) => {
    const { key } = event;
    switch (key) {
      case 'ArrowUp': {
        if (!inputFocused) {
          onBack();
        }
        break;
      }
      case 'ArrowDown': {
        if (!inputFocused) {
          onForward();
        }
        break;
      }
      case 'Enter': {
        if (!inputFocused) {
          handleSubmit();
        }
        break;
      }
      default:
    }
  }, [ inputFocused, onBack, onForward, handleSubmit ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKey);
    return () => {
      window.removeEventListener('keyup', handleKey);
    };
  }, [ handleKey ]);

  if (!question) {
    return null;
  }


  // RENDER: QuestionFreeText
  return (
    <div className={styles.questionFreeText}>
      <div className={styles.gridContent}>
        { question.question && (
          <div className='bluTypeS'>{ question.question }</div>
        ) }

        { question.description && (
          <div className={styles.questionDescription}>
            { translate(question.description) }
          </div>
        ) }

        <div className={styles.questionInput}>
          { !question.multiline && (
            <InputNext
              label={translate('your_answer_lbl')}
              value={value}
              clearValueOnConfirm={false}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              onChange={handleChange}
              onConfirm={handleSubmit}
            />
          ) }

          { question.multiline && (
            <ResizableTextArea
              label={translate('your_answer_lbl')}
              value={value}
              minRows={2}
              maxRows={5}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              onChange={handleChange}
              onConfirm={handleSubmit}
            />
          ) }
        </div>

        { (question.maxAnswerLength || errorMessage) && (
          <div className={styles.bottomText}>
            { errorMessage && (
              <span className={styles.error}>{ errorMessage }</span>
            ) }

            { question.maxAnswerLength && (
              <div className={styles.hint}>
                { `${value.length} / ${question.maxAnswerLength}` }
              </div>
            ) }
          </div>
        ) }

        { (allowAnswerSkip || question.skipAnswer) && (
          <div
            role='button'
            tabIndex={0}
            className={styles.skip}
            onClick={() => onAnswer(null)}
            onKeyPress={() => onAnswer(null)}
          >
            { translate('question_can_not_answer_label') }
          </div>
        ) }

        <Button
          size='M'
          onClick={handleSubmit}
        >
          { translate('wp_ass_selection_button_next') }
        </Button>

        { !question.hideHelp && (
          <Button
            size='S'
            looks='tertiary'
            onClick={onHelp}
          >
            { translate('assessment_help_button') }
          </Button>
        ) }
      </div>
    </div>
  );
};

export default QuestionFreeText;
