// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './StripePaymentElement.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA

// const Config = {};

// COMPONENT: StripePaymentElement
const StripePaymentElement = (props) => {
  // PROPS
  const {
    bottomElements,
    controls,
    onSubmit = () => {}
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const stripe = useStripe();
  const elements = useElements();

  const [ paymentElementReady, setPaymentElementReady ] = useState();
  const [ errorMessage, setErrorMessage ] = useState();
  const [ requestProcessing, setRequestProcessing ] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setRequestProcessing(true);
    const response = await stripe.confirmSetup({
      elements,
      redirect: 'if_required'
    });

    setRequestProcessing(false);
    if (response.error) {
      setErrorMessage(response.error.message);
    } else {
      onSubmit();
    }
  }

  const Controls = controls;
  const BottomElements = bottomElements;

  // RENDER: StripePaymentElement
  return (
    <form
      className={styles.stripePaymentElement}
      onSubmit={handleSubmit}
    >
      <PaymentElement
        options={{terms: {
          card: 'never'
        }}}
        onReady={element => {
          setPaymentElementReady(!!element);
        }}
      />

      { bottomElements && <BottomElements />}

      { errorMessage && (
        <div className={classNames('bluTypeLabelL', 'marginTopS')}>
          { translate('upgrade_to_pp_stripe_error') }
        </div>
      )}

      { controls && (
        <Controls submitButtonDisabled={!paymentElementReady || requestProcessing}/>
      )}

    </form>
  );
};

export default StripePaymentElement;
