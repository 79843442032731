// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Section1.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';

// COMPONENT: Section1
const Section1 = (props) => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // RENDER: Section1
  return (
    <div className={classNames(styles.section1)} id='general-info'>
      <div className={classNames(styles.sTitle)}>
        {translate('9levels_report_subtitle_general_info')}
      </div>
      <div className={classNames(styles.description)}>
        {translate('9levels_report_copy_general_info')}
      </div>
    </div>
  );
};

export default Section1;
