import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Icons } from 'assets/icons';
import { ReactComponent as AnchorImage } from 'assets/icons/icn_arrow_anchor.svg';

import { Callout, Icon, LineDiagram } from 'ui/basic';

// UTILS
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';
import { useTranslate } from 'utils/translator';
import { numberWithForcedDecimals } from 'utils/numberFormatting';
import styles from './Big5Report.module.scss';


const Big5Report = (props) => {
  const {
    dimensionsResults,
    facetsResults,
    anchor,
    onClose,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const big5ResultSchema = useSelector((state) => state.assessments.table[ASSESSMENT_TYPES.BIG5]?.resultSchema ?? []);

  // HELPERS
  const scrollDown = (anchorId) => {
    const section = document.getElementById(anchorId);
    if (section) {
      document.getElementById('body').scrollTo({
        top: section.offsetTop - 100,
        behavior: 'smooth',
      });
    }
  };

  const getCallout = (dimensionIndex, dimension) => {
    const result = dimensionsResults[dimensionIndex];
    if (result >= 1 && result <= 2) {
      return translate(`big5_report_dim_${dimension.id}_callout_very_low`);
    }
    if (result > 2 && result <= 4) {
      return translate(`big5_report_dim_${dimension.id}_callout_low`);
    }
    if (result > 4 && result <= 6) {
      return translate(`big5_report_dim_${dimension.id}_callout_medium`);
    }
    if (result > 6 && result <= 8) {
      return translate(`big5_report_dim_${dimension.id}_callout_high`);
    }
    return translate(`big5_report_dim_${dimension.id}_callout_very_high`);
  };

  // EFFECT HOOKS
  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    };
  }, []);

  useEffect(() => {
    scrollDown(anchor);
  }, [ anchor ]);


  // RENDER: Big5Report
  return (
    <div className={styles.big5Report} data-test='Big5Report'>
      <div className={styles.header}>
        <span>{ translate('big5_report_header_title') }</span>
        <div
          className={styles.close}
          onClick={onClose}
          role='presentation'
          data-test='Big5ReportClose'
        >
          <Icon icon={Icons.CloseBig} />
        </div>
      </div>
      <div className={styles.bodyContainer} id='body'>
        <div className={styles.body}>
          <div className={styles.main}>
            <div className={styles.title}>
              { translate('big5_report_title') }
            </div>
            <div className={styles.contents}>
              { big5ResultSchema.map((dimension) => (
                <span
                  key={`dimension-content-${dimension.id}`}
                  role='presentation'
                  onClick={() => scrollDown(dimension.name.replace(/\s/g, '-').toLowerCase())}
                  className={styles.content}
                >
                  { dimension.name || translate(`big5_report_dim_${dimension.id}`) }
                  <AnchorImage />
                </span>
              )) }
            </div>
            { big5ResultSchema.map((dimension, dimensionIndex) => (
              <div className={styles.dimension} key={`dimension-${dimension.id}`}>
                <div className={styles.title} id={dimension.name.replace(/\s/g, '-').toLowerCase()}>
                  { translate(`big5_report_dim_${dimension.id}`) || dimension.name }
                </div>
                <div className={styles.description}>
                  { translate(`big5_report_dim_${dimension.id}_description`) }
                </div>
                <div className={styles.score}>
                  <div className={styles.row1}>
                    <span className={styles.text}>
                      { translate('big5_report_dimension_result') }
                    </span>
                    <span className={styles.number}>
                      { numberWithForcedDecimals(dimensionsResults[dimensionIndex]) }
                    </span>
                  </div>

                  <div className='marginTopXxs'>
                    <LineDiagram
                      score={dimensionsResults[dimensionIndex]}
                    />
                  </div>

                  <div className={styles.row2}>
                    <span>{ translate('big5_report_resut_hint1') }</span>
                    <span>{ translate('big5_report_resut_hint2') }</span>
                  </div>
                </div>

                <div className={styles.callOutContainer}>
                  <Callout>
                    { getCallout(dimensionIndex, dimension) }
                  </Callout>
                </div>

                <div className={styles.facets}>
                  <div className={styles.title}>
                    { translate(`big5_report_dim_${dimension.id}_facets`) }
                  </div>
                  { dimension.subDimensions.map((facet, facetIndex) => (
                    <div className={styles.facet} key={`facet-${facet.id}`}>
                      <div className={styles.title}>
                        { translate(`big5_report_dim_${dimension.id}_facet_${facet.id}`) || facet.name }
                      </div>
                      <div className={styles.description}>
                        { translate(`big5_report_dim_${dimension.id}_facet_${facet.id}_description`) }
                      </div>
                      <div className={styles.score}>
                        <div className={styles.row1}>
                          <span className={styles.text}>
                            { translate('big5_report_facet_result') }
                          </span>
                          <span className={styles.number}>
                            { numberWithForcedDecimals(facetsResults[dimensionIndex][facetIndex]) }
                          </span>
                        </div>

                        <div className='marginTopXxs'>
                          <LineDiagram
                            score={facetsResults[dimensionIndex][facetIndex]}
                          />
                        </div>

                        <div className={styles.row2}>
                          <span>{ translate('big5_report_resut_hint1') }</span>
                          <span>{ translate('big5_report_resut_hint2') }</span>
                        </div>
                      </div>
                    </div>
                  )) }
                </div>
              </div>
            )) }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Big5Report;
