// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './Peer360Result.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Card } from 'ui/basic/containers/Card';
import {
  Button,
  InfoTitle,
  Modal,
  SkeletonFlexible,
  ShowResultsButton,
} from 'ui/basic';
import { DownloadFileButton } from '../DownloadFileButton';
import { Peer360ResultItem } from './Peer360ResultItem';
import { Peer360Report } from './Peer360Report';

// UTILS
import { useTranslate } from 'utils/translator/translator';
import { replace360PeerResults } from 'utils/assessment';
import { DownloadResultButton } from '../DownloadResultButton';

// STORE
import { useSelector } from 'react-redux';
import { selectAssessmentPdfDownloadHandles } from 'store/selectors/assessment';

// COMPONENT: Peer360Result
const Peer360Result = (props) => {
  // PROPS
  const {
    assessmentData,
    assessmentResults,
    openReport = false,
    showExportIcon = false,
    showInfoTitle = true,
    showReportButton = true,
    exportView = false,
    onExport = () => {},
    onReportClose = () => {},
    assessmentType,
    userId,
    managerControlled,
    sharedWithUser,
    noDataText,
    historyIndex,
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // RESULTS DATA: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ resultsInternal, setResultsInternal ] = useState();

  const downloadHandles = useSelector((state) => selectAssessmentPdfDownloadHandles(state, props));

  useEffect(() => {
    if (!assessmentResults || !assessmentData) {
      return;
    }
    setResultsInternal(replace360PeerResults(assessmentData, assessmentResults));
  }, [ assessmentData, assessmentResults ]);

  // MODALS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ infoModalVisible, setInfoModalVisible ] = useState(false);
  const [ reportVisible, setReportVisible ] = useState(false);
  useEffect(() => {
    setReportVisible(openReport && Boolean(resultsInternal?.length));
  }, [ openReport, resultsInternal?.length ]);

  if (!resultsInternal) {
    return <SkeletonFlexible />;
  }

  // RENDER: Peer360Result
  return (
    <div className={classNames(styles.peer360Result)}>
      <Card>
        <div className={styles.title}>
          { (exportView || !showInfoTitle) && (
            <strong className='bluTypeLabelL'>
              { assessmentData.title }
            </strong>
          ) }
          { (!exportView && showInfoTitle) && (
            <InfoTitle
              title={assessmentData.title}
              onClick={() => setInfoModalVisible(true)}
            />
          ) }

          { (showExportIcon && !noDataText && !exportView) && (
            <DownloadResultButton
              onExport={onExport}
              downloadHandles={downloadHandles}
            />
          ) }
        </div>

        { managerControlled && (
          <ShowResultsButton
            userId={userId}
            assessmentId={assessmentType}
            sharedWithUser={sharedWithUser}
          />
        ) }

        { noDataText
          ? (
            <div className={styles.noDataText}>
              { noDataText }
            </div>
          ) : (
            <>
              <div className='marginTopS'>
                { resultsInternal
                .filter((resultItem) => typeof resultItem.result === 'number')
                .map((resultItem) => (
                  <Peer360ResultItem
                    key={resultItem.id}
                    segmentsCount={assessmentData.renderReferenceMax}
                    data={resultItem}
                    exportView={exportView}
                  />
                )) }
              </div>

              { !exportView && showReportButton && (
                <div className={styles.buttons}>
                  <DownloadFileButton {...downloadHandles} />

                  <div>
                    <Button
                      size='S'
                      looks='secondary'
                      onClick={() => setReportVisible(true)}
                    >
                      { translate('9levels_results_show_report_btn') }
                    </Button>
                  </div>
                </div>
              ) }
            </>
          ) }
      </Card>

      { infoModalVisible && (
        <Modal
          header={assessmentData.title}
          onClose={() => setInfoModalVisible(false)}
          secondaryButtonTitle={translate('close_lbl')}
        >
          { translate(assessmentData.description) }
        </Modal>
      ) }

      { reportVisible && (
        <Peer360Report
          historyIndex={historyIndex}
          userId={userId}
          assessmentData={assessmentData}
          assessmentResults={resultsInternal}
          onClose={() => {
            setReportVisible(false);
            onReportClose();
          }}
          downloadHandles={downloadHandles}
        />
      ) }

    </div>
  );
};

export default Peer360Result;
