/* eslint-disable
jsx-a11y/no-noninteractive-element-to-interactive-role,
jsx-a11y/label-has-associated-control */
import React, { memo } from 'react';
import styles from './RadioButton.module.scss';
import classnames from 'classnames';

const RadioButton = memo((props) => {
  const {
    isError, disabled, checked, value, title, deselectable, onChange = () => {},
  } = props;

  const classNames = classnames(
    styles.radioButton,
    { [styles.error]: isError },
    { [styles.disabled]: disabled },
    { [styles.checked]: checked },
  );

  const handleChange = () => {
    if (checked && !deselectable) {
      return;
    }

    // if radio button is deselectable, 'null' should be returned to parent component
    onChange(checked ? null : value);
  };

  return (
    <label
      className={classNames}
      role='button'
      onClick={handleChange}
      onKeyDown={(e) => {
        // toggle with spacebar
        if (e.key === ' ') {
          e.preventDefault(); // prevent scrolling the page
          handleChange();
        }
      }}
      tabIndex={0}
    >
      <div className={styles.checkmark} />
      <div className={styles.name}>{ title }</div>
    </label>
  );
});

RadioButton.defaultProps = {
  disabled: false,
  name: '',
  title: '',
};

export default RadioButton;
