// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './IstResult.module.scss';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
import {
  Card,
  Button,
  IstIndicator,
} from 'ui/basic';
import { CallHelp } from './CallHelp';
import { IstReport } from './IstReport';

// UTILS
import { useTranslate } from 'utils/translator';
import { BookCoaching } from './BookCoaching';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  subDimensions: [
    'bio_med_hist',
    'symptoms',
    'stressors',
    'stress_amplifier',
    'phq',
    'cbi',
    'eri',
  ],
};

// COMPONENT: IstResult
const IstResult = (props) => {
  // PROPS
  const {
    ist,
    user,
    coachingView = false,
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ showReport, setShowReport ] = useState(false);
  const [ showBookCoaching, setShowBookCoaching ] = useState(false);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: IstResult
  if (!ist?.results) {
    return null;
  }

  const overallVulnerable = ist.results.find(({ id }) => id === 'ist_total')?.classification === 'vulnerable';

  return (
    <div className={classNames(styles.istResult)}>
      <Card>
        <div className={styles.mainHeader}>
          <span className='bluTypeLabelL'>{ translate('ist_assessment_title') }</span>
        </div>
        <div className={styles.content}>
          <IstIndicator color={overallVulnerable && 'red'} />

          <div className={styles.intro}>
            <strong>
              { translate(`ist_result_intro_title_${overallVulnerable ? 'vulnerable' : 'normal'}`) }
            </strong>
            { !coachingView && translate(`ist_result_intro_${overallVulnerable ? 'vulnerable' : 'normal'}`) }
          </div>

          <div className={styles.subDimensions}>
            { ist.results
            .filter(({ id }) => Config.subDimensions.includes(id))
            .map(({ id, classification }) => (
              <div className={styles.subDimension} key={id}>
                <IstIndicator
                  size='S'
                  color={classification === 'vulnerable' ? 'red' : 'light'}
                />
                { translate(`ist_result_subdimension_${id}`) }
              </div>
            )) }
          </div>
          <div className={styles.legend}>
            { translate('ist_result_legend') }
          </div>

          { !coachingView && ist.results.find(({ id }) => id === 'phq_self_harm')?.classification !== 'low_hazard' && (
            <div className={styles.callHelp}>
              <CallHelp />
            </div>
          ) }
        </div>

        <div className={styles.bottom}>
          <Button
            size='S'
            looks='secondary'
            onClick={() => setShowReport(true)}
          >
            { translate('assessment_show_report') }
          </Button>
        </div>
      </Card>

      { showReport && (
        <IstReport
          ist={ist}
          user={user}
          onClose={() => setShowReport(false)}
        />
      ) }

      { showBookCoaching && (
        <BookCoaching
          onClose={() => setShowBookCoaching(false)}
        />
      ) }
    </div>
  );
};

export default IstResult;
