// REACT, STYLE, STORIES & COMPONENT
import React, {useState, useEffect, useCallback} from 'react';
import styles from './QuestionPrefilledName.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import {useSelector} from 'react-redux';

// OTHER COMPONENTS
import { InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE
import {
  selectUserFirstName,
  selectUserLastName
} from 'features/framework/storeNext/configurationSlice';

// CONFIG & DATA
const Config = {
  minLengthFirstName: 2,
  minLengthLastName: 2,
};


// COMPONENT: QuestionPrefilledName
const QuestionPrefilledName = (props) => {
  // PROPS
  const {
    size = 'responsive',
    answer = {},
    onAnswer = () => {}
  } = props;

  const translate = useTranslate();

  // HELPERS
  const checkAnswers = useCallback(({ firstName, lastName }) => {

    const isValid = firstName && lastName
      && firstName.length >= Config.minLengthFirstName
      && lastName.length >= Config.minLengthLastName;

    const answer = { firstName, lastName, isValid };
    onAnswer(answer);
  }, [onAnswer]);

  // COMPONENT/UI STATE and REFS
  const firstNameStore = useSelector(selectUserFirstName);
  const lastNameStore = useSelector(selectUserLastName);
  const [ nameSetFromStore, setNameSetFromStore ] = useState();
  useEffect(() => {
    if (nameSetFromStore) {
      return;
    }

    const firstNameInternal = answer.firstName || firstNameStore;
    const lastNameInternal = answer.lastName || lastNameStore;
    setFirstName(firstNameInternal);
    setLastName(lastNameInternal);
    setNameSetFromStore(true);

    if (!nameSetFromStore && (firstNameInternal || lastNameInternal)) {
      checkAnswers({ firstName: firstNameInternal, lastName: lastNameInternal })
    }
  }, [answer, firstNameStore, lastNameStore, nameSetFromStore, checkAnswers, onAnswer])

  const [ firstName, setFirstName ] = useState('');
  const [ lastName, setLastName ] = useState('');

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS

  // EVENT HANDLES
  const handleFirstName = (firstName) => {
    setFirstName(firstName);
    checkAnswers({firstName, lastName});
  };

  const handleLastName = (lastName) => {
    setLastName(lastName);
    checkAnswers({ firstName, lastName });
  };

  // RENDERS

  // RENDER: QuestionPrefilledName
  return (
    <div className={classNames(styles.questionPrefilledName)}>

      {/* FORM */}
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={firstName}
          label={translate('cp_firstname_placeholder') || 'Dein Vorname'}
          onChange={handleFirstName}>
        </InputNext>
      </div>
      <div className={styles.formRow}>
        <InputNext
          size={size}
          value={lastName}
          label={translate('cp_lastname_placeholder') || 'Dein Nachname'}
          onChange={handleLastName}>
        </InputNext>
      </div>

    </div>
  );
};

export default QuestionPrefilledName;
