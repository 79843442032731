// REACT, STYLE, STORIES & COMPONENT
import React, {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import styles from './PaymentCycleChange.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
import { Button } from 'ui/basic';
import { PricingTable } from 'ui/basic/containers/PricingTable';
// import { Button, InputNext } from 'ui/basic';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator';

// STORE NEXT
import { useDispatch } from 'react-redux';
import { updateSubscription } from 'features/framework/storeNext/configurationSlice';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};
import { getPriceFromCents, PLANS } from 'features/+adminUG/config/payment.config';


// COMPONENT: PaymentCycleChange
const PaymentCycleChange = forwardRef((props, ref) => {
  // PROPS
  const {
    handleFinish = () => {},
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const dispatch = useDispatch();
  const translate = useTranslate();

  const [ processing, setProcessing ] = useState();
  const [ errorMessage, setErrorMessage ] = useState();
  const handleChangeCycle = async () => {
    setProcessing(true);

    try {
      const {
        ok: updatePlanOk, status: updatePlanStatus, data: updatePlanData,
      } = await api.post('/core/company/billing/plan', { plan: PLANS.ANNUAL });
      if (updatePlanOk && updatePlanStatus === 200) {
        const {
          ok: subscriptionOk, status: subscriptionStatus, data: subscriptionData,
        } = await api.get('/core/subscription', { expand: 'plan' });
        if (subscriptionOk && subscriptionStatus === 200) {
          const { switchToPlan } = subscriptionData;
          dispatch(updateSubscription({
            billingPeriod: switchToPlan?.billingPeriod || subscriptionData.billingPeriod,
          }));
          handleFinish(true);
        }
      } else {
        setErrorMessage(updatePlanData.error?.errorMessage);
      }
    } catch (error) {
      setErrorMessage(error.message);
      console.error(error.message);
    } finally {
      setProcessing(false);
    }
  };

  useImperativeHandle(ref, () => ({
    onEnter: () => {
      handleChangeCycle();
    },
  }));

  const [ invoiceResources, setInvoiceResources ] = useState();
  useEffect(() => {
    if (invoiceResources) {
      return;
    }

    api.get('/core/subscription/invoice/resources', { plan: PLANS.ANNUAL })
    .then(({ ok, status, data }) => {
      if (ok && status === 200) {
        setInvoiceResources(data.resources);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
  }, [ invoiceResources ]);

  const [ tableItems, setTableItems ] = useState([]);
  useEffect(() => {
    if (tableItems.length || !invoiceResources) {
      return;
    }

    const { user = {} } = invoiceResources;

    const tableItemsInternal = [
      {
        description: translate('profile_type'),
        quantity: translate('quantity'),
        amount: translate('costs'),
        bold: true,
      },
      {
        ...user,
        description: translate('billed_profiles_annual'),
        amount: (
          <>
            <span>
              { getPriceFromCents(user.pricePerUnit / 12, user.currency, false) }
              { ' ' }
              /
              { ' ' }
              { translate('month') }
            </span>
            <br />
            <span>
              (
              { getPriceFromCents(user.pricePerUnit, user.currency, false) }
              { ' ' }
              /
              { translate('year') }
              )
            </span>
          </>
        ),
      },
      {
        description: translate('total_costs_annual'),
        amount: getPriceFromCents(user.totalPrice, user.currency),
        bold: true,
      },
    ];

    setTableItems(tableItemsInternal);
  }, [
    translate,
    invoiceResources,
    tableItems.length,
  ]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: PaymentCycleChange
  return (
    <div className={styles.paymentCycleChange}>
      <div className='bluContent666'>
        <div className='bluTypeS'>
          { translate('payment_cycle_change_to_annual') }
        </div>
        <div className={classNames('bluTypeCopyStrong', 'marginTopXs')}>
          { translate('upgrade_to_pp_annual_copy') }
        </div>

        { errorMessage && (
          <div className={classNames('error', 'marginTopXs')}>{ errorMessage }</div>
        ) }

        <PricingTable list={tableItems} />

        <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
          { translate('payment_cycle_change_legal_note') }
        </div>
        <div className='bluDialogControls'>
          <div />
          <div>
            <Button
              looks='primary'
              disabled={processing}
              onClick={handleChangeCycle}
            >
              { translate('confirm_lbl') }
            </Button>
          </div>
        </div>

        <div className={classNames('bluTypeCopy', 'marginTopS')}>
          { translate('upgrade_to_pp_hint') }
        </div>
      </div>
    </div>
  );
});

export default PaymentCycleChange;
