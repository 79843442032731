// REACT, STYLE, STORIES & COMPONENT
import React, { useRef, useState } from 'react';
import styles from './GarminIndividualResult.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import {
  Card, CollapsiblePanel, Modal, SkeletonFlexible,
} from 'ui/basic';
import { GarminIcon } from 'features/+garmin/ui/basic/GarminIcon';

// UTILS
import { timestampToFullDate } from 'utils/dateTools';
import { capitalise } from 'utils/textTools';
import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: GarminIndividualResult
const GarminIndividualResult = (props) => {
  // PROPS
  const { result, showPendingState } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const noSleepCopyRef = useRef();
  const noStressCopyRef = useRef();

  const [ infoModalVisible, setInfoModalVisible ] = useState(false);
  const [ infoModalTitle, setInfoModalTitle ] = useState();
  const [ infoModalCopy, setInfoModalCopy ] = useState();

  const bodyBatteryScoreClass = result?.bodyBattery?.score?.class;
  const bodyBatteryTimestamp = result?.bodyBattery?.score?.timestamp;
  const sleepScoreClass = result?.sleep?.score?.class;
  const stressScoreClass = result?.stress?.score?.class;

  const handlePanelExpandLink = (ref) => {
    const refElement = ref.current;
    const links = refElement?.getElementsByTagName('a');

    if (!links || !links[0] || (links[0] && links[0].target)) {
      return;
    }

    links[0].target = '_blank';
  };


  // RENDER: Skeleton
  if (!showPendingState && !result) {
    return <SkeletonFlexible />;
  }

  // RENDER: ScorePanel
  const renderScorePanel = ({
    name,
    faqId,
    scoreClass,
    copyRef,
  }) => (
    <CollapsiblePanel
      header={(
        <div className={styles.collapsibleInnerHeader}>
          <GarminIcon
            looks={name}
            size='S'
            scoreClass={scoreClass}
          />
          <span className='bluTypeLabel'>
            { translate(name) }
          </span>
        </div>
      )}
      headerClassName={styles.collapsibleHeader}
      showTopBorder={false}
      onExpand={() => handlePanelExpandLink(copyRef)}
    >
      <>
        <div className={classNames('bluTypeLabel', 'marginTopXxs')}>
          { scoreClass
            ? translate(`${name}_${scoreClass}_title`)
            : translate('garmin_ind_no_value_title', [
              '{{score}}', `${capitalise(name)} Score`,
            ]) }
        </div>
        <div className={classNames('bluTypeLabel', 'marginTopXxs')} ref={copyRef}>
          { scoreClass
            ? translate(`${name}_${scoreClass}_copy_short`)
            : translate('garmin_ind_no_value_copy', [
              '{{score}}', `${capitalise(name)} Score`,
              '{{faqId}}', faqId,
            ]) }
        </div>
      </>
    </CollapsiblePanel>
  );

  // RENDER: GarminIndividualResult
  return (
    <div className={classNames(styles.garminIndividualResult)} id='garmin-ind-result'>
      <Card hasNoPaddings>
        { /* IF NO RESULT */ }
        { (showPendingState && !result) && (
          <div className={styles.blockWithPaddings}>
            <div className='bluTypeLabelL'>
              { translate('garmin_connect') }
            </div>

            <div className={classNames('bluTypeCopy', 'marginTopS')}>
              { translate('garmin_ind_result_pending_copy') }
            </div>

            <div
              className={styles.additionalInfo}
              role='presentation'
              onClick={() => {
                setInfoModalTitle(translate('garmin_ind_result_pending_additional_info_modal_title'));
                setInfoModalCopy(translate('garmin_ind_result_pending_additional_info_modal_copy'));
                setInfoModalVisible(true);
              }}
            >
              { translate('garmin_ind_result_pending_additional_info') }
            </div>
          </div>
        ) }

        { result && (
          <>
            <div className={styles.blockWithPaddings}>
              <div className='bluTypeLabelL'>
                { translate('garmin_connect') }
              </div>
              <GarminIcon
                looks='body-battery'
                size='M'
                scoreClass={bodyBatteryScoreClass}
              />
              <div className={classNames('bluTypeLabel', 'marginTopXs')}>
                { translate(`body_battery_${bodyBatteryScoreClass}_title`) }
              </div>
              <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                { translate(`body_battery_${bodyBatteryScoreClass}_copy_short`) }
              </div>
            </div>

            <div className={styles.collapsiblePanels}>
              { renderScorePanel({
                name: 'sleep',
                scoreClass: sleepScoreClass,
                copyRef: noSleepCopyRef,
                faqId: 'DWcdBazhr097VgqFufsTk8',
              }) }
              { renderScorePanel({
                name: 'stress',
                scoreClass: stressScoreClass,
                copyRef: noStressCopyRef,
                faqId: 'WT9BmhjacO4ZpxbCc0EKn9',
              }) }
            </div>

            <div className={styles.blockWithPaddings}>
              <div className={styles.hint}>
                { translate('garmin_data_from', [ '{{date}}', timestampToFullDate(bodyBatteryTimestamp) ]) }
              </div>
            </div>
          </>
        ) }
      </Card>

      { /* INFO MODAL */ }
      { infoModalVisible && (
        <Modal
          header={infoModalTitle}
          secondaryButtonTitle={translate('ok')}
          onClose={() => setInfoModalVisible(false)}
        >
          { infoModalCopy }
        </Modal>
      ) }
    </div>
  );
};

export default GarminIndividualResult;
