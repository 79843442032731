// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './UpgradeTrial.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import {millisecondsToDays} from 'utils/dateTools';

// STORE NEXT
import { useSelector } from 'react-redux';
import {
  selectPlanIsTrial,
  selectPlanTrialExpiration,
  selectUserGroup
} from 'features/framework/storeNext/configurationSlice';
import { Link } from 'react-router-dom';


// CONFIG & DATA
// const Config = {};

// COMPONENT: UpgradeTrial
const UpgradeTrial = (props) => {
  // PROPS

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();
  const planIsTrial = useSelector(selectPlanIsTrial)
  const planTrialExpiration = useSelector(selectPlanTrialExpiration)
  const userGroup = useSelector(selectUserGroup);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: UpgradeTrial
  return (
    <>
      { planIsTrial && (
        <div className={classNames(styles.upgradeTrial)}>
          <div className={styles.trialNotice}>
            <div className={styles.text}>
              {translate('trial_ends_text')}
              <span className={styles.highlight}> {millisecondsToDays(planTrialExpiration)} {translate('in_days')}</span>
            </div>
          </div>

          {userGroup === 'admin' &&
            <Link to='/upgrade-from-trial'>
              <Button looks='primary' size='S'>
                {translate('subscribe')}
              </Button>
            </Link>
          }
        </div>
      )}
    </>
  );
};

export default UpgradeTrial;
