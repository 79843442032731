// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './BookCoaching.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// OTHER COMPONENTS
import { Modal } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// CONFIG & DATA
import { CALENDLY_URL } from 'utils/configuration';

// COMPONENT: BookCoaching
const BookCoaching = (props) => {
  // PROPS
  const {
    onClose = () => {},
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const email = localStorage.getItem('mail') || '';

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: BookCoaching
  return (
    <Modal
      header={translate('ist_result_book_coaching_title')}
      primaryButtonTitle={(
        <div className={styles.primaryButton}>
          <IconsSvg.ExternalLink />
          { translate('ist_result_book_coaching_btn') }
        </div>
      )}
      secondaryButtonTitle={translate('cancel_lbl')}
      onConfirm={() => window.open(CALENDLY_URL, '_blank')}
      onClose={onClose}
    >
      { translate(
        'ist_result_book_coaching_description',
        [ '{{email}}', email.replace('@', '&commat;') ],
      ) }
    </Modal>
  );
};

export default BookCoaching;
