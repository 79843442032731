import React, { useEffect, useRef, useState } from 'react';
import styles from './Overview.module.scss';

import { useHistory } from 'react-router';
import { useDispatch, useSelector, Provider } from 'react-redux';

// 3RD PARTY
import * as moment from 'moment';
import classNames from 'classnames';

// STORE
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import * as fromGarminSelectors from 'features/+garmin/store/garmin.selectors';
import * as fromGarminActions from 'features/+garmin/store/garmin.actions';
import {
  selectCapabilitiesNext,
  selectFeaturesNext,
  selectInstanceContainsBluquistFeatures,
  selectIsBalancedYou,
  selectIsPeerControlled,
} from 'store/selectors/configuration';
import {
  getAssessment,
  getAssessmentResult, getMindJourneyScore,
  getStatistic,
  listAssessments,
} from 'store/actions';
import store from 'store';
// eslint-disable-next-line import/no-cycle
import { hasAnyCapability } from 'features/framework/utils/routeNext';

// ASSETS
import InviteEmployeeImage from 'assets/keyvisuals/invite-employees.svg';
import ViewEmployeesProfileImage from 'assets/keyvisuals/view-employees-profile.svg';
import CreateRoleImage from 'assets/keyvisuals/create-role.svg';
import CreateTeamImage from 'assets/keyvisuals/create_team.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/icn_arrow_right.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/icn_arrow_left.svg';

// OTHER COMPONENTS
import AssessmentTeaserNextSkeleton from 'ui/basic/containers/AssessmentTeaserNext/AssessmentTeaserNextSkeleton';
import {
  MyProfileTeaser,
  ButtonCircular,
  StoreNext,
} from 'ui/basic';

import { DialogSequence } from 'ui/molecules';
import { OrganizationOverview } from 'features/framework/pages/MyOrganization/OrganizationOverview';
import { MyCoaching } from 'features/framework/pages';
import RmpImport from './RmpImport';
import Welcome from './Welcome/Welcome';
import AssessmentCarousel from 'ui/basic/containers/AssessmentCarousel/AssessmentCarousel';
// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES, MIND_JOURNEY_MIN_ASSESSMENT_PERIOD } from 'utils/configuration/const/assessment-types';
import { MAPPED_PLATFORM_ROLES } from 'utils/configuration/const/roles';
import { MAIL_TYPES } from 'utils/configuration/const/mailTypes';
import { useBreakpoint } from 'utils/hooks';
import { selectSurveyParticipations } from 'store/selectors/surveys';

// DATA
const DEFAULT_FIRST_ASS = 'Starte mit einem der folgenden Assessments um dich selbst und bluquist besser kennzulernen.';
const DEFAULT_NEXT_ASS = 'Starte dein nächstes Assessment oder schau dir dein Profil an.';
const DEFAULT_VIEW_PROFILE = 'Schau dir dein Profil an.';

const ACTIONS = [
  {
    title: 'home_actions_invite_employees_title',
    description: 'home_actions_invite_employees_description',
    image: InviteEmployeeImage,
    link: '/user/invite',
    forCapabilities: [ 'employeesInvite', 'candidatesInvite' ],
  },
  {
    title: 'home_actions_view_employees_profile_title',
    description: 'home_actions_view_employees_profile_title',
    image: ViewEmployeesProfileImage,
    link: '/employees',
    forCapabilities: 'employeesView',
  },
  {
    title: 'home_actions_create_role_title',
    description: 'home_actions_create_role_description',
    image: CreateRoleImage,
    link: '/roles/new',
    forCapabilities: 'rolesCreate',
    featureKey: 'roles',
  },
  {
    title: 'home_actions_create_team_title',
    description: 'home_actions_create_team_description',
    image: CreateTeamImage,
    link: '/teams/new',
    forCapabilities: 'teamsCreate',
    featureKey: 'teams',
  },
];


// COMPONENT: OVERVIEW
const Overview = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const bp = useBreakpoint();

  const scrollableRef = useRef();
  const actionsRef = useRef();
  const actionRef = useRef();

  let actionIsDown = false;
  let actionStartX;

  const [ actionScrollLeft, setActionScrollLeft ] = useState(0);

  // TEASERS
  const [ teasersLength, setTeasersLength ] = useState(0);
  const [ teasersPageLeftAvailable, setTeasersPageLeftAvailable ] = useState(false);
  const [ teasersPageRightAvailable, setTeasersPageRightAvailable ] = useState(false);

  // ONBOARDING
  const [ showOnboarding, setShowOnboarding ] = useState(false);
  const [ onboardingType, setOnboardingType ] = useState();
  const [ onboardingConfig, setOnboardingConfig ] = useState();
  const [ learnMoreModal, setLearnMoreModal ] = useState();


  // RMP IMPORT
  const [ rmpImportModalVisible, setRmpImportModalVisible ] = useState();

  // SELECTORS
  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);

  const currentLanguage = useSelector((state) => state.localisation?.currentLanguage);

  const assessments = useSelector(fromAssessmentsSelectors.selectAssessmentsTable);
  const assessmentsLoading = useSelector(fromAssessmentsSelectors.selectAssessmentsLoading);
  const showStrengths = useSelector(fromAssessmentsSelectors.selectShowStrengths);

  const capabilitiesNext = useSelector(selectCapabilitiesNext);
  const featuresNext = useSelector(selectFeaturesNext);
  const isPeerControlled = useSelector(selectIsPeerControlled);

  // BALANCED YOU SELECTORS
  const orgScore = useSelector((state) => state.organization.score);
  const orgStatistic = useSelector((state) => state.organization.statistic);
  const mindJourneyAssessment = assessments[ASSESSMENT_TYPES.BALANCED_YOU];
  const assessmentCompletionDate = mindJourneyAssessment?.date;
  const daysAfterCompletion = assessmentCompletionDate
    && moment.duration(moment().diff(moment(assessmentCompletionDate * 1000))).asDays();

  const isBalancedYou = useSelector(selectIsBalancedYou);
  const containsBluquistFeatures = useSelector(selectInstanceContainsBluquistFeatures);

  const showActionTeasers = containsBluquistFeatures
    && (capabilitiesNext.employeesInvite
      || capabilitiesNext.rolesCreate
      || capabilitiesNext.teamsCreate);

  // USER REDIRECTION
  const mailType = localStorage.getItem('mailType');
  localStorage.removeItem('mailType');
  const assessmentId = localStorage.getItem('assessmentId');
  localStorage.removeItem('assessmentId');
  const path = MAIL_TYPES[mailType]?.(assessmentId);
  if (path) {
    history.push(path);
  }

  // GARMIN CONNECTION STATUS
  const garminAccountConnected = useSelector(fromGarminSelectors.selectGarminConnectionStatus);
  useEffect(() => {
    if (garminAccountConnected === null && featuresNext.garmin) {
      dispatch(fromGarminActions.getConnectionStatus());
    }
  }, [ dispatch, featuresNext, garminAccountConnected ]);

  useEffect(() => {
    if (isBalancedYou && (!orgScore || Object.keys(orgScore).length === 0)) {
      dispatch(getMindJourneyScore());
    }
  }, [ dispatch, orgScore, isBalancedYou ]);

  useEffect(() => {
    if (isBalancedYou && (!orgStatistic || Object.keys(orgStatistic).length === 0)) {
      dispatch(getStatistic());
    }
  }, [ dispatch, orgStatistic, isBalancedYou ]);

  // ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessmentsWithNoClifton);
  const surveyParticipations = useSelector(selectSurveyParticipations);

  const [ assessmentsListRequested, setAssessmentsListRequested ] = useState();
  useEffect(() => {
    if (assessmentsListRequested) {
      return;
    }

    setAssessmentsListRequested(true);
    dispatch(listAssessments());
  }, [ dispatch, assessmentsListRequested ]);

  const [ byAssessmentResultRequested, setByAssessmentResultRequested ] = useState();
  useEffect(() => {
    if (!isBalancedYou || byAssessmentResultRequested) {
      return;
    }

    if (!mindJourneyAssessment?.results && !byAssessmentResultRequested) {
      dispatch(getAssessmentResult(ASSESSMENT_TYPES.BALANCED_YOU));

      setByAssessmentResultRequested(true);
    }
  }, [ dispatch, mindJourneyAssessment, byAssessmentResultRequested, isBalancedYou ]);

  const [ assessmentRequested, setAssessmentRequested ] = useState();
  useEffect(() => {
    if (assessmentRequested || !assessmentsFromStore?.length) {
      return;
    }

    assessmentsFromStore.forEach((assessment) => {
      // if assessment is a custom assessment
      if ((!Object.values(ASSESSMENT_TYPES).includes(assessment.id) && assessment.userAvailable)
        && (!assessment.resultSchema || assessment.resultSchemaLanguage !== currentLanguage
        )) {
        dispatch(getAssessment(assessment.id));
      }
    });

    setAssessmentRequested(true);
  }, [
    dispatch,
    assessmentRequested,
    assessmentsFromStore,
    currentLanguage,
  ]);

  // Fetch IST results
  const completedIst = assessmentsFromStore.find((el) => el.id === 'ist' && el.completed);
  const istVulnerable = Boolean(completedIst?.results)
  && completedIst.results.find((el) => el.id === 'phq_self_harm')?.classification !== 'low_hazard';
  const [ istAssessmentResultRequested, setIstAssessmentResultRequested ] = useState();
  useEffect(() => {
    if (completedIst && !completedIst.results && !istAssessmentResultRequested) {
      dispatch(getAssessmentResult(completedIst.id));
      setIstAssessmentResultRequested(true);
    }
  }, [ dispatch, completedIst, istAssessmentResultRequested ]);

  // Count all assessments that have been completed
  const completedAssessmentsLength = Object.values(assessments)
  .filter(Boolean)
  .filter(({ userAvailable }) => userAvailable !== false)
  .filter(({ completed }) => Boolean(completed))
  .length;

  const getTeasersDescription = () => {
    if (isBalancedYou) {
      if (daysAfterCompletion >= MIND_JOURNEY_MIN_ASSESSMENT_PERIOD) {
        return translate('mind_journey__ind_profile_description');
      }
      return null;
    }

    if (completedAssessmentsLength === 0) {
      return translate('home_assessment_teasers_first_ass') || DEFAULT_FIRST_ASS;
    }
    if (assessmentsFromStore?.length === completedAssessmentsLength) {
      return translate('home_assessment_teasers_view_profile') || DEFAULT_VIEW_PROFILE;
    }
    return translate('home_assessment_teasers_next_ass') || DEFAULT_NEXT_ASS;
  };

  const getActionScrollWidth = () => {
    const action = actionRef.current;
    if (!action) {
      return 0;
    }
    if (bp.isS) {
      // 3 action teasers, 32- margin between them
      return (action.clientWidth * 3) + 32;
    }
    // 4 assessment teasers, 48 - margin between them
    return (action.clientWidth * 4) + 48;
  };

  const actionPagingRightClassNames = classNames(
    styles.right,
    {
      [styles.active]: actionsRef.current
        ? (actionScrollLeft + getActionScrollWidth()) < actionsRef.current.clientWidth
        : false,
    },
  );

  const actionPagingLeftClassNames = classNames(
    styles.left,
    { [styles.active]: actionScrollLeft > 0 },
  );

  const actionsPagerIsVisible = () => bp.isS;

  // ACTION HANDLERS
  const handleOnboardingStart = () => {
    if (me.role === MAPPED_PLATFORM_ROLES.RECRUITING_CANDIDATE) {
      setShowOnboarding(true);
      setOnboardingType('candidateOnboarding');
      setOnboardingConfig({
        inject: {
          firstName: me.firstName,
          teasers: (
            <div className={styles.onboardingTeasersContainer}>
              <div className={styles.onboardingTeasersNav}>
                <ButtonCircular
                  icon='ArrowLeft'
                  looks='primary'
                  // disabled={!teasersPageLeftAvailable}
                  // can't dynamically update these without rerendering the assessment atm
                  onClick={() => { scrollableRef.current.pageLeft(); }}
                />
              </div>
              <div className={styles.onboardingTeasers}>
                <Provider store={store}>
                  { /* eslint-disable-next-line no-use-before-define */ }
                  { renderTeasers(
                    (modal) => { setLearnMoreModal(modal); },
                    () => { setLearnMoreModal(); },
                  ) }
                </Provider>
              </div>
              <div className={styles.onboardingTeasersNav}>
                <ButtonCircular
                  icon='ArrowRight'
                  looks='primary'
                  // disabled={!teasersPageRightAvailable}
                  // can't dynamically update these without rerendering the assessment atm
                  onClick={() => { scrollableRef.current.pageRight(); }}
                />
              </div>
            </div>
          ),
        },
      });
    } else if (me.role === MAPPED_PLATFORM_ROLES.COMPANY_ADMINISTRATOR) {
      setShowOnboarding(true);
      setOnboardingType('adminOnboarding');
      setOnboardingConfig({
        inject: {
          firstName: me.firstName,
        },
      });
    } else {
      history.push('/introduction');
    }
  };
  const handleOnboardingClose = () => {
    setShowOnboarding(false);
    setOnboardingType('');
    setOnboardingConfig();
    setLearnMoreModal();
  };
  const handleActionPagingLeft = () => {
    actionsRef.current.scroll({
      left: actionScrollLeft - getActionScrollWidth(),
      behavior: 'smooth',
    });

    setActionScrollLeft((actionScrollLeft - getActionScrollWidth()));
  };

  const handleActionPagingRight = () => {
    actionsRef.current.scroll({
      left: getActionScrollWidth(),
      behavior: 'smooth',
    });

    setActionScrollLeft(getActionScrollWidth());
  };

  // GARMIN CONNECT: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDERS: Teasers
  const renderTeasers = (onModal, onModalClose) => (
    <AssessmentCarousel
      scrollableRef={scrollableRef}
      assessments={assessmentsFromStore}
      surveyParticipations={surveyParticipations}
      onPaginationAvailabilities={([ pageLeftAvailable, pageRightAvailable ]) => {
        setTeasersPageLeftAvailable(pageLeftAvailable);
        setTeasersPageRightAvailable(pageRightAvailable);
      }}
      setRmpImportModalVisible={setRmpImportModalVisible}
      setTeasersLengthCb={setTeasersLength}
      showPaginationBubbles={false}
      teaserProps={{
        fullWidth: true,
        onModal,
        onModalClose,
      }}
    >
      { /* MY PROFILE TEASER */ }
      { Boolean(completedAssessmentsLength) && (
        <div className={styles.teaser}>
          <MyProfileTeaser
            user={me}
            buttonTitle={isBalancedYou
              ? translate('mind_journey__ind_profile_teaser_btn')
              : translate('myprofile_teaser_btn')}
            showStrengths={showStrengths}
          />
        </div>
      ) }
    </AssessmentCarousel>
  );

  const showTeasers = () => {
    if (!assessmentsLoading && !assessmentsFromStore?.length) {
      return false;
    }
    if (isPeerControlled) {
      const controlledAssessments = assessmentsFromStore
      .filter((el) => !(el.peerAssessment && !el.peerProgress));
      if (!controlledAssessments.length) {
        return false;
      }
    }
    return true;
  };

  // RENDER: Overview
  return (
    <div className={styles.overview}>
      <StoreNext>
        <Welcome
          firstName={me.firstName}
          handleOnboardingStart={handleOnboardingStart}
        />
      </StoreNext>

      { istVulnerable && (
        <div className={classNames(styles.orgDevelopmentItems)}>
          <MyCoaching
            ist={completedIst}
            user={me}
          />
        </div>
      ) }

      { /* TEASERS. */ }
      { !showTeasers()
        ? null
        : (
          <div className={styles.teasers}>
            { containsBluquistFeatures && (
              <div className={styles.title}>
                { translate('home_assessment_teasers_title') }
              </div>
            ) }

            { isBalancedYou && (
              <div className={styles.titleWithScrollable}>
                { translate('mind_journey__ind_profile_title') }

                { !assessmentsLoading && teasersLength > 0 && (
                  <div className={styles.scrollingButtons}>
                    <ButtonCircular
                      icon='ArrowLeft'
                      looks='primary'
                      disabled={!teasersPageLeftAvailable}
                      onClick={() => { scrollableRef.current.pageLeft(); }}
                    />
                    <ButtonCircular
                      icon='ArrowRight'
                      looks='primary'
                      disabled={!teasersPageRightAvailable}
                      onClick={() => { scrollableRef.current.pageRight(); }}
                    />
                  </div>
                ) }
              </div>
            ) }

            { isBalancedYou && daysAfterCompletion >= MIND_JOURNEY_MIN_ASSESSMENT_PERIOD && (
              <div className={styles.descriptionBlock}>
                <div className={styles.description}>
                  { translate('mind_journey__ind_profile_description') }
                </div>
              </div>
            ) }

            { containsBluquistFeatures && (
              <div className={styles.descriptionBlock}>
                <div className={styles.description}>
                  { getTeasersDescription() }
                </div>

                { !assessmentsLoading && teasersLength > 0 && (
                  <div className={styles.scrollingButtons}>
                    <ButtonCircular
                      icon='ArrowLeft'
                      looks='primary'
                      disabled={!teasersPageLeftAvailable}
                      onClick={() => { scrollableRef.current.pageLeft(); }}
                    />
                    <ButtonCircular
                      icon='ArrowRight'
                      looks='primary'
                      disabled={!teasersPageRightAvailable}
                      onClick={() => { scrollableRef.current.pageRight(); }}
                    />
                  </div>
                ) }
              </div>
            ) }

            <div className={styles.subTeasers}>
              { assessmentsLoading
                ? <AssessmentTeaserNextSkeleton />
                : renderTeasers() }
            </div>
          </div>
        ) }

      { me.role !== MAPPED_PLATFORM_ROLES.RECRUITING_CANDIDATE && (
        <div className={classNames(styles.orgDevelopmentItems)}>
          <OrganizationOverview />
        </div>
      ) }

      { /* { ACTIONS */ }
      { showActionTeasers && (
        <div className={styles.other}>
          <div className={styles.titleBlock}>
            <div className={styles.title}>
              { translate('home_actions_title') }
            </div>
            { actionsPagerIsVisible() && (
              <div className={styles.pagingButtons}>
                <div
                  className={actionPagingLeftClassNames}
                  role='presentation'
                  onClick={handleActionPagingLeft}
                >
                  <ArrowLeft />
                </div>
                <div
                  className={actionPagingRightClassNames}
                  role='presentation'
                  onClick={handleActionPagingRight}
                >
                  <ArrowRight />
                </div>
              </div>
            ) }
          </div>
          <div
            className={styles.actions}
            ref={actionsRef}
            onMouseDown={(event) => {
              actionIsDown = true;
              actionStartX = event.pageX - actionsRef.current.offsetLeft;
              setActionScrollLeft(actionsRef.current.scrollLeft);
            }}
            onMouseLeave={() => {
              actionIsDown = false;
            }}
            onMouseUp={() => {
              actionIsDown = false;
            }}
            onMouseMove={(event) => {
              if (!actionIsDown) return;
              event.preventDefault();
              const x = event.pageX - actionsRef.current.offsetLeft;
              const walk = (x - actionStartX) * 3; // scroll-fast
              actionsRef.current.scrollLeft = actionScrollLeft - walk;
            }}
            role='presentation'
          >
            { ACTIONS
            .filter(({ forCapabilities }) => hasAnyCapability(capabilitiesNext, forCapabilities))
            .filter(({ featureKey }) => !featureKey || featuresNext[featureKey])
            .map((action) => (
              <div
                key={`action-${action.title}`}
                ref={actionRef}
                className={styles.action}
                onClick={() => history.push(action.link)}
                onKeyPress={() => history.push(action.link)}
                role='link'
                tabIndex='0'
              >
                <div className={styles.imageBlock}>
                  <img
                    alt={action.className}
                    src={action.image}
                  />
                </div>
                <div className={styles.text}>
                  <div className={styles.title}>{ translate(action.title) }</div>
                  <div className={styles.description}>
                    { translate(action.description) }
                  </div>
                </div>
              </div>
            )) }
            <div style={{ minWidth: '60px' }} />
          </div>
        </div>
      ) }

      { /* RMP IMPORT */ }
      { rmpImportModalVisible && (
        <RmpImport onClose={() => setRmpImportModalVisible(false)} />
      ) }

      { /* LEARN MORE MODAL */ }
      { learnMoreModal }

      { /* ONBOARDING */ }
      { showOnboarding && (
        <StoreNext>
          <DialogSequence
            type={onboardingType}
            userId={me.id}
            configOverride={onboardingConfig}
            smallHeader
            closeWithoutPrompt
            onCancel={handleOnboardingClose}
          />
        </StoreNext>
      ) }
    </div>
  );
};

export default Overview;
