// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './TopBottomX.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { Callout, LineDiagram, SegmentedLineDiagram } from 'ui/basic';
import { setDecimalSeparator } from 'utils/userpreference';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: TopBottomX
const TopBottomX = (props) => {
  // PROPS
  const {
    configuration = {},
    content = {},
    assessmentResults,
    segmentsCount,
    isPeerAssessment = false,
  } = props;

  const {
    showTitle = true,
    numResults = 5,
    mode = 'top',
    dimensionLevel = 'all',
    showParentDimension = false,
  } = configuration;
  const {
    topLevelDimension,
  } = content;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const isTop = mode === 'top';
  const isBottom = mode === 'bottom';

  let dimensions = assessmentResults.filter(({ result }) => Number.isFinite(result));
  if (topLevelDimension) {
    dimensions = dimensions.filter((result) => result.id === topLevelDimension);
  }

  if (dimensionLevel !== 'top-level') {
    dimensions.forEach((result) => {
      if (result.subDimensions) {
        dimensions.push(...result.subDimensions);
      }
    });

    if (dimensionLevel === 'sub') {
      dimensions = dimensions.filter((el) => el.parentDimension);
    }
  }

  const sortOrder = isTop ? 1 : -1;
  dimensions = dimensions
  .sort((a, b) => (a.result < b.result ? sortOrder : -sortOrder))
  .slice(0, numResults);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: TopLabel
  const renderTopLabel = (el) => {
    if (el.parentDimension) {
      const parentDimension = assessmentResults.find((resultItem) => resultItem.id === el.parentDimension);
      return (
        <span className={styles.topLabel}>
          { parentDimension.name }
        </span>
      );
    }
    return null;
  };

  // RENDER: TopBottomX
  if (!isTop && !isBottom) {
    const topProps = { ...props, configuration: { ...configuration, mode: 'top' } };
    const bottomProps = { ...props, configuration: { ...configuration, mode: 'bottom' } };
    return (
      <div className={classNames(styles.topBottomX)}>
        <TopBottomX {...topProps} />
        { !showTitle && <div className={styles.divider} /> }
        <TopBottomX {...bottomProps} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.topBottomX)}>
      { showTitle && (
        <span className='bluTypeXs'>
          { translate(`custom_block_${mode}_x`, [ '{{amount}}', numResults ]) }
        </span>
      ) }
      <div className={styles.dimensionWrapper}>
        { dimensions.length
          ? (
            dimensions.map((el, index) => (
              <div className={styles.dimension} key={el.id}>
                <div className={styles.title}>
                  <div className={classNames('bluTypeXs', styles.blue)}>
                    { `#${index + 1}` }
                  </div>
                  <div className={styles.label}>
                    { showParentDimension && renderTopLabel(el) }
                    <span>{ el.name }</span>
                  </div>
                  <span className={styles.blue}>
                    { setDecimalSeparator(el.result) }
                  </span>
                </div>

                { isPeerAssessment ? (
                  <LineDiagram
                    score={el.result}
                    range={[ el.minResult, el.maxResult ]}
                    segmentsCount={segmentsCount}
                  />
                ) : (
                  <SegmentedLineDiagram
                    score={el.result}
                    segmentsCount={segmentsCount}
                  />
                ) }

                { isPeerAssessment && el.minResult !== el.maxResult && (
                  <div className={styles.lowestHighestValues}>
                    <div>
                      <span>
                        { `${translate('lowest_lbl')}:` }
                      </span>
                      <span>{ setDecimalSeparator(el.minResult) }</span>
                    </div>
                    <div>
                      <span>
                        { `${translate('highest_lbl')}:` }
                      </span>
                      <span>{ setDecimalSeparator(el.maxResult) }</span>
                    </div>
                  </div>
                ) }
              </div>
            ))
          ) : (
            <Callout triangleOnTop={false} shortMargin>
              <>
                <span className='bluTypeLabelL'>
                  { translate('no_data') }
                </span>
                <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                  { translate('custom_block_no_data') }
                </div>
              </>
            </Callout>
          ) }
      </div>
    </div>
  );
};

export default TopBottomX;
