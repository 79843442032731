// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './VideoInstructional.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { LoadingMedium } from 'ui/basic';

// UTILS
// import { useTranslate } from 'utils/translator';

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: VideoInstructional
const VideoInstructional = (props) => {
  // PROPS
  const {
    src = ''
  } = props;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  // const translate = useTranslate();

  // LOADING
  const handleOnPlay = () => {
    setReady(true);
    // setTimeout(() => {
    //   setReady(true);
    // }, 1000);
  };
  const [ ready, setReady ] = useState(false)

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: VideoInstructional
  return (
    <div className={classNames(styles.videoInstructional)}>
      <div className={styles.loader}>
        <LoadingMedium />
      </div>
      <div className={classNames(styles.video, {
        [styles.visible]: ready
      })}>
        <video autoPlay loop muted playsInline width="100%"
          onPlay={handleOnPlay}>
          { Array.isArray(src) && src.map((src, index) => (
            <source key={index} src={src.src || src} type={src.type} />
          ))}
          { typeof(src) === 'string' && (
            <source src={src} />
          )}
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </div>
  );
};

export default VideoInstructional;
