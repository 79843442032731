// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './Peer360ItemInfoModal.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button, LineDiagram, Modal } from 'ui/basic';

// UTILS
import { apiNext } from 'apiNext';
import { useTranslate } from 'utils/translator/translator';
import { setDecimalSeparator } from 'utils/userpreference';
import { isValid } from 'utils/numbers';
import { markdown } from 'utils/textTools';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: InfoModal
const Peer360ItemInfoModal = (props) => {
  // PROPS
  const {
    segmentsCount,
    data = {},
    onClose = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // HANDLERS
  const [ feedbackSubmitted, setFeedbackSubmitted ] = useState(false);
  const [ feedbackSubmitError, setFeedbackSubmitError ] = useState();
  const handleFeedbackSubmit = (feedback) => {
    apiNext.post('/core/platform/feedback/content', {
      contentId: data.id,
      contentType: 'peer360 dimension info',
      helpful: feedback === 'Yes',
    })
    .then(() => {
      setFeedbackSubmitted(true);
    })
    .catch((error) => {
      setFeedbackSubmitError(error.message);
      console.error(error.message);
    });
  };

  // RENDER: InfoModal
  return (
    <Modal
      header={data.name || data.id}
      onFeedbackSubmit={(feedback) => handleFeedbackSubmit(feedback)}
      feedbackSubmitted={feedbackSubmitted}
      onClose={onClose}
    >
      <div className={styles.modalContent}>
        <span className='blu-typeCopy'>
          { markdown(data.description) || '-' }
        </span>

        { feedbackSubmitError && (
          <div className={classNames('error', 'marginTopXs')}>{ feedbackSubmitError }</div>
        ) }

        <div className={styles.resultOverall}>
          <span className='bluTypeLabelL'>{ translate('big5_report_dimension_result') }</span>
          <div className={styles.value}>{ setDecimalSeparator(data.result) }</div>
          <div className='marginTopXs'>
            <LineDiagram
              score={data.result}
              range={(isValid(data.minResult) && isValid(data.maxResult))
                ? [ data.minResult, data.maxResult ]
                : undefined}
              segmentsCount={segmentsCount}
            />
          </div>
          { (isValid(data.minResult) && isValid(data.maxResult)
            && data.minResult !== data.maxResult)
            && (
              <div className={styles.lowestHighestValues}>
                <div>
                  <span>
                    { translate('lowest_lbl') }
                    :
                  </span>
                  <span>{ setDecimalSeparator(data.minResult) }</span>
                </div>
                <div>
                  <span>
                    { translate('highest_lbl') }
                    :
                  </span>
                  <span>{ setDecimalSeparator(data.maxResult) }</span>
                </div>
              </div>
            ) }
        </div>

        <div className={styles.resultsPeer}>
          <span className='bluTypeLabelL'>{ translate('peer_360_peer_perspectives') }</span>

          { data.peerResults?.map((peerResult) => (
            <div className={styles.peer} key={peerResult.id}>
              <div className={styles.textContent}>
                <span>{ peerResult.name || translate(`peer_360_peer_perspectives_${peerResult.id}`) }</span>
                <span>{ setDecimalSeparator(peerResult.result) }</span>
              </div>

              <div className='marginTopXs'>
                <LineDiagram
                  score={peerResult.result}
                  range={(isValid(peerResult.minResult) && isValid(peerResult.maxResult))
                    ? [ peerResult.minResult, peerResult.maxResult ]
                    : undefined}
                  segmentsCount={segmentsCount}
                  style={{
                    score: peerResult.id === 'self' ? 'yellow' : 'primary',
                  }}
                />
                { (isValid(peerResult.minResult) && isValid(peerResult.maxResult)
                  && peerResult.minResult !== peerResult.maxResult)
                  && (
                    <div className={styles.lowestHighestValues}>
                      <div>
                        <span>
                          { translate('lowest_lbl') }
                          :
                        </span>
                        <span>{ setDecimalSeparator(peerResult.minResult) }</span>
                      </div>
                      <div>
                        <span>
                          { translate('highest_lbl') }
                          :
                        </span>
                        <span>{ setDecimalSeparator(peerResult.maxResult) }</span>
                      </div>
                    </div>
                  ) }
              </div>
            </div>
          )) }
        </div>

        { (data.peerClassification && data.peerClassification !== 'average') && (
          <div className={styles.classification}>
            <span className='bluTypeLabelL'>
              { translate(`peer_360_classification_title_${data.peerClassification}`) }
            </span>
            <div className={classNames('bluTypeCopy', 'marginTopXs')}>
              { translate(`peer_360_classification_copy_${data.peerClassification}`) }
            </div>
          </div>
        ) }

        <Button
          size='S'
          looks='secondary'
          onClick={onClose}
        >
          { translate('close_lbl') }
        </Button>
      </div>
    </Modal>
  );
};

export default Peer360ItemInfoModal;
