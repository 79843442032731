import React, {useRef} from 'react';
import styles from './ErrorScreen.module.scss';

import {IconsSvg} from 'assets/icons';

import {Button} from 'ui/basic';

import { useTranslate } from 'utils/translator';


const ErrorScreen = (props) => {
  const {error} = props;

  const errorCodeRef = useRef();

  const translate = useTranslate();

  return (
    <div className={styles.errorScreen}>
      <div className={styles.error}>
        <span className={styles.gearEmoji} role={'img'} aria-label={'gear'}>⚙️</span>
        <div className={styles.title}>
          {translate('assessment_registration_error')}
          <span ref={errorCodeRef}>{` ${error.errorCode}`}</span>
        </div>
        <div className={styles.description}>
          {translate('assessment_registration_error_description') ||
          'Leider ist ein Fehler aufgetreten. Bitte versuche es in einigen Minuten nochmal. ' +
          'Falls der Fehler weiterhin besteht, schicke uns bitte eine Nachricht an support@bluquist.com unter Angabe des Fehlercode.'}
        </div>
        <Button
          size='M'
          leadingIcon={IconsSvg.Copy}
          onClick={() => {
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNode(errorCodeRef.current);

            if (selection) {
              selection.removeAllRanges();
              selection.addRange(range);
              document.execCommand('copy');
            }
          }}
        >
          {translate('assessment_registration_error_btn') || 'Copy error code'}
        </Button>
      </div>
    </div>
  )
};

export default ErrorScreen;
