import React, { useState } from 'react';
import styles from './ResetPasswordRequest.module.scss';

import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { requestResetPassword } from 'store/actions';
import * as fromSettingsSelectors from 'store/selectors/settings';
import { selectIsCustomLogoActive } from 'store/selectors/configuration';

import { COMPANY_ID } from 'utils/configuration';

import PoweredBy from 'ui/basic/micro-ui/PoweredBy';
import ResetPasswordRequestForm from 'features/framework/components/ResetPasswordRequestForm';
import ResetPasswordRequestSuccessForm from 'features/framework/components/ResetPasswordRequestSuccessForm';

const ResetPasswordRequest = () => {
  const dispatch = useDispatch();

  const [ email, setEmail ] = useState('');

  const error = useSelector((state) => state.resetPasswordRequest && state.resetPasswordRequest.error);
  const resetPasswordRequestSuccessful = useSelector((state) => state.resetPasswordRequest.status === 'valid');

  const isBalancedYou = useSelector(fromSettingsSelectors.getCompanyIsBalancedYou);
  const isCustomLogoActive = useSelector(selectIsCustomLogoActive);

  const handleResetPassword = (email) => {
    setEmail(email);
    dispatch(requestResetPassword(email, COMPANY_ID));
  };

  return (
    <div className={styles.resetPasswordRequest}>
      <div className={classNames(styles.container, { [styles.balancedYou]: isBalancedYou })}>
        { isCustomLogoActive && (
          <div className={styles.poweredBy}>
            <PoweredBy />
          </div>
        ) }

        { resetPasswordRequestSuccessful
          ? <ResetPasswordRequestSuccessForm email={email} />
          : (
            <ResetPasswordRequestForm
              error={error && error.error && error.error.errorMessage}
              onResetPassword={handleResetPassword}
            />
          ) }
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
