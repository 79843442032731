import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './DialogModal.module.scss';
import classNames from 'classnames';
import Button from 'ui/basic/forms/Button';

// UTILS
import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';

const DialogModal = (props) => {
  const {
    className,
    header,
    description,
    confirmLabel,
    cancelLabel,
    onConfirm,
    onCancel,
    onClose
  } = props;

  const ref = useRef();
  const modalRef = useRef();

  const [isClosing, setIsClosing] = useState(false);

  const dialogModalClassNames = classNames(styles.dialogModal, className, {
    [styles.isClosing]: isClosing
  });
  const modalClassNames = classNames(styles.modal, {
    [styles.isClosing]: isClosing
  });

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    }
  }, []);

  const handleConfirm = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      if (onConfirm) {
        onConfirm();
      }
    }, 250);
  }, [onConfirm]);

  const handleCancel = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      if (onCancel) {
        onCancel();
      }
    }, 250);
  }, [onCancel]);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 250);
  }, [onClose]);

  const handleKeyDown = useCallback( (event) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  }, [handleClose]);

  useEffect(() => {
    const modal = modalRef.current;
    modal.addEventListener('keydown', handleKeyDown);
    modal.focus();

    return () => {
      modal.removeEventListener('keydown', handleKeyDown);
    }
  }, [handleKeyDown]);


  return (
    <div
      ref={ref}
      className={dialogModalClassNames}
      onClick={event => {
        if (ref.current === event.target) {
          handleClose();
        }
      }}
    >
      <div className={modalClassNames} ref={modalRef} tabIndex={'0'}>
        <div className={styles.header}>{header}</div>
        <div className={styles.body}>{description}</div>
        <div className={styles.footer}>
          {cancelLabel ? (
            <div className={styles.cancel}>
              <Button
                size={'S'}
                looks={'cancel'}
                onClick={handleCancel}
              >
                {cancelLabel}
              </Button>
            </div>
          ) : null}

          {confirmLabel &&
          <div className={styles.confirm}>
            <Button
              size={'S'}
              looks={'secondary'}
              onClick={handleConfirm}
            >
              {confirmLabel}
            </Button>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default DialogModal;
