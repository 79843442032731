import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import styles from './AssessmentHistoryDiagram.module.scss';

/* OTHER COMPONENTS */
import { Scrollable } from 'ui/basic/containers/Scrollable';
import { BluCSSTransition } from 'ui/basic';

/* 3RD PARTY */

/* ASSETS */

// UTILS
import {
  breakpoints, useBreakpoint, useDebounce, useWindowWidth,
} from 'utils/hooks';
import { capitalise } from 'utils/textTools';
import { timestampToFullDate } from 'utils/dateTools';
import { isValid } from 'utils/numbers';

/* CONFIG */

const Config = {
  chartHeightOuter: 198,
  chartHeight: 174,
  chartHeightInner: 164,
  yPos: 7, // start from 7 so that the circles on hover can be displayed properly on top
  verticalSegmentsCountDefault: 4,
  numberValuesWidth: 24,
};


const AssessmentHistoryDiagram = (props) => {
  const {
    historyItems = [],
    monthlyMode = true,
    range = [],
    activeIndex,
    pageCountTotal,
    onPaginationAvailabilities = () => {},
  } = props;

  const bp = useBreakpoint();

  const dates = historyItems[0]?.points
  ?.filter((p, index) => isValid(p.value) || (!p.value && historyItems[0].points.length === index + 1))
  .map((p) => p.date) ?? [];

  // dom state
  const [ clientWidth, setClientWidth ] = useState();

  // chart state
  const [ chartWidth, setChartWidth ] = useState(0);
  const [ pageCount, setPageCount ] = useState(0);
  const [ pagePosition, setPagePosition ] = useState();

  const [ cellWidth, setCellWidth ] = useState(0);
  const [ cellsCountForChart, setCellsCountForChart ] = useState(0);
  const [ cellsCountPerSinglePage, setCellsCountPerSinglePage ] = useState(0);

  const [ xStartOffset, setXStartOffset ] = useState(0);
  const [ xOffsetForLabels, setXOffsetForLabels ] = useState(0);

  // scrollable state
  const scrollableRef = useRef(null);

  // update clientWidth on svg node change
  const [ svgNode, setSvgNode ] = useState();
  const componentRef = useCallback((node) => {
    if (node) {
      setSvgNode(node);
    }
  }, []);

  const [ verticalSegmentsDividerValues, setVerticalSegmentsDividerValues ] = useState([]);
  const [ verticalSegmentsCount, setVerticalSegmentsCount ] = useState(0);
  useEffect(() => {
    let count = Config.verticalSegmentsCountDefault;

    // get a total value first
    const total = range[1] - range[0];

    // segments count: 5, 4 or 3
    for (let i = 5; i >= 3; i -= 1) {
      if (total % i === 0) {
        count = i;
        break;
      }
    }

    const step = total / count;
    let dividerValue = range[0];
    const dividerValues = new Array(count + 1).fill(1).map((value, index) => {
      if (index > 0) {
        dividerValue += step;
      }

      return dividerValue;
    });

    setVerticalSegmentsCount(count);
    setVerticalSegmentsDividerValues(dividerValues);
  }, [ range ]);

  // HELPERS
  const updateDOMDerivedVars = (node) => {
    let { clientWidth: clientWidthInternal } = node;

    // client width
    clientWidthInternal -= Config.numberValuesWidth;
    setClientWidth(clientWidthInternal);

    // cell width
    let cellWidthInternal;
    if (bp.bpWidth >= breakpoints.S.bpWidth) {
      cellWidthInternal = monthlyMode ? 75 : 150;
    } else {
      cellWidthInternal = 55;
    }

    cellWidthInternal -= 1; // '-1' goes for line width on the right of each segment
    setCellWidth(cellWidthInternal);

    let pageCountInternal = Math.max(
      Math.ceil((cellWidthInternal * (dates.length - 1)) / clientWidthInternal),
      1,
    );

    if (pageCountTotal) {
      pageCountInternal = Math.ceil((cellWidthInternal * pageCountTotal) / clientWidthInternal);
    }

    setPageCount(pageCountInternal);

    // chart width
    const chartWidthInternal = Math.ceil(pageCountInternal * clientWidthInternal);
    setChartWidth(chartWidthInternal);

    // cells per page
    const cellsCountForChartInternal = Math.ceil(chartWidthInternal / cellWidthInternal);
    setCellsCountForChart(cellsCountForChartInternal);

    const cellsCountForChartPageInternal = Math.ceil(clientWidthInternal / cellWidthInternal);
    setCellsCountPerSinglePage(cellsCountForChartPageInternal);

    // x offset for labels
    const restForCells = dates.length % cellsCountForChartInternal;
    if (restForCells !== 0) {
      const diff = cellsCountForChartInternal - restForCells;
      setXOffsetForLabels((diff - 1) * cellWidthInternal);
    } else {
      setXOffsetForLabels(0);
    }

    // x start offset
    setXStartOffset(chartWidthInternal % cellWidthInternal);
  };

  // EFFECT HOOKS
  // update clientWidth on windowWidth change
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 1200);
  useEffect(() => {
    if (svgNode) {
      updateDOMDerivedVars(svgNode);
    }
    // eslint-disable-next-line
  }, [debouncedWindowWidth, svgNode, dates]);

  // VERTICAL LINES
  const renderVerticalLines = () => {
    const lines = [];

    let x;
    const y1 = Config.yPos;
    const y2 = Config.chartHeight + Config.yPos;

    new Array(cellsCountForChart - 1).fill(1)
    .forEach((item, index) => {
      if (index === 0) {
        x = xStartOffset;
      } else {
        x += cellWidth;
      }

      lines.push(
        <line
          // eslint-disable-next-line react/no-array-index-key
          key={`ver-line-${index}`}
          x1={x}
          y1={y1}
          x2={x}
          y2={y2}
          strokeWidth='1'
          stroke={styles.colorGrey3}
        />,
      );
    });

    return lines;
  };

  const renderActiveMarker = () => {
    const markerThinSideWidth = 2;

    const x = xStartOffset + xOffsetForLabels + (cellWidth * activeIndex) + (cellWidth / 2) - (markerThinSideWidth / 2);
    const y1 = Config.yPos;
    const y2 = y1 + Config.chartHeight;

    const markerX1 = 0;
    const markerX2 = markerX1 + markerThinSideWidth;
    const markerY1 = y1;
    const markerY2 = y2 - 10;

    return (
      <polygon
        key='current-marker'
        className={styles.currentMarker}
        transform={`translate(${x})`}
        points={`
          ${markerX1},${markerY1}
          ${markerX2},${markerY1}
          ${markerX2},${markerY2 - 10}
          ${markerX2 + 5},${markerY2}
          ${markerX1 - 5},${markerY2}
          ${markerX1},${markerY2 - 10}
          ${markerX1},${markerY1}
        `}
      />
    );
  };

  // HORIZONTAL LINES
  const renderHorizontalLines = () => {
    const lines = [];

    new Array(verticalSegmentsCount + 1).fill(1)
    .forEach((_, index) => {
      const x1 = 0;
      const x2 = clientWidth * pageCount;

      const cellHeight = (Config.chartHeightInner / verticalSegmentsCount);
      const y = Config.yPos + (cellHeight * index);

      lines.push(
        <line
          // eslint-disable-next-line react/no-array-index-key
          key={`hor-line-${index}`}
          x1={x1}
          y1={y}
          x2={x2}
          y2={y}
          strokeWidth='1'
          stroke={styles.colorGrey3}
        />,
      );
    });

    return lines;
  };

  const [ circleX, setCircleX ] = useState(0);
  const [ circleY, setCircleY ] = useState(0);

  const [ circleIsVisible, setCircleIsVisible ] = useState(false);
  const [ circleColor, setCircleColor ] = useState();

  const [ circleValue, setCircleValue ] = useState({});

  const circleIndicatorRef = useRef();

  const renderChartLine = () => {
    const polyLines = [];

    historyItems.forEach((item) => {
      const circles = [];

      let pointsInternal = '';
      const cellHeight = (Config.chartHeightInner / verticalSegmentsCount);

      const itemPoints = item.points.filter((itemPoint) => isValid(itemPoint.value));

      itemPoints.forEach((dataPoint, index) => {
        const { value, date } = dataPoint;

        if (!isValid(value)) {
          return;
        }

        let rangeStart = range[0];
        let rangeEnd = range[1];
        let valueInternal = value;

        if (rangeStart > 0) {
          const diffToZero = Math.abs(0 - rangeStart);
          rangeStart -= diffToZero;
          rangeEnd -= diffToZero;
          valueInternal -= diffToZero;
        }

        const sum = Math.abs(rangeStart) + Math.abs(rangeEnd);
        const x = (cellWidth * index) + (cellWidth / 2) + xOffsetForLabels + xStartOffset;

        // y position for the range with no negative values, e.g. [0, 6]
        let y = Config.yPos + (Config.chartHeightInner - ((Config.chartHeightInner * valueInternal) / sum));

        // if range includes negative values, e.g. [-2, 2]
        if (range[0] < 0) {
          const extraCut = verticalSegmentsCount / 2;
          y -= cellHeight * extraCut;
        }
        y = Math.floor(y);

        circles.push(
          <circle
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            cx={x}
            cy={y}
            r='6'
            strokeWidth='30'
            stroke='transparent'
            fill='none'
            className={styles.hoverCircle}
            onMouseOver={() => {
              setCircleX(x);
              setCircleY(y);

              setCircleColor(styles[`color${capitalise(item.color)}`]);

              setCircleValue({
                label: item.label,
                value,
                date,
              });

              setCircleIsVisible(true);
            }}
            onMouseLeave={(event) => {
              if (event.relatedTarget === circleIndicatorRef.current) {
                return;
              }

              setCircleIsVisible(false);
            }}
          />,
        );

        pointsInternal += `${x},${y} `;
      });

      if (itemPoints?.length === 1) {
        const [ x, y ] = pointsInternal.trim().split(',');
        polyLines.push(
          <g key={item.id}>
            <circle
              cx={x}
              cy={y}
              r={6}
              fill={styles[`color${capitalise(item.color)}`]}
            />

            { circles }
          </g>,
        );
      } else if (itemPoints?.length > 1) {
        polyLines.push(
          <g key={item.id}>
            <polyline
              stroke={styles[`color${capitalise(item.color)}`]}
              strokeWidth='2'
              fill='none'
              points={pointsInternal}
            />

            { circles }
          </g>,
        );
      }
    });

    return polyLines;
  };

  const renderBottomLabels = () => {
    let label;
    const texts = [];

    let x = (cellWidth / 2) + xOffsetForLabels + xStartOffset;
    dates.forEach((date, index) => {
      x += (index === 0 ? 0 : cellWidth);

      if (monthlyMode) {
        label = timestampToFullDate(date, 'DD.MM');
      } else {
        label = timestampToFullDate(date, 'YYYY');
      }

      const text = (
        <text
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          x={x}
          y={Config.yPos + Config.chartHeight}
        >
          { label }
        </text>
      );

      texts.push(text);
    });

    return texts;
  };

  const [ year, setYear ] = useState();

  // RENDER
  return (
    <div ref={componentRef} className={styles.assessmentHistoryDiagram}>
      <div className={styles.year}>{ year }</div>

      <div className={styles.flexContainer}>
        <div className={styles.numberValues}>
          { verticalSegmentsDividerValues.map((value, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>{ value }</div>
          )) }
        </div>

        <BluCSSTransition
          in={circleIsVisible}
          classNames={{ ...styles }}
        >
          <div
            className={styles.tooltip}
            style={{
              // 24 - top padding, 6 - circle radius, 2 - spacing between
              top: circleY + 24 + 6 + 2,
              // 25 - centering the tooltip
              left: circleX - (clientWidth * pagePosition) - 25,
            }}
          >
            <div className={styles.row1}>
              <span>{ circleValue.label }</span>
              <span>{ circleValue.value }</span>
            </div>
            <span>{ timestampToFullDate(circleValue.date) }</span>
          </div>
        </BluCSSTransition>

        { /* don't render anything before we have clientWidth */ }
        { clientWidth && (
          <div className={styles.scrollable}>
            <Scrollable
              ref={scrollableRef}
              startRight
              pagination
              drag
              showPaginationBubbles
              showPagerButtons
              refresh={pageCount}
              reset={monthlyMode}
              onPaginationAvailabilities={([ , , pagePositionParam, pageToLeft ]) => {
                const cellsPerPage = Math.floor(clientWidth / cellWidth);
                const start = pagePositionParam === 0
                  ? 0
                  : dates.length - (pagePositionParam * cellsPerPage);

                const part = dates.slice(start, start + cellsPerPage + 1);
                const date = timestampToFullDate(part[0], 'YYYY');
                setYear(date);

                setPagePosition(pagePositionParam);

                onPaginationAvailabilities({
                  newPagePosition: pagePositionParam,
                  totalPagesCount: pageCount,
                  cellsCountPerPage: cellsCountPerSinglePage,
                  pageToLeft,
                });
              }}
            >
              <svg
                width={chartWidth}
                height={Config.chartHeightOuter}
                className={styles.chart}
              >
                { renderHorizontalLines() }

                { (isValid(xStartOffset) && isValid(xOffsetForLabels)) && (
                  <>
                    { renderVerticalLines() }
                    { renderBottomLabels() }
                    { isValid(activeIndex) && renderActiveMarker() }
                    { renderChartLine() }
                  </>
                ) }

                <BluCSSTransition
                  in={circleIsVisible}
                  classNames={{ ...styles }}
                >
                  <>
                    <line
                      x1={circleX}
                      y1={Config.yPos}
                      x2={circleX}
                      y2={Config.yPos + Config.chartHeightInner}
                      strokeWidth='2'
                      stroke={styles.colorGrey4}
                    />

                    <circle
                      ref={circleIndicatorRef}
                      className={styles.indicatorCircle}
                      cx={circleX}
                      cy={circleY}
                      r='6'
                      strokeWidth='2'
                      stroke={circleColor}
                      fill='white'
                    />
                  </>
                </BluCSSTransition>
              </svg>
            </Scrollable>
          </div>
        ) }

      </div>

    </div>
  );
};

export default AssessmentHistoryDiagram;
