// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './AssessmentHistory.module.scss';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Card, PillGroup } from 'ui/basic';
import { AssessmentHistoryDiagram } from './AssessmentHistoryDiagram';
// eslint-disable-next-line import/no-cycle

// UTILS
import { useTranslate } from 'utils/translator';

// CONFIG & DATA
const Config = {
  colors: [ 'turquoise', 'yellow', 'green', 'orange', 'purple', 'blue', 'red' ],
};


// COMPONENT: AssessmentHistory
const AssessmentHistory = (props) => {
  // PROPS
  const {
    results = [],
    range,
    historyIndex,
    monthlyMode = true,
    title = 'assmnt_history_title',
    pageCountTotal,
    onPageUpdate = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const allItem = { id: 'all', label: translate('all') };
  const [ selectedItem, setSelectedItem ] = useState(allItem);

  // List all diagram items
  const items = [];
  results.forEach(({ results: res = [] }) => {
    res.forEach(({ id, name: label }) => {
      if (items.some((item) => item.id === id)) {
        return;
      }

      // Add item if not included yet
      const points = results.map((point = {}) => ({
        value: point.results.find(({ id: pId }) => pId === id)?.result ?? null,
        date: point.date,
      }));
      const index = Object.keys(items).length;
      const colorType = index % Config.colors.length;
      const colorIntensity = Math.floor(index / Config.colors.length) + 1;
      if (points.every(({ value }) => value === null || Number.isFinite(value))) {
        items.push({
          id,
          label,
          points,
          color: `${Config.colors[colorType]}${colorIntensity}`,
        });
      }
    });
  });
  // Add 'all' option at the beginning
  items.unshift(allItem);


  // RENDER: AssessmentHistory
  return (
    <div className={classNames(styles.assessmentHistory)}>
      <Card hasPaddingsForModal>
        <span className='bluTypeXxs'>
          { translate(title) }
        </span>
        <div className='marginTopXs'>
          <PillGroup
            activeIndex={items.findIndex((item) => item.id === selectedItem.id)}
            looks='primary'
            pillItems={items}
            onPillClick={(pillItem) => {
              setSelectedItem(pillItem);
            }}
          />
        </div>

        <AssessmentHistoryDiagram
          historyItems={selectedItem.id === allItem.id ? items.slice(1) : [ selectedItem ]}
          range={range}
          activeIndex={(results.length - 1) - historyIndex}
          monthlyMode={monthlyMode}
          pageCountTotal={pageCountTotal}
          onPaginationAvailabilities={onPageUpdate}
        />
      </Card>
    </div>
  );
};

export default AssessmentHistory;
