import React, { useState, useEffect } from 'react';
import styles from './BigToggle.module.scss';
import classNames from 'classnames';


const BigToggle = (props) => {
  const {
    activeIndex = 0,
    options = [],
    inlineStyling,
    onToggle = (newValue) => {}, // deprecated
    onChange = (newIndex) => {}
  } = props;

  const [internalValue, setInternalValue] = useState(options[0]);

  useEffect(() => {
    setInternalValue(options[activeIndex]);
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps
  // NOTE: options excluded from dependency array
  // because some components set options on every render

  return (
    <div className={classNames(styles.bigToggle, {[styles.inline]: inlineStyling})}>
      {/*BLUE BACKGROUND*/}
      <div className={classNames(
        styles.blue,
        {[styles.right]: options.indexOf(internalValue) === 1}
      )}></div>

      {options.map((option, index) => (
        <div
          key={`option${index}`}
          className={classNames(
            styles.option,
            {[styles.active]: option === internalValue}
          )}
          onClick={(event) => {
            setInternalValue(option);

            onToggle(option);
            onChange(index);
          }}
        >
          {option}
        </div>
      ))}
    </div>
  )
};

export default BigToggle;
