// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';

// 3RD PARTY
// eslint-disable-next-line import/no-cycle
import HotChipsPeople from './HotChipsPeople';

// UTILS
import { useLatestCall } from 'utils/hooks';

// CONFIG & DATA
const Config = {
  PAGINATION: 10,
};


// COMPONENT: HotChipsPeopleWithSearch
const HotChipsPeopleWithSearch = (props) => {
  // PROPS
  const {
    searchConfig = {},
    ...args
  } = props;
  const {
    entrypoint,
    mapResults,
    extraParams,
    ignoreEmptyString,
  } = searchConfig;

  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const [ pagination, setPagination ] = useState(0);
  const [ cumulatedResults, setCumulatedResults ] = useState([]);

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [
    searchLoading,
    searchResults,
    setSearchString,
    searchTotal,
  ] = useLatestCall(
    entrypoint,
    mapResults,
    {
      limit: Config.PAGINATION,
      offset: Config.PAGINATION * pagination,
      ...extraParams,
    },
    ignoreEmptyString,
  );

  useEffect(() => {
    if (searchResults?.length) {
      setCumulatedResults((state) => [ ...state, ...searchResults ]);
    }
  }, [ searchResults ]);

  const onSearch = useCallback((searchString) => {
    setSearchString(searchString);
    setCumulatedResults([]);
    setPagination(0);
  }, [ setSearchString ]);

  const onPaginationNext = useCallback(() => {
    setPagination((state) => state + 1);
  }, []);
  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS


  // RENDER: HotChipsPeopleWithSearch
  return (
    <HotChipsPeople
      {...args}
      loadingSearchResults={searchLoading}
      searchResults={cumulatedResults.length ? cumulatedResults : undefined}
      searchTotal={searchTotal}
      onSearch={onSearch}
      onPaginationNext={onPaginationNext}
    />
  );
};

export default HotChipsPeopleWithSearch;
