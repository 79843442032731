// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect } from 'react';
import styles from './Ist.module.scss';

// ASSETS
import User1Image from './assets/user1.png';
import User2Image from './assets/user2.png';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { IstResult } from 'ui/molecules';
import {
  Button,
  Card,
  InfoCard,
  SkeletonFlexible,
} from 'ui/basic';
import { RecommendationCard } from 'ui/basic/molecules/RecommendationCard';
import { BookCoaching } from 'ui/molecules/IstResult/BookCoaching';

// UTILS
import { useTranslate } from 'utils/translator';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

// STORE NEXT
import { useSelector, useDispatch } from 'react-redux';
import { selectRecommendationsUnarchived } from 'store/selectors/recommendations';
import { selectAssessments } from 'store/selectors/assessment';
import { getCurrentUser } from 'store/selectors/currentUser';
import { getAssessmentResult } from 'store/actions';


// CONFIG & DATA
// const Config = {};

// COMPONENT: Ist
const Ist = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();
  const dispatch = useDispatch();

  const recommendations = useSelector(selectRecommendationsUnarchived);
  const assessmentsFromStore = useSelector(selectAssessments);
  const me = useSelector(getCurrentUser);
  const [ showBookCoaching, setShowBookCoaching ] = useState(false);

  const istAssessment = assessmentsFromStore.find(({ id }) => id === ASSESSMENT_TYPES.IST);
  const hasResults = Boolean(istAssessment?.results?.length);

  useEffect(() => {
    if (!hasResults) {
      dispatch(getAssessmentResult(ASSESSMENT_TYPES.IST));
    }
  }, [ dispatch, hasResults ]);


  // RENDER: Ist
  if (!hasResults) {
    return (
      <div className={classNames(styles.ist)}>
        <SkeletonFlexible noHeader repeat={9} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.ist)}>
      <div className={styles.gridContainer}>
        <div className={styles.left}>
          <IstResult
            ist={istAssessment}
            user={me}
          />
        </div>

        <div className={styles.right}>
          <InfoCard
            title={translate('ist_info_title')}
            content={translate('ist_assessment_description')}
          />

          <Card hasPaddingsForModal>
            <div className={styles.bookCoachingSession}>
              <span className='bluTypeLabelL'>{ translate('ist_result_book_cta') }</span>
              <div className={styles.userImages}>
                <img src={User1Image} alt='coach1Image' />
                <img src={User2Image} alt='coach2Image' />
              </div>
              <div className={classNames('bluTypeCopy', 'marginTopXs')}>
                { translate('ist_result_book_coach') }
              </div>
              <Button
                size='S'
                onClick={() => setShowBookCoaching(true)}
              >
                { translate('ist_result_call_help_btn') }
              </Button>
            </div>
          </Card>

          { recommendations
          .filter((el) => el.module === ASSESSMENT_TYPES.IST)
          .map((el) => <RecommendationCard key={el.id} {...el} />) }
        </div>
      </div>

      { showBookCoaching && (
        <BookCoaching
          onClose={() => setShowBookCoaching(false)}
        />
      ) }
    </div>
  );
};

export default Ist;
