// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Comment.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';
import * as moment from 'moment';

// OTHER COMPONENTS
import {ImgCircle, TextEditor} from 'ui/basic';

// UTILS

// STORE

// CONFIG & DATA
// const Config = {};

// COMPONENT: Comment
const Comment = (props) => {
  // PROPS
  const { authorName, authorImage, date, text, showTopBorder} = props;

  // SPECIAL HOOKS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDER

  // RENDER: Comment
  return (
    <div className={classNames(styles.comment, {[styles.topBorder]: showTopBorder})}>
      <ImgCircle src={authorImage} fullLabel={authorName} size='S'/>

      <div className={styles.content}>
        {/*HEADER*/}
        <div className={styles.header}>
          <div className={styles.authorName}>{authorName}</div>

          {date &&
          <div className={styles.dateTime}>
            <span>{moment(date).format('DD.MM.YY')}</span>
            <span>{moment(date).format('HH:mm')}</span>
          </div>
          }
        </div>

        {/*TEXT*/}
        <div className={styles.text}>
          <TextEditor
            content={text}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default Comment;
