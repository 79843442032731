// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './AddPeersModal.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import { useParams } from 'react-router';
import classNames from 'classnames';

// OTHER COMPONENTS
import { HotChips, Modal } from 'ui/basic';
import { PeerIconsLine } from '../../Peer360Setup/PeerIconsLine';
import { PeerHotChipsPeople } from '../../PeerHotChipsPeople';

// UTILS
import { apiNext } from 'apiNext';
import { useTranslate } from 'utils/translator';
import { capitalise } from 'utils/textTools';
import REGEXES from 'utils/configuration/const/regexes';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: AddPeersModal
const AddPeersModal = (props) => {
  // PROPS
  const {
    peerProgress = {},
    peerGroup = {},
    crossValues = [],
    allowInviteInternalUsers = true,
    onAdd = () => {},
    onClose = () => {},
    employeeId,
  } = props;

  // SPECIAL HOOKS
  const params = useParams();
  const translate = useTranslate();

  const { customAssessmentId } = params;

  const [ newPeers, setNewPeers ] = useState([]);
  const [ errorMessage, setErrorMessage ] = useState('');

  const [ submitProcessing, setSubmitProcessing ] = useState(false);
  const handleSubmit = () => {
    setSubmitProcessing(true);
    apiNext.put(
      `/core/assessments/${customAssessmentId}/peers`,
      {
        peers: newPeers.map(({ id, value, isEmail }) => (isEmail
          ? { group: peerGroup.id, email: value }
          : { group: peerGroup.id, user: id }
        )),
      },
      employeeId ? { user: employeeId } : undefined,
    )
    .then(() => onAdd())
    .catch((error) => {
      setErrorMessage(error.message);
    })
    .finally(() => {
      setSubmitProcessing(false);
    });
  };

  // RENDER: AddPeersModal
  return (
    <Modal
      header={translate('add_peers_lbl', [ '{{peerGroup}}', capitalise(peerGroup.label) ])}
      primaryButtonTitle={translate('add')}
      secondaryButtonTitle={translate('cancel_lbl')}
      primaryButtonDisabled={submitProcessing || !newPeers.length}
      ignoredKeys={[ 'Enter' ]}
      closeOnConfirm={false}
      onConfirm={handleSubmit}
      onClose={onClose}
    >
      <div className={styles.addPeersModal}>
        <div className='bluTypeCopy'>
          { peerGroup.description }
        </div>

        <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
          { translate('add_peers_copy') }
        </div>

        <div className='marginTopS'>
          <span className='bluTypeLabelL'>
            { translate('peer_360_group_invite_peers') }
          </span>
          <PeerIconsLine
            total={newPeers.length + peerProgress.invited}
            min={peerGroup.minPeers}
            max={peerGroup.maxPeers}
          />

          { allowInviteInternalUsers && (
            <PeerHotChipsPeople
              values={newPeers}
              crossValues={crossValues}
              onUpdate={setNewPeers}
              employeeId={employeeId}
            />
          ) }

          { !allowInviteInternalUsers && (
            <HotChips
              hint={translate('invite_emp_emails_hint')}
              errorHint={translate('invite_emp_emails_hint_invalid')}
              errorHintDuplicate={translate('invite_emp_emails_duplicate_hint')}
              placeholder={translate('email_add_lbl')}
              validate={{ pattern: REGEXES.EMAIL }}
              values={newPeers.map((newPeer) => newPeer.value)}
              onUpdate={(newMails) => setNewPeers(newMails.map((newMail) => ({
                value: newMail,
                isEmail: true,
              })))}
              required={peerGroup.required}
            />
          ) }
        </div>

        { errorMessage && (
          <div className={styles.error}>
            { errorMessage }
          </div>
        ) }

      </div>
    </Modal>
  );
};

export default AddPeersModal;
