// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useEffect, useReducer, useRef,
} from 'react';
import styles from './DropDownSearchable.module.scss';
import { reducer, initialState } from './DropDownSearchable.reducer';

// ASSETS
import { ReactComponent as IconSearchBig } from 'assets/icons/icn_search_big.svg';
import { ReactComponent as IconArrowDown } from 'assets/icons/icn_arrow_down.svg';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { BluCSSTransition } from 'ui/basic';

// UTILS
import { capitalise } from 'utils/textTools';
// import { useAutoReset } from 'utils/hooks';

// STORE

// CONFIG & DATA
// const Config = {};

/**
 * DropDownSearchable
 *  - animated flyout
 *  - filter options
 *    - (by label & value)
 *    - highlight options
 *  - show selected option at top.
 *  - set active via props: activeIndex & activeValue (has precedence)
 *  - unfocus
 *    - outside click
 *    - esc
 * @param {*} props
 * @returns
 */

// COMPONENT: DropDownSearchable
const DropDownSearchable = (props) => {
  // PROPS
  const {
    size = '', // M (default), L, Responsive
    placeholder = '',
    filterPlaceholder = '',
    options = [],
    activeValue,
    activeIndex,
    errorMessage,
    hint,
    onChange = () => {},
    onClick = () => {},
  } = props;

  // SPECIAL HOOKS
  const [ state, dispatchLocal ] = useReducer(reducer, initialState);
  const [ callOnChange, setCallOnChange ] = useState(false);

  // PROPS & CHANGES & OUTPUTS
  // set options
  useEffect(() => {
    dispatchLocal({ type: 'setOptions', payload: options });
  }, [ options ]);

  // set activeOption by value
  useEffect(() => {
    if (activeValue === undefined) return;
    dispatchLocal({ type: 'setActiveOptionByValue', payload: activeValue });
  }, [ activeValue ]);

  // set activeOption by index
  useEffect(() => {
    if (activeIndex === undefined) return;
    dispatchLocal({ type: 'setActiveOptionByIndex', payload: activeIndex });
  }, [ activeIndex ]);

  // call onChange when activeOption changes
  useEffect(() => {
    if (callOnChange) {
      onChange(state.activeOption);
      setCallOnChange(false);
    }
  }, [ state.activeOption, onChange, callOnChange ]);


  // FOCUS
  // const [ recentlyFocused, setRecentlyFocused ] = useAutoReset(false);

  const componentRef = useRef(null); // for closing flyout: focus component
  const inputRef = useRef(null); // for opening flyout: focus filter
  // Effect: opening flyout: auto focus filter
  useEffect(() => {
    if (state.showFlyout) {
      inputRef.current.focus();
    }
  }, [ state.showFlyout ]);

  const closeFlyout = () => {
    dispatchLocal({ type: 'hideFlyout' });
    setTimeout(() => {
      dispatchLocal({ type: 'setFilterString', payload: '' });
      dispatchLocal({ type: 'setFilteredOptionsIndex', payload: 0 });
      componentRef.current.focus();
    }, 200);
  };

  // Effect: opening flyout; set event listeners on body to close flyout
  useEffect(() => {
    const bodyCloseFlyout = () => {
      // console.log('body close flyout');
      closeFlyout(); // call close flyout after header click
    };
    if (state.showFlyout) {
      setTimeout(() => {
        // document.body.addEventListener('click', bodyCloseFlyout);
      }, 5);
    } else {
      document.body.removeEventListener('click', bodyCloseFlyout);
    }

    return () => {
      document.body.removeEventListener('click', bodyCloseFlyout);
    };
  }, [ state.showFlyout ]);

  // const handleFocus = () => {
  //   if (!state.showFlyout) {
  //     dispatchLocal({ type: 'showFlyout' });
  //     setRecentlyFocused(true);
  //   }
  // }
  // const handleBlur = () => {
  //   if (!recentlyFocused && state.showFlyout) {
  //     console.log('blur');
  //     dispatchLocal({ type: 'hideFlyout' });
  //   }
  // };


  // METHODS
  const selectOption = (option) => {
    dispatchLocal({ type: 'setActiveOptionByValue', payload: option.value });
    setCallOnChange(true);

    setTimeout(() => {
      closeFlyout();
    }, 150);
  };


  // HANDLES
  // const handleHeaderClick = (event) => {
  //   console.log('header click');
  //   dispatchLocal({ type: 'showFlyout' });
  // };

  const handleFlyoutToggle = () => {
    dispatchLocal({ type: 'toggleFlyout' });
  };

  const handleOptionClick = (event, option) => {
    selectOption(option);
  };
  const handleFilterChange = (event) => {
    const { value } = event.target;
    dispatchLocal({ type: 'setFilterString', payload: value });
  };

  // KEYBOARD CONTROLS
  const handleKeyUp = (event) => {
    const { key } = event;

    switch (key) {
      case 'Escape': {
        if (state.showFlyout) {
          closeFlyout();
        }
        break;
      }
      case 'ArrowUp': {
        dispatchLocal({ type: 'decFilteredOptionsIndex' });
        break;
      }
      case 'ArrowDown': {
        dispatchLocal({ type: 'incFilteredOptionsIndex' });
        break;
      }
      case ' ': {
        if (!state.showFlyout) {
          dispatchLocal({ type: 'showFlyout' });
        }
        break;
      }
      case 'Enter': {
        if (!state.showFlyout) {
          dispatchLocal({ type: 'showFlyout' });
        } else {
          dispatchLocal({ type: 'setActiveOptionByFilteredOptionsIndex' });
          setCallOnChange(true);
          closeFlyout();
        }
        break;
      }
      default: // return
    }
    event.stopPropagation();
  };

  // useEffect(() => {
  //   console.log(state);
  // }, [state]);

  // RENDER: DropDownSearchable
  return (
    <div className={classNames(styles.dropDownSearchable)}>
      <div
        ref={componentRef}
        role='presentation'
        className={classNames(styles.dropDown, {
          [styles.showFlyout]: state.showFlyout,
          [styles[`size${capitalise(size)}`]]: size,
        })}
        tabIndex={0}
        onKeyUp={handleKeyUp}
        // onFocus={handleFocus}
        onBlur={(event) => {
          // close flyout if
          // - flyout is open AND
          // - onBlur event was wired by clicking outside the DropDownSearchable component
          if (state.showFlyout && (!event.relatedTarget
          || (event.relatedTarget && event.relatedTarget !== componentRef.current
            && (event.relatedTarget.contains(componentRef.current) || event.relatedTarget.className.includes('DropDownSearchable'))))
          ) {
            handleFlyoutToggle();
          }
        }}
        onClick={onClick}
      >

        { /* HEADER */ }
        <div
          className={styles.header}
          onClick={handleFlyoutToggle}
          role='presentation'
        // onClick={!state.showFlyout ? handleHeaderClick : undefined}
        >

          { /* show label */ }
          { state.activeOption && state.activeOption.label }

          { /* show placeholder */ }
          { !state.activeOption && placeholder }

          <div className={styles.icon}>
            <IconArrowDown />
          </div>
        </div>


        { /* FLYOUT */ }
        <BluCSSTransition in={state.showFlyout} classNames={{ ...styles }}>
          <div className={styles.flyout}>

            { /* FILTER */ }
            <div className={styles.filterInput}>
              <div className={styles.icon}>
                <IconSearchBig />
              </div>
              <input
                ref={inputRef}
                placeholder={filterPlaceholder}
                value={state.filterString}
                onChange={handleFilterChange}
              />
            </div>

            <div className={styles.divider} />


            { /* OPTIONS */ }
            <div className={styles.options}>

              { /* show options: filtered except active option */ }
              { state.filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  // tabIndex={0}
                  role='presentation'
                  className={classNames(styles.option, {
                    [styles.active]: state.activeOption && state.activeOption.value === option.value,
                    [styles.highlight]: state.filteredOptionsIndex === index,
                  })}
                  onClick={(event) => { handleOptionClick(event, option); }}
                >
                  { option.label }
                </div>
              )) }
            </div>
          </div>
        </BluCSSTransition>
      </div>

      { /* HINT */ }
      { (errorMessage || hint) && (
        <div className={classNames(styles.hint, { [styles.errorMessage]: errorMessage })}>
          { errorMessage || hint }
        </div>
      ) }
    </div>
  );
};

export default DropDownSearchable;
