import React, { useEffect, useState } from 'react';
import styles from './CompetenciesResult.module.scss';

import { useDispatch, useSelector } from 'react-redux';

import { getResultInfoDescription, getResultInfoTitle, getTranslationIds } from './translationIds';

// UTILS
import { useTranslate } from 'utils/translator';

import { normalizeText } from 'utils/normalizeText';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';

import { getAssessment } from 'store/actions';

import CompetenciesResultSkeleton from './CompetenciesResultSkeleton';
import { SteppedScaleResultItem } from '../SteppedScaleResultItem';
import { DownloadResultButton } from '../DownloadResultButton';

import { InfoTitle, Modal, ShowResultsButton } from 'ui/basic';


const CompetenciesResult = (props) => {
  const {
    assessmentType = ASSESSMENT_TYPES.KEY_COMPETENCIES,
    results,
    noDataText,
    footerText,
    showExportIcon,
    showInfoTitle = true,
    exportView,
    onExport = () => {},
    userId,
    managerControlled,
    sharedWithUser,
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();

  const [ infoPageIsVisible, setInfoPageIsVisible ] = useState(false);
  const [ infoPageTitle, setInfoPageTitle ] = useState();
  const [ infoPageDescription, setInfoPageDescription ] = useState();

  const translationIds = getTranslationIds(assessmentType);

  const currentLanguage = useSelector((state) => state.localisation?.currentLanguage);

  const resultSchemaLanguage = useSelector((state) => state.assessments.table[assessmentType]?.resultSchemaLanguage);
  const resultSchema = useSelector((state) => state.assessments.table[assessmentType]?.resultSchema);

  const [ data, setData ] = useState([]);
  useEffect(() => {
    if (!resultSchema || !results) {
      return;
    }

    const mappedData = resultSchema.map((resultSchemaItem) => {
      const dimensionResultItem = results.find((resultItem) => resultItem.id === resultSchemaItem.id);

      return {
        id: resultSchemaItem.id,
        name: resultSchemaItem.name,
        result: dimensionResultItem && dimensionResultItem.result,
        referenceValue: dimensionResultItem && dimensionResultItem.range && dimensionResultItem.range[0],
        subDimensions: resultSchemaItem.subDimensions.map((subDimension) => {
          const subDimensionResultItem = results.find((resultItem) => resultItem.id === subDimension.id);
          return {
            id: subDimension.id,
            name: subDimension.name,
            result: subDimensionResultItem && subDimensionResultItem.result,
            referenceValue: subDimensionResultItem && subDimensionResultItem.range && subDimensionResultItem.range[0],
          };
        }),
      };
    });

    setData(mappedData);
  }, [ resultSchema, results ]);

  useEffect(() => {
    if (!resultSchema || resultSchemaLanguage !== currentLanguage) {
      dispatch(getAssessment(assessmentType));
    }
  }, [
    dispatch,
    assessmentType,
    resultSchema,
    currentLanguage,
    resultSchemaLanguage,
  ]);

  if (!data || data.length === 0 || !resultSchema) {
    return (
      <div className={styles.competenciesResult}>
        <CompetenciesResultSkeleton />
      </div>
    );
  }

  return (
    <div className={styles.competenciesResult}>
      <div id='container-id' className={styles.container}>
        <div className={styles.mainHeader}>
          { (exportView || !showInfoTitle) ? (
            <span className='bluTypeLabelL'>
              { translate(translationIds.resultTitle) || normalizeText(assessmentType) }
            </span>
          ) : (
            <InfoTitle
              title={translate(translationIds.resultTitle) || normalizeText(assessmentType)}
              onClick={() => {
                setInfoPageTitle(translate(translationIds.resultInfoTitle));
                setInfoPageDescription(translate(translationIds.resultInfoDescription));
                setInfoPageIsVisible(true);
              }}
            />
          ) }

          { (!exportView && showExportIcon) && (
            <DownloadResultButton onExport={onExport} />
          ) }
        </div>

        { noDataText && <div className={styles.noDataText}>{ noDataText }</div> }

        { !noDataText && (
          <>
            { managerControlled && (
              <ShowResultsButton
                userId={userId}
                assessmentId={assessmentType}
                sharedWithUser={sharedWithUser}
              />
            ) }

            <div className={styles.rows}>
              { data.map((dimension, index) => (
                <SteppedScaleResultItem
                  key={dimension.id}
                  data={dimension}
                  isExpandable={!exportView}
                  onInfoClick={(facetIndex) => {
                    setInfoPageTitle(translate(getResultInfoTitle(assessmentType, index + 1, facetIndex)));
                    setInfoPageDescription(
                      translate(getResultInfoDescription(assessmentType, index + 1, facetIndex)),
                    );
                    setInfoPageIsVisible(true);
                  }}
                />
              )) }
            </div>

            { footerText && <div className={styles.footerText}>{ footerText }</div> }
          </>
        ) }

        { infoPageIsVisible && (
          <Modal
            header={infoPageTitle}
            secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
            onConfirm={() => setInfoPageIsVisible(false)}
            onClose={() => setInfoPageIsVisible(false)}
          >
            { infoPageDescription }
          </Modal>
        ) }
      </div>
    </div>
  );
};

export default CompetenciesResult;
