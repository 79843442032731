import React, { useEffect, useState } from 'react';
import styles from './SkillsResult.module.scss';

import { useDispatch, useSelector } from 'react-redux';

// UTILS
import { useTranslate } from 'utils/translator';
import { getAssessment } from 'store/actions';
import {
  Button,
  InfoTitle,
  Modal,
  ShowResultsButton,
} from 'ui/basic';
import { SteppedScaleResultItem } from '../SteppedScaleResultItem';
import SkillsResultSkeleton from './SkillsResultSkeleton';
import { CustomReport } from '../CustomReport';
import { DownloadResultButton } from '../DownloadResultButton';
import { DownloadFileButton } from '../DownloadFileButton';

// STORE
import { selectAssessmentPdfDownloadHandles } from 'store/selectors/assessment';


const SkillsResult = (props) => {
  const {
    assessmentType,
    assessmentData,
    results,
    noDataText,
    footerText,
    showExportIcon,
    showInfoTitle = true,
    exportView,
    onExport = () => {},
    userId,
    managerControlled,
    sharedWithUser,
    historyIndex,
  } = props;

  const dispatch = useDispatch();
  const translate = useTranslate();

  const [ infoPageIsVisible, setInfoPageIsVisible ] = useState(false);
  const [ infoPageTitle, setInfoPageTitle ] = useState();
  const [ infoPageDescription, setInfoPageDescription ] = useState();
  const [ showReport, setShowReport ] = useState(false);

  let assessment = useSelector((state) => state.assessments.table[assessmentType]);
  if (assessmentData) {
    assessment = assessmentData;
  }
  const downloadHandles = useSelector((state) => selectAssessmentPdfDownloadHandles(
    state,
    { ...props, assessmentData: assessment },
  ));

  const [ data, setData ] = useState([]);
  useEffect(() => {
    if (!assessment || !assessment.resultSchema || !results) {
      return;
    }

    const mappedData = assessment.resultSchema
    .filter((resultSchemaItem) => resultSchemaItem.type !== 'string')
    .map((resultSchemaItem) => {
      const mapDimension = (dimension, parentDimension) => {
        const dimensionResultItem = results.find(({ id }) => id === dimension.id);
        return {
          ...dimension,
          parentDimension,
          subDimensions: dimension.subDimensions?.map((el) => mapDimension(el, dimension.id)),
          result: dimensionResultItem?.result,
          referenceValue: dimensionResultItem?.range?.[0],
        };
      };
      return mapDimension(resultSchemaItem);
    });

    setData(mappedData);
  }, [ assessment, results ]);

  useEffect(() => {
    if (!assessment?.resultSchema) {
      dispatch(getAssessment(assessmentType, userId));
    }
  }, [ dispatch, assessmentType, assessment, userId ]);


  if (!data || data.length === 0 || !assessment.resultSchema) {
    return (
      <div className={styles.skillsResult}>
        <SkillsResultSkeleton />
      </div>
    );
  }

  return (
    <div className={styles.skillsResult}>
      <div id='container-id' className={styles.container}>
        <div className={styles.mainHeader}>
          { (exportView || !showInfoTitle) ? (
            <strong className='bluTypeLabel'>
              { assessment.title }
            </strong>
          ) : (
            <InfoTitle
              title={assessment.title}
              className={styles.infoTitle}
              onClick={() => {
                setInfoPageTitle(assessment.title);
                setInfoPageDescription(translate(assessment.description));
                setInfoPageIsVisible(true);
              }}
            />
          ) }

          { (!exportView && showExportIcon && !noDataText) && (
            <DownloadResultButton
              onExport={onExport}
              downloadHandles={downloadHandles}
            />
          ) }
        </div>

        { noDataText && (
          <div className={styles.noDataText}>
            { noDataText }
          </div>
        ) }

        { !noDataText && (
          <>
            { managerControlled && (
              <div className={styles.controlled}>
                <ShowResultsButton
                  userId={userId}
                  assessmentId={assessmentType}
                  sharedWithUser={sharedWithUser}
                />
              </div>
            ) }

            <div className={styles.rows}>
              { data.map((dimension) => (
                <SteppedScaleResultItem
                  key={dimension.id}
                  data={dimension}
                  segmentsCount={assessment && assessment.renderReferenceMax}
                  isExpandable={!exportView}
                  onInfoClick={(subDimensionIndex, subDimension) => {
                    setInfoPageTitle(subDimension && subDimension.name);
                    setInfoPageDescription(subDimension && translate(subDimension.description || subDimension.name));
                    setInfoPageIsVisible(true);
                  }}
                />
              )) }
            </div>

            { !exportView && assessment.customReport && (
              <div className={styles.buttons}>
                <DownloadFileButton {...downloadHandles} />

                <div>
                  <Button
                    size='S'
                    looks='secondary'
                    onClick={() => setShowReport(true)}
                  >
                    { translate('9levels_results_show_report_btn') }
                  </Button>
                </div>
              </div>
            ) }

            { footerText && (
              <div className={styles.footerText}>
                { footerText }
              </div>
            ) }
          </>
        ) }

        { infoPageIsVisible && (
          <Modal
            header={infoPageTitle}
            secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
            onConfirm={() => setInfoPageIsVisible(false)}
            onClose={() => setInfoPageIsVisible(false)}
          >
            { infoPageDescription }
          </Modal>
        ) }

        { showReport && (
          <CustomReport
            historyIndex={historyIndex}
            userId={userId}
            assessment={assessment}
            assessmentResults={data}
            onClose={() => setShowReport(false)}
            downloadHandles={downloadHandles}
          />
        ) }
      </div>
    </div>
  );
};

export default SkillsResult;
