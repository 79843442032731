import React, { memo, useState, useEffect } from 'react';

// UTILS
import { useTranslate } from 'utils/translator';
import {
  InfoTitle, Modal, LineDiagram, Callout, ShowResultsButton,
} from 'ui/basic';
import { initSubmitContentFeedback, submitContentFeedback } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useBreakpoint } from 'utils/hooks';
import { setDecimalSeparator } from 'utils/userpreference';
import PotentialResultSkeleton from './PotentialResultSkeleton';
import PotentialResultItem from './PotentialResultItem';
import styles from './PotentialResult.module.scss';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { DownloadResultButton } from '../DownloadResultButton';


const LOW = [ 1, 3.9 ];
const MEDIUM = [ 4, 6.9 ];


const PotentialResult = memo((props) => {
  const {
    results,
    footerText,
    noDataText,
    showCallout,
    showDimensionReport = true,
    requestFeedback = true,
    showExportIcon,
    showInfoTitle = true,
    exportView,
    onExport = () => {},
    userId,
    managerControlled,
    sharedWithUser,
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const bp = useBreakpoint();

  const [ currentDimensionId, setCurrentDimensionId ] = useState();

  const [ showModal, setShowModal ] = useState();
  const [ modalHeader, setModalHeader ] = useState();
  const [ modalSubHeader, setModalSubHeader ] = useState();
  const [ modalContent, setModalContent ] = useState();
  const [ showModalFeedback, setShowModalFeedBack ] = useState();
  const [ modalData, setModalData ] = useState();

  const feedbackSubmittedSuccessfully = useSelector((state) => state.survey?.success);

  const getSubHeader = (dimensionName, dimensionValue) => {
    if (dimensionValue >= LOW[0] && dimensionValue <= LOW[1]) {
      return translate(
        'potential_dimension_low__short_descr',
        [ '{{dimension}}', dimensionName ],
      );
    }

    if (dimensionValue >= MEDIUM[0] && dimensionValue <= MEDIUM[1]) {
      return translate(
        'potential_dimension_medium__short_descr',
        [ '{{dimension}}', dimensionName ],
      );
    }

    return translate(
      'potential_dimension_high__short_descr',
      [ '{{dimension}}', dimensionName ],
    );
  };

  const getCalloutContent = (dimensionId, score) => {
    if (score >= LOW[0] && score <= LOW[1]) {
      return translate(`employee_result_low__${dimensionId}`);
    }

    if (score >= MEDIUM[0] && score <= MEDIUM[1]) {
      return translate(`employee_result_medium__${dimensionId}`);
    }

    return translate(`employee_result_high__${dimensionId}`);
  };

  const getPersonalRecommendation = (dimensionId, score) => {
    if (score >= LOW[0] && score <= LOW[1]) {
      return translate(`${dimensionId}_low_result_recommendation`);
    }

    if (score >= MEDIUM[0] && score <= MEDIUM[1]) {
      return translate(`${dimensionId}_medium_result_recommendation`);
    }

    return translate(`${dimensionId}_high_result_recommendation`);
  };

  const renderModalContent = (result, resultIndex) => (
    <div className={styles.infoModalResult}>
      <div className={styles.reportDiagram}>
        <div className={styles.header}>
          <span>{ translate('big5_report_facet_result') }</span>
          <span>{ setDecimalSeparator(result.result) }</span>
        </div>

        <div className={styles.diagram}>
          <LineDiagram
            score={result.result}
          />
        </div>

        <div className={styles.footer}>
          <span>{ translate('big5_report_resut_hint1') }</span>
          <span>{ translate('big5_report_resut_hint2') }</span>
        </div>
      </div>

      <div className={styles.copy}>
        { translate(`potential_results_details_description_dim${resultIndex + 1}`) }
      </div>

      { showCallout && (
        <div className={styles.callout}>
          <Callout>
            { getCalloutContent(result.id, result.result) }

            { /* personal recommendation text should be shown only
                              for potential results on my profile page */ }
            { window.location.href.includes('my-profile') && (
              <div className={styles.copyStrong}>
                { getPersonalRecommendation(result.id, result.result) }
              </div>
            ) }
          </Callout>
        </div>
      ) }
    </div>
  );

  // Rerender modal content on resize
  useEffect(() => {
    if (!modalData?.result) {
      return;
    }

    setModalContent(renderModalContent(modalData.result, modalData.index));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bp.isS, bp.isXs, modalData?.index ]);

  if (!results) {
    return (
      <div className={styles.potentialResult}>
        <PotentialResultSkeleton />
      </div>
    );
  }

  return (
    <div className={styles.potentialResult}>
      <div id='container-id' className={styles.container}>
        <div className={styles.mainHeader}>
          { (exportView || !showInfoTitle) ? (
            <span className='bluTypeLabelL'>
              { translate('potential_results_title') }
            </span>
          ) : (
            <InfoTitle
              title={translate('potential_results_title')}
              onClick={() => {
                setModalHeader(translate('potential_results_details_title'));
                setModalSubHeader(null);
                setModalContent(translate('potential_results_details_description'));
                setShowModalFeedBack(false);
                setShowModal(true);
              }}
            />
          ) }

          { (!exportView && showExportIcon) && (
            <DownloadResultButton onExport={onExport} />
          ) }
        </div>

        { noDataText && <div className={styles.noDataText}>{ noDataText }</div> }

        { !noDataText && (
          <>
            { managerControlled && (
              <ShowResultsButton
                userId={userId}
                assessmentId={ASSESSMENT_TYPES.POTENTIAL}
                sharedWithUser={sharedWithUser}
              />
            ) }

            <div className={styles.rows}>
              { results.map((result, index) => (
                <PotentialResultItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${result.name}-${index}`}
                  index={index}
                  label={result.name}
                  result={result.result}
                  range={result.range}
                  exportView={exportView}
                  onInfoClick={() => {
                    if (!showDimensionReport) {
                      setModalHeader(translate(`potential_results_details_title_dim${index + 1}`));
                      setModalContent(translate(`potential_results_details_description_dim${index + 1}`));

                      setShowModal(true);
                      return;
                    }

                    setCurrentDimensionId(result.id);

                    setModalHeader(translate(`potential_results_details_title_dim${index + 1}`));
                    setModalSubHeader(getSubHeader(result.name, result.result));
                    setShowModalFeedBack(true);
                    setModalData({ result, index });

                    setShowModal(true);
                  }}
                />
              )) }
            </div>

            { footerText && (
              <div className={styles.footerText}>
                { footerText }
              </div>
            ) }
          </>
        ) }

        { showModal && (
          <Modal
            header={modalHeader}
            subHeader={modalSubHeader}
            secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
            feedbackSubmitted={feedbackSubmittedSuccessfully}
            onFeedbackSubmit={(requestFeedback && showModalFeedback)
              ? ((feedback) => {
                dispatch(submitContentFeedback({
                  contentId: currentDimensionId,
                  contentType: 'recommendation',
                  helpful: feedback === 'Yes',
                }));
              })
              : null}
            onClose={() => {
              dispatch(initSubmitContentFeedback());
              setShowModal(false);
              setModalData();
            }}
            onConfirm={() => {
              dispatch(initSubmitContentFeedback());
              setShowModal(false);
              setModalData();
            }}
          >
            { modalContent }
          </Modal>
        ) }
      </div>
    </div>
  );
});

export default PotentialResult;
