import * as fromDebugActionTypes from 'store/actionTypes/debug';

const initialState = {
  showTranslationKeys: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case fromDebugActionTypes.DEBUG_SET_SHOW_TRANSLATION_KEYS: {
      const showTranslationKeys = action.payload ? true : false;
      return {
        ...state,
        showTranslationKeys,
      };
    }
    default:
      return state;
  }
};
