import * as fromActionTypes from 'features/+admin/store/actionTypes/instanceOverview';

// GET INDUSTRIES
export const getIndustries = () => {
  return {
    type: fromActionTypes.GET_INDUSTRIES,
    payload: {}
  };
};

export const getIndustriesFulfilled = ({industries}) => {
  return {
    type: fromActionTypes.GET_INDUSTRIES_FULFILLED,
    payload: { industries }
  };
};

export const getIndustriesRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_INDUSTRIES_REJECTED,
    payload: { error }
  };
};

// GET INSTANCES
export const initGetInstances = () => {
  return {
    type: fromActionTypes.INIT_GET_INSTANCES,
    payload: {}
  };
};

export const getInstances = (requestDto) => {
  return {
    type: fromActionTypes.GET_INSTANCES,
    payload: {requestDto}
  };
};

export const getInstancesFulfilled = ({instances, instanceType, infiniteLoad, totalCount, noDataAtAll}) => {
  return {
    type: fromActionTypes.GET_INSTANCES_FULFILLED,
    payload: {instances, instanceType, infiniteLoad, totalCount, noDataAtAll}
  };
};

export const getInstancesRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_INSTANCES_REJECTED,
    payload: { error }
  };
};

// GET INSTANCE
export const initGetInstance = () => {
  return {
    type: fromActionTypes.INIT_GET_INSTANCE,
    payload: {}
  };
};

export const getInstance = (instanceId) => {
  return {
    type: fromActionTypes.GET_INSTANCE,
    payload: {instanceId}
  };
};

export const getInstanceFulfilled = ({instance}) => {
  return {
    type: fromActionTypes.GET_INSTANCE_FULFILLED,
    payload: {instance}
  };
};

export const getInstanceRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_INSTANCE_REJECTED,
    payload: { error }
  };
};

// GET INSTANCE REGISTRATION DOMAINS
export const initGetInstanceRegistrationDomains = () => {
  return {
    type: fromActionTypes.INIT_GET_INSTANCE_REG_DOMAINS,
    payload: {}
  };
};

export const getInstanceRegistrationDomains = (instanceId) => {
  return {
    type: fromActionTypes.GET_INSTANCE_REG_DOMAINS,
    payload: {instanceId}
  };
};

export const getInstanceRegistrationDomainsFulfilled = ({registrationDomains}) => {
  return {
    type: fromActionTypes.GET_INSTANCE_REG_DOMAINS_FULFILLED,
    payload: {registrationDomains}
  };
};

export const getInstanceRegistrationDomainsRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_INSTANCE_REG_DOMAINS_REJECTED,
    payload: { error }
  };
};

// UPDATE INSTANCE REGISTRATION DOMAINS
export const updateInstanceRegistrationDomains = (instanceId, domains) => {
  return {
    type: fromActionTypes.UPDATE_INSTANCE_REG_DOMAINS,
    payload: {instanceId, domains}
  };
};

export const updateInstanceRegistrationDomainsFulfilled = () => {
  return {
    type: fromActionTypes.UPDATE_INSTANCE_REG_DOMAINS_FULFILLED,
    payload: {}
  };
};

export const updateInstanceRegistrationDomainsRejected = ({ error }) => {
  return {
    type: fromActionTypes.UPDATE_INSTANCE_REG_DOMAINS_REJECTED,
    payload: { error }
  };
};

// GET USER STATISTICS
export const getUserStatistics = () => {
  return {
    type: fromActionTypes.GET_USER_STATISTICS,
    payload: {}
  };
};

export const getUserStatisticsFulfilled = ({statistics}) => {
  return {
    type: fromActionTypes.GET_USER_STATISTICS_FULFILLED,
    payload: {statistics}
  };
};

export const getUserStatisticsRejected = ({ error }) => {
  return {
    type: fromActionTypes.GET_USER_STATISTICS_REJECTED,
    payload: { error }
  };
};

// REBUILD CACHE
export const initRebuildCache = () => {
  return {
    type: fromActionTypes.INIT_REBUILD_CACHE,
    payload: {}
  };
};

export const rebuildCache = (companyId) => {
  return {
    type: fromActionTypes.REBUILD_CACHE,
    payload: {companyId}
  };
};

export const rebuildCacheFulfilled = () => {
  return {
    type: fromActionTypes.REBUILD_CACHE_FULFILLED,
    payload: {}
  };
};

export const rebuildCacheRejected = ({ error }) => {
  return {
    type: fromActionTypes.REBUILD_CACHE_REJECTED,
    payload: { error }
  };
};
