// REACT, STYLE, STORIES & COMPONENT
import React, { useCallback, useEffect, useState } from 'react';
import styles from './QuestionCsat.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { Button } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { breakpoints, useBreakpoint } from 'utils/hooks';
import { isValid } from 'utils/numbers';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
const Config = {
  maxValue: 5,
};

// COMPONENT: QuestionCsat
const QuestionCsat = (props) => {
  // PROPS
  const {
    question,
    selectedValue,
    onAnswer = () => {},
  } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();
  const bp = useBreakpoint();

  const { CursorUp, CursorDown } = IconsSvg;

  const [ hoveredStarIndex, setHoveredStarIndex ] = useState();
  const [ value, setValue ] = useState(selectedValue);

  const hintTranslation = translate(
    'assessment_selection_items__hint',
    [ '{{endNumber}}', Config.maxValue ],
  );

  const renderHint = () => {
    const hint = [];
    const split = hintTranslation.split('. ');

    if (split && split[1]) { // split[1] - getting the second sentence
      split[1]
      .split('{{key arrow up / key arrow down}}')
      .forEach((s, index) => {
        if (index === 0) {
          hint.push(
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.line2} key={index}>
              <span>{ s }</span>
              <CursorUp />
              <CursorDown />
            </div>,
          );
        } else {
          // eslint-disable-next-line react/no-array-index-key
          hint.push(<span key={index}>{ s }</span>);
        }
      });
    } else {
      hint.push(split[0]);
    }

    return hint;
  };

  const handleSubmit = useCallback((valueParam) => {
    onAnswer(valueParam);
  }, [ onAnswer ]);

  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    if (event.key === 'Enter' && isValid(value)) {
      handleSubmit(value);
      return;
    }

    const selectedKey = Number(event.key);
    if (selectedKey && selectedKey <= Config.maxValue) {
      setValue(selectedKey);
    }
  }, [ value, handleSubmit ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [ handleKeyUp ]);

  if (!question) {
    return null;
  }

  // RENDER: QuestionCsat
  return (
    <div className={classNames(styles.questionCsat)}>
      <div className={styles.gridContent}>
        { question.question && (
          <div className='bluTypeS'>{ question.question }</div>
        ) }

        <div className={styles.stars}>
          { new Array(5).fill('_').map((unused, index) => (
            <div
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              role='presentation'
              className={classNames(
                styles.star,
                { [styles.selected]: index + 1 <= value },
                { [styles.hovered]: index + 1 <= hoveredStarIndex },
              )}
              onClick={() => setValue(index + 1)}
              onMouseEnter={() => {
                setHoveredStarIndex(index + 1);
              }}
              onMouseLeave={() => {
                setHoveredStarIndex(undefined);
              }}
            />
          )) }
        </div>

        { !question.answerRequired && (
          <div
            className={styles.greyValue}
            role='presentation'
            onClick={() => handleSubmit(null)}
          >
            { translate('question_can_not_answer_label') }
          </div>
        ) }

        <Button
          size='M'
          disabled={!value}
          onClick={() => handleSubmit(value)}
        >
          { translate('wp_ass_selection_button_next') }
        </Button>

        { /* HINT */ }
        { bp.bpWidth > breakpoints.M.bpWidth && (
          <div className={styles.hint}>
            <div className={styles.line2}>
              { `${hintTranslation.split('. ')[0]}.` }
            </div>

            <div className={styles.line2}>{ renderHint() }</div>
          </div>
        ) }
      </div>
    </div>
  );
};

export default QuestionCsat;
