import React, { useState } from 'react';
import styles from './MindfulnessJourneyResult.module.scss';

import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import {
  initSubmitContentFeedback, submitContentFeedback,
} from 'store/actions';

import {
  Card,
  CollapsibleNext,
  InfoTitle,
  SpiderDiagramNext,
  Modal, BluCSSTransition,
} from 'ui/basic';
import { DownloadResultButton } from '../DownloadResultButton';

// eslint-disable-next-line import/no-cycle
import { Big5ResultSkeleton } from 'ui/molecules';

// UTILS
import { useTranslate } from 'utils/translator';
import { joinNumbers } from 'utils/textTools';
import { numberWithForcedDecimals } from 'utils/numberFormatting';


const HIGH_VALUE_DEFINITION = 7;


const MindfulnessJourneyResult = (props) => {
  const {
    type,
    exportView = false,
    dimensions,
    lineValues,
    secondaryLineValues,
    rangeValues,
    showHistory,
    showDimensionInfo,
    score,
    completedAssessments,
    showSpiderDiagramTextLabels,
    showPendingState = false,
    showInfoTitle = false,
    historyIsVisible = false,
    onExport,
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();

  const [ showDimensionReport, setShowDimensionReport ] = useState(false);
  const [ selectedDimensionId, setSelectedDimensionId ] = useState();
  const [ selectedDimensionResult, setSelectedDimensionResult ] = useState();
  const [ infoModalIsVisible, setInfoModalIsVisible ] = useState(false);
  const [ dimensionsHovers, setDimensionsHovers ] = useState([
    // true|false value for each dimensions
    // 0 => true|false depending if hover is set for dimension 0
  ]);

  // SELECTORS
  const feedbackSubmittedSuccessfully = useSelector(
    (state) => state.survey && state.survey.success,
  );

  const setHover = (dimensionIndex, isHover) => {
    const dimensionsHoversNew = new Array(dimensions.length).fill(false);
    dimensionsHoversNew[dimensionIndex] = isHover;

    setDimensionsHovers(dimensionsHoversNew);
  };

  // RENDER
  if (!showPendingState && !lineValues?.length) {
    return <Big5ResultSkeleton />;
  }

  const renderExtraDetail = (dimension, diffPercent) => {
    const renderItem = (title, value) => (
      <div className={styles.item}>
        <div className={styles.itemTitle}>
          { title }
        </div>
        <div className={styles.itemValue}>
          { value }
        </div>
      </div>
    );

    return (
      <div className={styles.collapsibleDetail}>
        { renderItem(
          translate('mind_journey_result__range_lbl'),
          `${numberWithForcedDecimals(dimension.lower_quartile)}-${numberWithForcedDecimals(dimension.upper_quartile)}`,
        ) }
        { renderItem(
          translate('mind_journey_result__median_lbl'),
          numberWithForcedDecimals(dimension.median, { decimals: 2 }),
        ) }
        { !showHistory
          ? <div />
          : renderItem(
            translate('mind_journey_result__change_lbl'),
            `${numberWithForcedDecimals(diffPercent, { decimals: 0, addPlus: true })}%`,
          ) }
      </div>
    );
  };

  const renderInfoModalContent = () => {
    if (type === 'org') {
      return translate(`${selectedDimensionId}_definition`);
    }

    return selectedDimensionResult >= HIGH_VALUE_DEFINITION
      ? translate(`by__${selectedDimensionId}_report__high`)
      : translate(`by__${selectedDimensionId}_report__low`);
  };

  const resultTitle = type === 'org'
    ? translate('mind_journey_org_result_title')
    : translate('mind_journey_ind_result_title');

  return (
    <div className={styles.mindfulnessJourneyResult}>
      { showPendingState ? (
        /* IF NO RESULT */
        <Card hasNoPaddings>
          <div className={styles.blockWithPaddings}>
            { showInfoTitle
              ? (
                <InfoTitle
                  title={resultTitle}
                  className={styles.infoTitle}
                  onClick={() => setInfoModalIsVisible(true)}
                />
              )
              : <span>{ resultTitle }</span> }
            <div className={classNames('bluTypeCopy', 'marginTopS')}>
              { translate('garmin_org_result_pending_copy') }
            </div>
          </div>
        </Card>
      ) : (
        /* CARD */
        <Card hasBreakout='S'>
          { /* TITLE */ }
          <div className={styles.title}>
            { exportView && (
              <span className='bluTypeLabelL'>
                { translate('mind_journey_ind_result_title') }
              </span>
            ) }

            { !exportView && (
              showInfoTitle
                ? (
                  <InfoTitle
                    title={resultTitle}
                    onClick={() => setInfoModalIsVisible(true)}
                  />
                )
                : <span className='bluTypeLabelL'>{ resultTitle }</span>
            ) }

            { (onExport && !exportView) && (
              <DownloadResultButton onExport={onExport} />
            ) }
          </div>

          { /* SPIDERDIAGRAMNEXT */ }
          <SpiderDiagramNext
            dimensions={dimensions}
            dimensionsHovers={dimensionsHovers}
            lineValues={lineValues}
            lineValuesSecondary={historyIsVisible ? secondaryLineValues?.results : []}
            rangeValues={rangeValues}
            onHover={setHover}
            showTextLabels={showSpiderDiagramTextLabels}
          />

          { /* CONTROLS PANEL */ }
          <div className={styles.controlsPanel}>
            { (showHistory && secondaryLineValues) && (
              <BluCSSTransition
                in={historyIsVisible}
                classNames={{ ...styles }}
              >
                <div className={styles.historySection}>
                  { historyIsVisible && (
                    <div className={styles.legend}>
                      <div className={styles.blue}>
                        <div className={styles.rect} />
                        <span>{ translate('mind_journey_score_current') }</span>
                      </div>

                      <div className={styles.gray}>
                        <div className={styles.rect} />
                        <span>{ translate('mind_journey_score_prev_state') }</span>
                      </div>
                    </div>
                  ) }
                </div>
              </BluCSSTransition>
            ) }
          </div>

          { /* SCORE */ }
          <div className={classNames({
            [styles.score]: true,
          })}
          >
            <span>
              { translate('mind_journey_result__score') }
            </span>
            &nbsp;&nbsp;
            <span className={styles.scoreRatio}>
              { `${numberWithForcedDecimals(score, { decimals: 2 })} / 10` }
            </span>
          </div>

          { /* COLLAPSIBLES / DIMENSIONS */ }
          <div className={styles.collapsibles}>
            { dimensions.map((dimension, dimensionIndex) => {
              const lineValue = lineValues[dimensionIndex];
              const secondaryValue = secondaryLineValues?.results
                ? secondaryLineValues.results[dimensionIndex]
                : 0;

              const diffPercent = Math.round((lineValue - secondaryValue) * 10);

              const header = dimension.label;

              const formattedLineValue = numberWithForcedDecimals(lineValue, { decimals: 2 });

              const headerHighlight = rangeValues
                ? joinNumbers(
                  rangeValues[dimensionIndex],
                  '-',
                  (number) => numberWithForcedDecimals(number, { decimals: 0 }),
                ) : formattedLineValue;

              const getHeaderRight = () => {
                if (!secondaryLineValues) {
                  return null;
                }
                const headerRight = `${diffPercent > 0 ? `+${diffPercent}` : `${diffPercent}`}%`;
                // headerRight = rangeValues ? formattedLineValue : '';
                return headerRight;
              };

              return (
                <CollapsibleNext
                  key={dimension.label}
                  isExpandable={false}
                  headerSubtle={dimensionIndex + 1}
                  header={header}
                  headerRight={type !== 'org' && getHeaderRight()}
                  headerHighlight={type !== 'org' && headerHighlight}
                  extraDetail={type === 'org' && renderExtraDetail(dimension, diffPercent)}
                  hasInfoIcon={showDimensionInfo && !exportView}
                  onInfoIconClick={() => {
                    setSelectedDimensionId(dimension.id);
                    setSelectedDimensionResult(lineValues[dimensionIndex]);
                    setShowDimensionReport(true);
                  }}
                  isHovered={dimensionsHovers[dimensionIndex]}
                  onMouseEnter={() => { setHover(dimensionIndex, true); }}
                  onMouseLeave={() => { setHover(dimensionIndex, false); }}
                />
              );
            }) }
          </div>

          { completedAssessments && (
            <div className={styles.basedOn}>
              { translate(
                'mind_journey_result__based_on',
                [ '{{assessments}}', completedAssessments ],
              ) }
            </div>
          ) }
        </Card>
      ) }

      { infoModalIsVisible && (
        <Modal
          header={type === 'org'
            ? translate('mind_journey_org_result_info_title')
            : translate('mind_journey_ind_result_info_title')}
          secondaryButtonTitle={translate('okay_lbl')}
          onClose={() => setInfoModalIsVisible(false)}
        >
          { type === 'org'
            ? translate('mind_journey_org_result_info_description')
            : translate('mind_journey_ind_result_info_description') }
        </Modal>
      ) }

      { showDimensionReport && (
        <Modal
          subHeader={type === 'org' ? null : translate(`${selectedDimensionId}_definition`)}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
          feedbackSubmitted={feedbackSubmittedSuccessfully}
          onFeedbackSubmit={(feedback) => {
            dispatch(submitContentFeedback({
              contentId: selectedDimensionId,
              contentType: 'recommendation',
              helpful: feedback === 'Yes',
            }));
          }}
          onClose={() => {
            dispatch(initSubmitContentFeedback());
            setShowDimensionReport(false);
          }}
        >
          { renderInfoModalContent() }
        </Modal>
      ) }

    </div>
  );
};

export default MindfulnessJourneyResult;
