// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './NextSteps.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: NextSteps
const NextSteps = () => {

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: NextSteps
  return (
    <div className={styles.nextSteps}>
      <span className={classNames('bluTypeS', styles.blue)}>{ translate('peer_360_report_next_steps') }</span>
      <div className='marginTopM'>
        { translate('peer_360_report_next_steps_copy') }
      </div>
    </div>
  );
};

export default NextSteps;
