// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './IstRegistration.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { DropDown, InputNumber } from 'ui/basic';
import { AssessmentPageWithFooter } from '../AssessmentPageWithFooter';

// UTILS
import * as api from 'api';
import { useTranslate } from 'utils/translator/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA

// COMPONENT: IstRegistration
const IstRegistration = (props) => {
  // PROPS
  const {
    assessmentDetails = {},
    skipRegistration = false,
    extras = {},
    onClickNext = () => {},
  } = props;

  const { loadingDuring, setLoadingDuring } = extras;
  const { registrationSchema = {} } = assessmentDetails;

  // SPECIAL HOOKS
  const translate = useTranslate();

  const [ gender, setGender ] = useState();
  const [ age, setAge ] = useState();
  const [ ageIsValid, setAgeIsValid ] = useState();
  const [ weight, setWeight ] = useState();
  const [ weightIsValid, setWeightIsValid ] = useState();

  const [ height, setHeight ] = useState();
  const [ heightIsValid, setHeightIsValid ] = useState();

  const [ abdGirth, setAbdGirth ] = useState();
  const [ abdGirthIsValid, setAbdGirthIsValid ] = useState();
  const [ activity, setActivity ] = useState();

  const valuesAreValid = age && ageIsValid
    && gender
    && weight && weightIsValid
    && height && heightIsValid
    && (!abdGirth || (abdGirth && abdGirthIsValid));

  const [ errorMessage, setErrorMessage ] = useState('');

  const handleRegister = () => {
    if (!valuesAreValid) {
      return;
    }

    setLoadingDuring(true);

    const payload = {
      gender: gender.value,
      age: Number(age),
      weight: Number(weight),
      height: Number(height),
      abdominal_girth: abdGirth ? Number(abdGirth) : undefined,
      activity: activity?.value,
    };

    api.post('/core/assessments/ist/register', payload)
    .then(({ ok, data = {} }) => {
      if (ok) {
        onClickNext();
        return;
      }
      setErrorMessage(data.error?.errorMessage);
    })
    .catch((error) => {
      setErrorMessage(error?.message);
    })
    .finally(() => {
      setLoadingDuring(false);
    });
  };

  if (skipRegistration) {
    onClickNext();
    return null;
  }

  // RENDER: IstRegistration
  return (
    <AssessmentPageWithFooter
      nextButtonText={translate('continue_lbl')}
      onClickNext={(valuesAreValid && !loadingDuring)
        ? handleRegister
        : undefined}
    >
      <div className={classNames(styles.istRegistration)}>
        <span className='bluTypeXxs'>
          { translate('ist_report_personal_data_title') }
        </span>
        <div className={classNames('bluTypeCopy', 'marginTopXs')}>
          { translate('ist_reg_copy') }
        </div>

        { errorMessage && (
          <div className={styles.errorMessage}>{ errorMessage }</div>
        ) }

        <div className={styles.personalInfo}>
          <div className='bluTypeLabelL'>{ translate('personal_info') }</div>
          <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
            { translate('ist_reg_personal_info_copy') }
          </div>
          <div className={classNames(styles.inputs, 'marginTopS')}>
            <DropDown
              placeholder={translate('rmp_ass_form_gender_placeholder')}
              options={registrationSchema.gender?.options}
              optionLabel='name'
              optionValue='value'
              onChange={(option) => setGender(option)}
            />
            <InputNumber
              label={translate('assessment_registration_age_placeholder')}
              validate={{
                // accept the age value from 12 to 99,
                pattern: /^(1[2-9]|[2-9][0-9])$/,
              }}
              errorMessage={translate('ass_registration_invalid_age_msg')}
              onChange={(value, valueIsValid) => {
                setAge(value);
                setAgeIsValid(valueIsValid);
              }}
            />
          </div>
        </div>

        <div className={styles.physioData}>
          <span className='bluTypeLabelL'>{ translate('physio_data') }</span>
          <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
            { translate('ist_reg_physio_data_copy') }
          </div>

          <div className={classNames(styles.inputs, 'marginTopS')}>
            <InputNumber
              label={`${translate('ist_reg_weight')}*`}
              floatingNumbers
              onChange={(value, valueIsValid) => {
                setWeight(value);
                setWeightIsValid(valueIsValid);
              }}
            />
            <InputNumber
              label={`${translate('ist_reg_height')}*`}
              onChange={(value, valueIsValid) => {
                setHeight(value);
                setHeightIsValid(valueIsValid);
              }}
            />
          </div>

          <div className={classNames(styles.inputs, 'marginTopXs')}>
            <InputNumber
              label={translate('ist_reg_abdominal_girth')}
              onChange={(value, valueIsValid) => {
                setAbdGirth(value);
                setAbdGirthIsValid(valueIsValid);
              }}
            />

            <DropDown
              placeholder={translate('activity')}
              options={registrationSchema.activity?.options}
              optionLabel='name'
              optionValue='value'
              onChange={(option) => setActivity(option)}
            />
          </div>

          <div className={classNames('bluTypeCopy', 'marginTopM')}>
            { translate('rmp_ass_form_hint_required_info') }
          </div>
        </div>
      </div>
    </AssessmentPageWithFooter>
  );
};

export default IstRegistration;
