// REACT, STYLE, STORIES & COMPONENT
import React from 'react';
import styles from './Section2.module.scss';

// ASSETS
// import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';
// import { useHistory } from 'react-router-dom';

// OTHER COMPONENTS
import { Callout } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';

// STORE NEXT
// import { useDispatch, useSelector } from 'react-redux';
// import { selectUserFirstName } from 'features/framework/storeNext/configurationSlice'

// CONFIG & DATA
// const Config = {};

// COMPONENT: Section2
const Section2 = (props) => {
  // SPECIAL HOOKS: translate, routing, breakpoints, ...
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  // RENDER: Section2
  return (
    <div className={classNames(styles.section2)} id='classify-results'>
      <div className={styles.sTitle}>
        {translate('9levels_report_subtitle_classify_results')}
      </div>
      <div className={styles.callout}>
        <Callout trianglePosition={'left'}>
          {translate('9levels_report_callout_classify_results')}
        </Callout>
      </div>
      <div className={styles.description}>
        {translate('9levels_report_copy_classify_results')}
      </div>
    </div>
  );
};

export default Section2;
