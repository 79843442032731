import React, { useState, useEffect } from 'react';

import * as api from 'api';

// UTILS
import { useTranslate } from 'utils/translator';
import { DIMENSION_BLOCKS, getResistanceText, getSubHeaderLong } from 'utils/nineLevels';
import { NINE_LEVELS_ORDER } from 'utils/configuration/const/assessment-types';

// UI
import NineLevelsDiagram from 'ui/molecules/NineLevelsResult/NineLevelsDiagram/NineLevelsDiagram';
import {
  Card,
  ImgCircle,
  InfoTitle,
  Modal,
  TeamDiagram9LevelsNext,
} from 'ui/basic';
import styles from './TeamNineLevelsResult.module.scss';
import { useBreakpoint } from '../../../utils/hooks';


const TeamNineLevelsResult = (props) => {
  // PROPS
  const { profile, referenceProfile, showAverage } = props;

  const translate = useTranslate();
  const bp = useBreakpoint();

  const [ modalHeader, setModalHeader ] = useState();
  const [ modalHeaderPosition, setModalHeaderPosition ] = useState('center');
  const [ modalSubHeader, setModalSubHeader ] = useState();
  const [ modalPreHeader, setModalPreHeader ] = useState();
  const [ modalContent, setModalContent ] = useState();
  const [ modalVisible, setModalVisible ] = useState();
  const [ modalData, setModalData ] = useState();

  // STORE

  // METHODS
  const getDimensionsData = () => {
    if (!profile) {
      return [];
    }

    const nineLevelsDimensions = [];

    profile.results.forEach((nineLevelsResult) => {
      if (nineLevelsResult.id.includes('_res_')) {
        return;
      }

      let range;
      if (referenceProfile && referenceProfile.results) {
        const thisResult = referenceProfile.results
        .find((r) => r.dimensionId === nineLevelsResult.id);
        if (thisResult) {
          range = [ thisResult.minValue, thisResult.maxValue ];
        }
      }

      const dimensionId = nineLevelsResult.id.replace('cyan', 'turquoise');
      const dimensionOrder = NINE_LEVELS_ORDER[dimensionId.split('levels_')[1]];
      nineLevelsDimensions[dimensionOrder] = {
        id: dimensionId,
        label: translate(`${dimensionId}_info_title`) || nineLevelsResult.name,
        color: dimensionId.split('levels_')[1],
        range,
        data: nineLevelsResult.values.map((userValue) => {
          const { user, value } = userValue;

          // get resistance dimension
          const resDimension = profile.results
          .find((resultItem) => resultItem.id === nineLevelsResult.id.split('_').join('_res_'));

          // get resistance dimension value for given user
          let resDimensionUserValue;
          if (resDimension) {
            resDimensionUserValue = resDimension.values
            .find((valueItem) => valueItem.user.id === user.id);
          }

          return {
            id: user.id,
            label: user.name,
            img: !user.isAnonymous ? api.getUserImageUrl(user.id) : undefined,
            leftValue: resDimensionUserValue ? resDimensionUserValue.value : 0,
            rightValue: value,
          };
        }),
      };
    });

    return nineLevelsDimensions;
  };

  const renderModalContent = (item = {}) => (
    <div className={styles.nineLevelsModalContent}>
      <div className={styles.dimensionName}>{ item.dimensionName }</div>

      { /* DIAGRAM */ }
      <div className={styles.diagram}>
        <NineLevelsDiagram
          results={[ item.leftValue, item.rightValue ]}
          color={item.dimensionColor}
        />
        <div className={styles.footer}>
          <span>{ item.leftValue }</span>
          <span>{ item.rightValue }</span>
        </div>
      </div>

      { /* DESCRIPTION */ }
      <div className={styles.description}>
        { translate(`${item.dimensionId}_info_description`) }
      </div>

      { /* BLOCKS */ }
      <div className={styles.blocks}>
        { DIMENSION_BLOCKS.map((block) => (
          <div className={styles.block} key={block.label}>
            <div className={styles.label}>
              { translate(block.label) || block.altLabel }
            </div>
            <div className={styles.description}>
              { translate(block.description.replace('*', item.dimensionId.split('_')[1]))
                        || block.altDescription }
            </div>
          </div>
        )) }

        <div className={styles.block}>
          <div className={styles.label}>
            { translate('9levels_report__level__resistance') }
          </div>
          <div className={styles.description}>
            { translate(getResistanceText(item.leftValue), [
              '{{level}}', translate(`levels_results_label_${item.dimensionId.split('levels_')[1]}`),
              '{{points}}', item.leftValue,
            ]) }
          </div>
        </div>

      </div>
    </div>
  );

  // Rerender modal content on resize
  useEffect(() => {
    if (!modalData) {
      return;
    }

    setModalContent(renderModalContent(modalData));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bp.isS, bp.isXs, modalData ]);


  return (
    <Card>
      <InfoTitle
        title={translate('9levels_results_title')}
        onClick={() => {
          setModalHeader(translate('9levels_results_details_title'));
          setModalHeaderPosition('left');
          setModalContent(translate('9levels_results_details_description'));
          setModalSubHeader(null);
          setModalPreHeader(null);

          setModalVisible(true);
        }}
      />

      <div className={styles.nineLevelsTeamDiagram}>
        <TeamDiagram9LevelsNext
          showAverage={showAverage}
          labelLeft={translate('ninelevels_team_diagram__resistance_lbl')}
          labelLeftAverage={translate('ninelevels_team_diagram__resistance_team_average_lbl')}
          labelRight={translate('ninelevels_team_diagram__level_expression_lbl')}
          labelRightAverage={translate('ninelevels_team_diagram__level_exp_team_average_lbl')}
          dimensions={getDimensionsData()}
          showRanges={!!referenceProfile}
          labelRange={referenceProfile && (`${translate('subnavigation_tabs_roles_item1')}: `)}
          onItemClick={(item) => {
            setModalHeader(item.label);
            setModalHeaderPosition('center');
            setModalSubHeader(getSubHeaderLong(
              {
                dimensionName: item.dimensionName,
                dimensionValue: item.rightValue,
              },
              item.label,
            ));
            setModalPreHeader(
              <ImgCircle
                src={item.img}
                size='M'
                label1={item.label.split(' ')[0]}
                label2={item.label.split(' ')[1]}
              />,
            );


            setModalData(item);

            setModalVisible(true);
          }}
        />

        <div
          className={styles.copyright}
          role='presentation'
          onClick={() => {
            setModalHeader(translate('9levels_ass_info_title_copyright'));
            setModalHeaderPosition('left');
            setModalContent(translate('9levels_ass_info_description_copyright'));

            setModalSubHeader(null);
            setModalPreHeader(null);

            setModalVisible(true);
          }}
        >
          { translate('9levels_ass_copyrightlink') }
        </div>
      </div>

      { modalVisible && (
        <Modal
          header={modalHeader}
          headerPosition={modalHeaderPosition}
          subHeader={modalSubHeader}
          subHeaderPosition='center'
          preHeader={modalPreHeader}
          secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
          onClose={() => {
            setModalVisible(false);
            setModalData();
          }}
          onConfirm={() => {
            setModalVisible(false);
            setModalData();
          }}
        >
          { modalContent }
        </Modal>
      ) }
    </Card>
  );
};

export default TeamNineLevelsResult;
