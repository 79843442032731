import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import styles from './BotAssistant.module.scss';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// eslint-disable-next-line import/no-cycle
import { Button, ResizableTextArea, BluCSSTransition } from 'ui/basic';

// UTILS
import { SecureStorage } from 'utils/storage';
import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import { apiNext } from 'apiNext';

// CONFIG & DATA
const Config = {
  SENDERS: {
    USER: 'user',
    BOT: 'bot',
  },
};


const BotAssistant = () => {
  // SPECIAL HOOKS
  const translate = useTranslate();
  const { isXs, isS } = useBreakpoint();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const secureStorage = useRef(new SecureStorage('chat_', localStorage.getItem('token')));
  const storedChat = useMemo(() => secureStorage.current.load() ?? [], []);
  const [ chat, setChat ] = useState(storedChat);
  const [ message, setMessage ] = useState('');
  const [ asking, setAsking ] = useState(false);
  const [ open, setOpen ] = useState(false);
  const openRef = useRef();
  openRef.current = open;
  const canSendMessage = Boolean(message.trim()) && !asking;

  // Add message to chat history (on both local state and local storage)
  const addMessageToChat = (id, sender, content) => {
    setChat((state) => {
      const newState = JSON.parse(JSON.stringify(state));
      // Check id. If it exists, replace content, otherwise add message
      const targetMessage = newState.find((el) => el.id === id);
      if (targetMessage) {
        // Skip replacement if content is empty (case when ellipsis msg arrives after Api response)
        if (!content) {
          return state;
        }
        targetMessage.content = content;
        targetMessage.read = openRef.current;
      } else {
        newState.push({
          id,
          sender,
          content,
          read: true,
        });
      }

      secureStorage.current.save(newState);
      return newState;
    });
  };

  // Send message to bot and receive answer
  const askBot = useCallback(async (userMessage, timestamp) => {
    try {
      setAsking(true);
      const data = await apiNext.post('/core/assistant/chat', { userMessage });
      addMessageToChat(timestamp, Config.SENDERS.BOT, data.answer);
    } catch (error) {
      console.error(error.message);
      addMessageToChat(timestamp, Config.SENDERS.BOT, 'assistant_chat_error');
    } finally {
      setAsking(false);
    }
  }, []);

  // Register message in chat history and send it to bot
  const sendMessage = () => {
    let timestamp = Date.now();
    addMessageToChat(timestamp, Config.SENDERS.USER, message);
    setTimeout(() => setMessage(''));

    // display bot ellipsis with a certain delay
    timestamp += 1;
    addMessageToChat(timestamp, Config.SENDERS.BOT, null);

    // get bot answer
    askBot(message, timestamp);
  };

  // Retrieve last answer if missing (because the chat was unmounted while waiting for the response)
  useEffect(() => {
    const lastBotMessage = storedChat.findLast((el) => el.sender === Config.SENDERS.BOT);
    const lastUserMessage = storedChat.findLast((el) => el.sender === Config.SENDERS.USER);
    if (lastBotMessage?.content === null) {
      askBot(lastUserMessage.content, lastBotMessage.id);
    }
  }, [ storedChat, askBot ]);

  // Mark all messages as read upon opening the chat
  useEffect(() => {
    if (open && chat.length) {
      setChat((state) => {
        const newState = JSON.parse(JSON.stringify(state)).map((el) => ({ ...el, read: true }));
        secureStorage.current.save(newState);
        return newState;
      });
    }
  }, [ open, chat.length ]);


  // RENDER: chatMessage
  const renderChatMessage = ({ id, sender, content }) => {
    let chatMessage = content;
    if (sender === Config.SENDERS.BOT) {
      chatMessage = translate(chatMessage);
    }

    return (
      <div
        key={id}
        className={classNames(
          styles.chatBubble,
          styles[sender],
          { [styles.typingDots]: !chatMessage },
        )}
      >
        { /* eslint-disable-next-line react/no-array-index-key */ }
        { chatMessage || Array(3).fill().map((_, i) => <div key={i} className={styles.dot} />) }
      </div>
    );
  };

  // RENDER: BotAssistant
  return (
    <div className={classNames(styles.botAssistant, {
      [styles.smallIcon]: !open && (isXs || isS),
      [styles.smallChat]: open && (isXs || isS),
    })}
    >
      { open ? (
        <div className={styles.chatContainer}>
          <div className={styles.chatHeader}>
            <div>
              <IconsSvg.BqLogo />
            </div>
            <div className={styles.title}>
              { translate('assistant_chat_title') }
            </div>
            <div className={styles.close}>
              <Button
                primaryIcon={IconsSvg.CloseBig}
                looks='primary'
                onClick={() => setOpen(false)}
              />
            </div>
          </div>

          <BluCSSTransition
            in={!chat.length}
            classNames={{ ...styles }}
          >
            <div className={styles.callToAction}>
              { translate('assistant_chat_cta') }
            </div>
          </BluCSSTransition>
          <div className={styles.chatContent}>
            { [ ...chat ].reverse().map(renderChatMessage) }
          </div>

          <div className={styles.chatInput}>
            <ResizableTextArea
              placeholder={translate('assistant_chat_placeholder')}
              value={message}
              minRows={1}
              maxRows={5}
              onChange={setMessage}
              onConfirm={() => {
                if (canSendMessage) {
                  sendMessage();
                }
              }}
              invertEnterControl
            />
            <Button
              primaryIcon={IconsSvg.Send}
              looks='primary'
              disabled={!canSendMessage}
              onClick={sendMessage}
            />
          </div>
        </div>
      ) : (
        <div
          role='presentation'
          className={styles.chatIcon}
          onClick={() => setOpen(true)}
        >
          <IconsSvg.Assistant />
          { chat.some(({ read }) => !read) && (
            <div className={styles.notificationDot} />
          ) }
        </div>
      ) }
    </div>
  );
};

export default BotAssistant;
