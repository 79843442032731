// REACT, STYLE, STORIES & COMPONENT
import React, { useState, useEffect, useCallback } from 'react';

// ASSETS
import { ReactComponent as CursorUp } from 'assets/icons/icn_cursor_up.svg';
import { ReactComponent as CursorDown } from 'assets/icons/icn_cursor_down.svg';

// 3RD PARTY
import classNames from 'classnames';

// UTILS
import { useTranslate } from 'utils/translator';
import { breakpoints, useBreakpoint } from 'utils/hooks';
import { isValid } from 'utils/numbers';

// OTHER COMPONENTS
import { Button } from 'ui/basic';
import styles from './QuestionBubbles.module.scss';


// STORE

// CONFIG & DATA
const CONFIG = {
  answerDelay: 200,
};

// COMPONENT: QuestionBubbles
const QuestionBubbles = (props) => {
  // PROPS
  const {
    question = {},
    range = [],
    selectedValue,
    clickBlock,
    localBlock,
    setLocalBlock = () => {},
    onAnswer = () => {},
    onAnimation = () => {},
    onHelp = () => {},
    allowAnswerSkip = false,
  } = props;

  // COMPONENT/UI STATE and REFS
  const translate = useTranslate();
  const bp = useBreakpoint();

  // useAnswerOptions
  const [ rangeInternal, setRangeInternal ] = useState([]);
  useEffect(() => {
    let answerOptionsInternal;
    if (question.useAnswerOptions && question.answerOptions?.length) {
      answerOptionsInternal = [ ...question.answerOptions ];
    } else {
      answerOptionsInternal = [ ...range ];
    }

    if ((allowAnswerSkip || question.skipAnswer)
      && !answerOptionsInternal.find((option) => option.id === 'skipAnswerOption')
    ) {
      answerOptionsInternal.push({
        id: 'skipAnswerOption',
        label: question.skip_option_label || translate('question_can_not_answer_label'),
        value: null,
      });
    }

    setRangeInternal(answerOptionsInternal);
  }, [
    translate,
    question,
    allowAnswerSkip,
    range,
  ]);

  // selectedValue
  const [ internalIndex, setInternalIndex ] = useState();
  useEffect(() => {
    const selectedOption = rangeInternal.filter((option) => option.value === selectedValue)[0];
    if (selectedOption) {
      const selectedIndex = rangeInternal.findIndex((option) => option === selectedOption);
      setInternalIndex(selectedIndex);
    }
  }, [ selectedValue, rangeInternal ]);

  // answered & animate
  const [ answered, setAnswered ] = useState();
  const [ animate, setAnimate ] = useState();

  // SPECIAL HOOKS

  // EFFECT HOOKS

  // STORE HOOKS

  // METHODS
  const handleAnswer = useCallback((index) => {
    if (clickBlock || localBlock?.current || answered) return;
    const selectedOption = rangeInternal[index];
    if (!selectedOption) return;
    const { value } = selectedOption;
    if (Number.isNaN(Number(value))) return;

    setLocalBlock();
    setInternalIndex(index);
    setAnswered(true);
    setTimeout(() => {
      setAnimate(true);
      onAnimation();
      setTimeout(() => {
        onAnswer(value, CONFIG.answerDelay);
      }, Number(styles.animationDurationMs));
    }, CONFIG.answerDelay);
  }, [
    clickBlock,
    answered,
    rangeInternal,
    localBlock,
    setLocalBlock,
    onAnimation,
    onAnswer,
  ]);

  // KEYBOARD CONTROLS
  const handleKeyUp = useCallback((event) => {
    // NUMBER CONTROLS
    const selectedOption = Number(event.key);
    if (selectedOption <= rangeInternal.length) {
      event.preventDefault();

      // since the 10th option is linked to key 0 we need to set the index to 9 separately
      const indexInternal = selectedOption === 0 ? 9 : selectedOption - 1;
      handleAnswer(indexInternal);
    }
  }, [ handleAnswer, rangeInternal ]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [ handleKeyUp ]);

  // HELPERS

  // RENDERS
  const rangesWithoutSkip = rangeInternal.filter(({ id }) => id !== 'skipAnswerOption');
  const hintTranslation = translate(
    'assessment_selection_items__hint',
    [ '{{endNumber}}', rangesWithoutSkip.length <= 10 ? rangesWithoutSkip.length % 10 : '' ],
  );

  const renderHint = () => {
    const hint = [];
    const split = hintTranslation.split('. ');

    if (split && split[1]) { // split[1] - getting the second sentence
      split[1]
      .split('{{key arrow up / key arrow down}}')
      .forEach((s, index) => {
        if (index === 0) {
          hint.push(
            <div className={styles.line2} key={`H${index}`}>
              <span>{ s }</span>
              <CursorUp />
              <CursorDown />
            </div>,
          );
        } else {
          hint.push(<span key={`H${index}`}>{ s }</span>);
        }
      });
    } else {
      hint.push(split[0]);
    }

    return hint;
  };

  // RENDER: QuestionBubbles
  return (
    <div
      className={classNames(styles.questionBubbles, {
        [styles.animate]: animate,
      })}
      data-test='QuestionBubbles'
      data-id={question.id}
    >

      { /* QUESTION LABEL */ }
      { question.questionLabel && (
        <div className={styles.questionLabel}>
          { question.questionLabel }
        </div>
      ) }

      { /* QUESTION TITLE */ }
      <div
        data-test='QuestionBubblesTitle'
        className={classNames(styles.questionTitle, {
          [styles.isBlue]: question.questionLabel || question.questionDescription,
        })}
      >
        { isValid(question.number) ? `${question.number} - ${question.question}` : question.question }
      </div>

      { /* QUESTION DESCRIPTION */ }
      { question.questionDescription && (
        <div className={styles.questionDescription}>
          { translate(question.questionDescription) }
        </div>
      ) }

      { /* OPTIONS CONTAINER */ }
      <div className={styles.optionsContainer}>

        { /* OPTIONS */ }
        <div className={styles.options}>

          { rangeInternal.map((option, index) => (
            <React.Fragment key={index}>
              <div
                className={classNames(styles.option, {
                  [styles.skipAnswerOption]: option.id === 'skipAnswerOption',
                  [styles.selected]: index === internalIndex,
                })}
                onClick={() => {
                  handleAnswer(index);
                }}
                role='presentation'
                data-test='Option'
              >

                { /* CHECKBOX */ }
                <div className={styles.checkbox} />

                { /* LABEL LIGHT */ }
                { option.labelLight && !question.hideLabelLight && bp.bpWidth > breakpoints.S.bpWidth && (
                  <div className={styles.labelLight}>
                    { option.labelLight }
                  </div>
                ) }

                { /* LABEL */ }
                <div className={styles.label}>
                  { translate(option.label || option.translationKey || option.translationFallback) }
                </div>
              </div>
            </React.Fragment>
          )) }
        </div>
      </div>

      { /* HELP WITH SELECTION BUTTON */ }
      { !question.hideHelp && (
        <div className={styles.helpButton}>
          <Button
            size='S'
            looks='tertiary'
            onClick={onHelp}
          >
            { translate('assessment_help_button') }
          </Button>
        </div>
      ) }

      { /* HINT */ }
      { bp.bpWidth > breakpoints.M.bpWidth && (
        <div className={styles.hint}>
          <div className={styles.line2}>
            { `${hintTranslation.split('. ')[0]}.` }
          </div>

          <div className={styles.line2}>{ renderHint() }</div>
        </div>
      ) }

    </div>
  );
};

export default QuestionBubbles;
