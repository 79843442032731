import React, { useEffect, useState } from 'react';
import styles from './UserInvitation.module.scss';

/* ASSETS */
import { Icons } from 'assets/icons';

/* 3RD PARTY */
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import * as fromCurrentUserSelectors from 'store/selectors/currentUser';

/* STORE */
import {
  addCompanyUsers, getPlatformRoles, getSettings, initAddCompanyUsers,
} from 'store/actions';
import * as rolesSelector from 'store/selectors/roles';
import { selectCompanyFeatures } from 'store/selectors/settings';
import {
  selectCapabilitiesNext,
} from 'store/selectors/configuration';

/* UTILS */
import { useTranslate } from 'utils/translator';
import { getFilteredRoles } from 'utils/roles';
import REGEXES from 'utils/configuration/const/regexes';
import { MAPPED_PLATFORM_ROLES, PLATFORM_ROLES } from 'utils/configuration/const/roles';

/* OTHER COMPONENTS */
import {
  Icon, Button, DropDown, Toggle, BluCSSTransition, TextArea, HotChips,
} from 'ui/basic';


const ROLES_FOR_COMPANY_LEADER = [
  { name: PLATFORM_ROLES.COMPANY_USER },
  { name: PLATFORM_ROLES.CANDIDATE, accessKey: 'candidatesInvite' },
];

const getRoleOptionLabel = (role, translate) => (
  <div className={styles.option}>
    <div className={styles.optionTitle}>{ translate(`platform_roles_${role}`) || role.toLowerCase() }</div>
    <div className={styles.optionDescription}>
      { translate(`platform_roles_descr_${role}`) }
    </div>
  </div>
);


const UserInvitation = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();

  const customEmailBaseValue = translate('invite_emp_customization_default');

  const [ emails, setEmails ] = useState([]);
  const [ userGroup, setUserGroup ] = useState();
  const [ customEmail, setCustomEmail ] = useState(customEmailBaseValue);
  const [ showCustomEmailBlock, setShowCustomEmailBlock ] = useState(false);
  const [ platformRoles, setPlatformRoles ] = useState();

  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const capabilitiesNext = useSelector(selectCapabilitiesNext);

  const allPlatformRoles = useSelector((state) => rolesSelector.getPlatformRoles(state));
  const addCompanyUsersProcessing = useSelector((state) => state.admin.addCompanyUsersProcessing);
  const addCompanyUsersSuccess = useSelector((state) => state.admin.addCompanyUsersSuccess);
  const addCompanyUsersError = useSelector((state) => state.admin.error);

  useEffect(() => {
    dispatch(getPlatformRoles());
  }, [ dispatch ]);

  const companyFeatures = useSelector(selectCompanyFeatures);
  const [ companyFeaturesRequested, setCompanyFeaturesRequested ] = useState(false);
  useEffect(() => {
    if (companyFeatures.length || companyFeaturesRequested) {
      return;
    }

    setCompanyFeaturesRequested(true);
    dispatch(getSettings());
  }, [ dispatch, companyFeatures, companyFeaturesRequested ]);

  useEffect(() => {
    if (addCompanyUsersSuccess) {
      history.goBack();
    }
  }, [ addCompanyUsersSuccess, history ]);

  useEffect(() => {
    const filteredRoles = getFilteredRoles(allPlatformRoles, false);

    setPlatformRoles(filteredRoles
    .filter((role) => {
      if (me.role !== MAPPED_PLATFORM_ROLES.COMPANY_LEADER) {
        return true;
      }

      const thisRole = ROLES_FOR_COMPANY_LEADER.find((r) => r.name === role);
      return !!(thisRole && (!thisRole.accessKey || capabilitiesNext[thisRole.accessKey]));
    })
    .map((role) => ({
      value: role,
      label: getRoleOptionLabel(role.split('_CUSTOM')[0].toLowerCase(), translate),
    })));
  }, [ allPlatformRoles, translate, capabilitiesNext, me ]);

  useEffect(() => {
    if (!platformRoles?.length) {
      return;
    }

    const splitValues = window.location.search.split('?type=');
    const type = splitValues[1];
    const defaultRole = type === 'candidate' ? PLATFORM_ROLES.CANDIDATE : PLATFORM_ROLES.COMPANY_USER;
    const newUserGroup = platformRoles.find(el => el.value === defaultRole) ?? platformRoles[0];
    setUserGroup(newUserGroup);
  }, [ platformRoles ]);

  // COMPONENT UNMOUNT
  useEffect(() => () => {
    dispatch(initAddCompanyUsers());
  }, [ dispatch ]);

  const handleSubmit = () => {
    const mails = emails
    .filter((email) => email.length > 0)
    .map((email) => ({
      mail: email,
      role: userGroup.value,
      features: [],
    }));

    const message = customEmail !== customEmailBaseValue
      ? customEmail
      : undefined;

    dispatch(addCompanyUsers({
      mails,
      message,
    }));
  };

  if (!platformRoles) {
    return null;
  }

  return (
    <div className={styles.userInvitation}>
      <div className={styles.header}>
        <span>{ translate('invite_emp_header_title') }</span>
        <div
          className={styles.close}
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon icon={Icons.CloseBig} />
        </div>
      </div>

      <div className={styles.bodyContainer}>
        <div className={styles.body}>
          <div className={styles.main}>

            { /* TITLE */ }
            <div className={styles.title}>
              { translate('by_invite_emp_title') }
            </div>
            <div className={styles.emails}>

              { /* DESCRIPTION */ }
              <div className={styles.description}>
                { translate('invite_emp_emails_description') }
              </div>

              { /* HOTCHIPS */ }
              <div className={styles.hotChips}>
                <HotChips
                  label={translate('invite_emp_emails_label')}
                  placeholder={translate('invite_emp_emails_placeholder')}
                  hint={translate('invite_emp_emails_hint')}
                  errorHint={translate('invite_emp_emails_hint_invalid')}
                  errorHintDuplicate={translate('invite_emp_emails_hint_duplicate')}
                  confirmHint={translate('invite_emp_emails_confirm_hint')}
                  values={emails}
                  onUpdate={(emailsParam) => setEmails(emailsParam)}
                  validate={{
                    pattern: REGEXES.EMAIL,
                  }}
                />
              </div>

              { /* ERROR */ }
              <div className={styles.error}>
                { addCompanyUsersError && addCompanyUsersError.errorMessage }
              </div>

            </div>

            { /* USER GROUPS */ }
            <div className={styles.userGroups}>
              <div className={styles.description}>
                { translate('invite_emp_usergroups_description') }
              </div>
              <div className={styles.select}>
                <DropDown
                  size='L'
                  disabled={platformRoles && platformRoles.length === 1}
                  selectedOption={userGroup}
                  placeholder={translate('invite_emp_usergroups_placeholder')}
                  options={platformRoles}
                  onChange={(option) => setUserGroup(option)}
                />
              </div>
            </div>

            <div className={styles.customBlock}>
              <div className={styles.visible}>
                <span className='bluTypeLabel'>
                  { translate('invite_emp_customization_title') }
                </span>
                <Toggle onChange={(value) => setShowCustomEmailBlock(value)} />
              </div>
              <BluCSSTransition
                in={showCustomEmailBlock}
                classNames={{ ...styles }}
              >
                <div className={styles.customEmail}>
                  <div className={styles.title}>
                    { translate('invite_emp_customization_description') }
                  </div>
                  <div className={styles.textarea}>
                    <TextArea
                      placeholder={translate('invite_emp_customization_placeholder')}
                      value={customEmail}
                      onChange={(value) => setCustomEmail(value)}
                    />
                  </div>
                </div>
              </BluCSSTransition>
            </div>

            { /* BUTTONS */ }
            <div className={styles.buttons}>
              <Button
                size='S'
                disabled={!emails.length || !userGroup || addCompanyUsersProcessing}
                onClick={handleSubmit}
              >
                { translate('invite_emp_btn_invite') }
              </Button>
              <Button
                size='S'
                looks='secondary'
                onClick={() => history.goBack()}
              >
                { translate('invite_emp_btn_cancel') }
              </Button>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInvitation;
