import React, { useState } from 'react';
import styles from './TeamWorkPrefResult.module.scss';

import * as api from 'api';

import { useTranslate } from 'utils/translator';

import {
  InfoTitle, Card, Modal, TeamDiagramBipolarNext,
} from 'ui/basic';


const TeamWorkPrefResult = (props) => {
  // PROPS
  const { profile, referenceProfile } = props;

  const translate = useTranslate();

  const [ showInfoModal, setShowInfoModal ] = useState(false);

  // METHODS
  const getDimensionsData = () => {
    if (!profile) {
      return [];
    }

    const workPrefDimensions = [];

    profile.results.forEach((workPrefResult) => {
      let range;
      if (referenceProfile && referenceProfile.results) {
        const thisResult = referenceProfile.results.find((r) => r.dimensionId === workPrefResult.id);
        if (thisResult) {
          range = [ thisResult.minValue, thisResult.maxValue ];
        }
      }

      workPrefDimensions.push({
        id: workPrefResult.id,
        label: workPrefResult.name,
        labelLeft: translate(`workpreferences_results_min_dim${workPrefResult.id.split('wp_')[1]}`),
        labelRight: translate(`workpreferences_results_max_dim${workPrefResult.id.split('wp_')[1]}`),
        range,
        data: workPrefResult.values.map((userValue) => {
          const { user, value } = userValue;

          return {
            id: user.id,
            label: user.name,
            img: !user.isAnonymous ? api.getUserImageUrl(user.id) : undefined,
            value,
          };
        }),
      });
    });

    return workPrefDimensions;
  };

  return (
    <Card>
      <InfoTitle
        title={translate('workpreferences_results_title')}
        onClick={() => setShowInfoModal(true)}
      />

      <div className={styles.diagram}>
        <TeamDiagramBipolarNext
          valueRange={[ -5, 5 ]}
          dimensions={getDimensionsData()}
          showRanges={!!referenceProfile}
          labelRange={referenceProfile && (`${translate('subnavigation_tabs_roles_item1')}: `)}
        />
      </div>

      { showInfoModal && (
        <Modal
          header={translate('workpreferences_results_info_title')}
          secondaryButtonTitle={translate('okay_lbl')}
          onClose={() => setShowInfoModal(false)}
        >
          { translate('workpreferences_results_info_description') }
        </Modal>
      ) }
    </Card>
  );
};

export default TeamWorkPrefResult;
