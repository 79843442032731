import { get } from 'api';
import * as action from 'store/actions';
import {
  call,
  put,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects';

export function* getAssessmentResult({ payload }) {
  const id = payload;

  try {
    const { status, ok, data } = yield call(get, `/core/assessments/${id}/result`);

    if (ok && status === 200) {
      yield put(action.getAssessmentResultFulfilled({ id, data }));
    } else {
      const { error } = data;
      yield put(action.getAssessmentResultRejected({ id, error }));
    }
  } catch (error) {
    yield put(action.getAssessmentResultRejected({ id, error }));
  }
}

function* getAssessmentHistory({ payload }) {
  const {
    id,
    params: { offset = 0, userId } = {},
  } = payload;

  try {
    const { status, ok, data } = yield call(get, `/core/assessments/${id}/result/history`, {
      limit: 20,
      offset,
      user: userId,
    });

    if (ok && status === 200) {
      if (userId) {
        yield put(action.getProfileHistoryFulfilled({
          id, history: data, offset, userId,
        }));
      } else {
        yield put(action.getAssessmentHistoryFulfilled({ id, history: data, offset }));
      }

      // Some extra data may be required
      const resultId = data.results?.[offset]?.id;
      yield put(action.getAssessmentReport({ id, userId, resultId }));
    } else {
      yield put(action.getAssessmentHistoryRejected({ id, error: data.error }));
    }
  } catch (error) {
    yield put(action.getAssessmentHistoryRejected({ id, error }));
  }
}

function* getLockedForValue({ payload }) {
  const { id } = payload;

  try {
    const { status, data } = yield call(get, `/core/assessments/${id}/1/locked`);
    if (status === 200) {
      yield put(action.getLockedForValueFulfilled({ id, ...data }));
    } else {
      yield put(action.getLockedForValueRejected({ id, error: data.error }));
    }
  } catch (error) {
    yield put(action.getLockedForValueRejected({ id, error }));
  }
}


export function* watchGetAssessmentResultRequest() {
  yield takeEvery(action.GET_ASSESSMENT_RESULT, getAssessmentResult);
}

export function* watchGetAssessmentHistoryRequest() {
  yield takeLeading(action.GET_ASSESSMENT_HISTORY, getAssessmentHistory);
}

export function* watchGetLockedForValueRequest() {
  yield takeEvery(action.GET_LOCKED_FOR_VALUE, getLockedForValue);
}
