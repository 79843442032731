import React, {memo, useCallback, useEffect, useRef, useState} from 'react';

import styles from './SidePanel.module.scss';
import fadeTransition from './transitions/fade.module.scss';
import transformTransitionLeft from './transitions/transformLeft.module.scss';
import transformTransitionRight from './transitions/transformRight.module.scss';

import classNames from 'classnames';

import { IconsSvg } from 'assets/icons';
import { BluCSSTransition } from 'ui/basic';

import { disableScrollingOnBody, enableScrollingOnBody } from 'utils/scrolling';


const SidePanel = memo((props) => {
  const {
    size = 'S',
    children,
    closeOnCancel = true,
    onClose,
    left = false,
    showArrow = false,
  } = props;

  const containerRef = useRef();
  const panelRef = useRef();

  const [backgroundIsVisible, setBackgroundIsVisible] = useState(false);
  const [panelIsVisible, setPanelIsVisible] = useState(false);

  const transitionStyle = left ? transformTransitionLeft : transformTransitionRight;

  // HOOKS

  const handleClose = useCallback(() => {
    if (!closeOnCancel && onClose) {
      onClose();
      return;
    }

    setBackgroundIsVisible(false);
    setPanelIsVisible(false);

    setTimeout(() => {
      onClose();
    }, 300)
  }, [onClose, closeOnCancel]);

  const handleKeyUp = useCallback((event) => {
    if (event.key === 'Escape') {
      handleClose();
    }
  }, [handleClose]);

  useEffect(() => {
    setBackgroundIsVisible(true);

    setTimeout(() => {
      setPanelIsVisible(true);
    })
  }, []);

  useEffect(() => {
    disableScrollingOnBody();
    return () => {
      enableScrollingOnBody();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyUp);
    }
  }, [handleKeyUp]);

  // RENDERS

  const renderClosingIcon = () => {
    if (showArrow) {
      return left ? <IconsSvg.ArrowLeft /> : <IconsSvg.ArrowRight />;
    }

    return <IconsSvg.CloseBig />;
  }

  return (
    <BluCSSTransition in={backgroundIsVisible} classNames={{ ...fadeTransition }}>
      <div
        className={classNames(styles.sidePanel, transitionStyle.sidePanel)}
        ref={panelRef}
        onClick={event => {
          if (event.target === panelRef.current) {
            handleClose();
          }
        }}
      >
        <BluCSSTransition in={panelIsVisible} classNames={transitionStyle}>
          <div
            ref={containerRef}
            className={classNames(
              styles.container,
              transitionStyle.container,
              styles[`size${size}`],
            )}
          >
            <div
              className={classNames(
                styles.close,
                { [styles.flip]: !left && showArrow },
                { [styles.arrow]: showArrow },
              )}
              onClick={handleClose}
            >
              { renderClosingIcon() }
            </div>
            {children}
          </div>
        </BluCSSTransition>
      </div>
    </BluCSSTransition>
  )
});

export default SidePanel;
