import {
  call, put, take, takeEvery, takeLeading,
} from 'redux-saga/effects';

import * as fromActionTypes from 'store/actionTypes/roles';
import * as fromActions from 'store/actions/roles';
import * as fromAdminActions from 'store/actions/admin';
import { showToast } from 'store/actions';

import * as api from 'api';
import { _delete, get, post } from 'api';
import { all } from '@redux-saga/core/effects';

import { REFERENCE_PROFILE_TYPES } from 'utils/configuration/const/reference-profile';
import { LIMIT } from 'utils/configuration/const/pagination';
import { PLATFORM_ROLES } from 'utils/configuration/const/roles';
import { CAREER_LEVELS_BY_ID } from 'utils/configuration/const/career-level';
import { translate } from 'utils/translator/translator';

// PLATFORM ROLES
function* getPlatformRoles() {
  try {
    const { status, ok, data } = yield call(get, '/core/access/roles');
    if (ok && status === 200 && data.roles) {
      yield put(fromActions.getPlatformRolesFulfilled({ roles: data.roles }));
    } else {
      yield put(fromActions.getPlatformRolesRejected({ error: data }));
    }
  } catch (error) {
    yield put(fromActions.getPlatformRolesRejected({ error }));
  }
}

function* createPlatformRole(createRoleDto) {
  try {
    const { status, ok, data } = yield call(post, '/core/access/roles', createRoleDto.payload);
    if (ok && status === 200) {
      yield put(fromActions.createPlatformRoleFulfilled({ data }));
      yield call(getPlatformRoles);
    } else {
      yield put(fromActions.createPlatformRoleRejected({ error: data }));
    }
  } catch (error) {
    yield put(fromActions.createPlatformRoleRejected({ error }));
  }
}

function* updatePlatformRole({ payload }) {
  try {
    const { roleId, capabilities } = payload.updatePlatformDto;
    const { status, ok, data } = yield call(api.put, `core/access/roles/${roleId}`, { capabilities });
    if (ok && status === 200) {
      yield put(fromActions.updatePlatformRoleFulfilled({ data }));
      yield put(fromActions.getPlatformRoles());
    } else {
      yield put(fromActions.updatePlatformRoleRejected({ error: data }));
    }
  } catch (error) {
    yield put(fromActions.updatePlatformRoleRejected({ error }));
  }
}

export function* watchGetPlatformRoles() {
  yield takeEvery(fromActionTypes.GET_PLATFORM_ROLES, getPlatformRoles);
}

export function* watchCreatePlatformRole() {
  const { payload } = yield take(fromActionTypes.CREATE_PLATFORM_ROLE);
  yield call(createPlatformRole, payload);
}

export function* watchUpdatePlatformRole() {
  yield takeEvery(fromActionTypes.UPDATE_PLATFORM_ROLE, updatePlatformRole);
}

// ORGANISATIONAL ROLES
function* getOrgRoles({ payload }) {
  try {
    let limit = LIMIT; let offset; let sort; let sortOrder; let
      search;

    if (payload.requestDto) {
      const { requestDto } = payload;
      limit = requestDto.limit ? requestDto.limit : LIMIT;
      offset = requestDto.offset;
      sort = requestDto.sort;
      sortOrder = requestDto.sortOrder;
      search = requestDto.search;
    }

    const {
      status, ok, data, headers,
    } = yield call(get, '/rolemapping/roles', {
      limit,
      offset,
      sort,
      sortOrder,
      search,
    });

    const roles = data.roles.map((role) => ({ ...role, usersCount: role.users }));

    if (ok && status === 200) {
      yield put(fromActions.getOrgRolesFulfilled({
        roles,
        totalCount: (headers && headers['x-total-result-count']) ? headers['x-total-result-count'] : 10,
      }));
    } else {
      yield put(fromActions.getOrgRolesRejected({ error: data }));
    }
  } catch (error) {
    yield put(fromActions.getOrgRolesRejected({ error }));
  }
}

// GET ORG ROLE BY ID
function* getOrgRole({ payload }) {
  try {
    const params = new URLSearchParams();
    params.append('expand', 'referenceProfile');
    params.append('expand', 'referenceProfile.references');
    params.append('include', 'referenceProfile.progress.incomplete');

    const {
      status, ok, data, headers,
    } = yield call(get, `/rolemapping/roles/${payload.id}`, params);

    const dataOutdated = headers['X-Outdated-Cache'];

    if (ok && status === 200) {
      let { careerLevel } = data;
      if (careerLevel) {
        const careerLevelObj = CAREER_LEVELS_BY_ID[careerLevel];
        if (careerLevelObj) {
          careerLevel = translate(careerLevelObj.translationKey)
          || careerLevelObj.translationFallback;
        }
      }

      const role = {
        ...data,
        careerLevel,
        careerLevelId: data.careerLevel,
      };

      // for manual roles profile values will be stored in 'generated property'
      // (for consistency with auto roles)
      if (role.referenceProfile.type === REFERENCE_PROFILE_TYPES.MANUAL) {
        const profile = [ ...role.referenceProfile.profile ];
        role.referenceProfile.profile = { generated: profile };
      }

      yield put(fromActions.getOrgRoleFulfilled({ role: { ...role, dataOutdated } }));
    } else {
      yield put(fromActions.getOrgRoleRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(fromActions.getOrgRoleRejected({ error }));
  }
}

function* updateOrgRole({ payload }) {
  try {
    const {
      id,
      name,
      description,
      careerLevel,
      jobFamily,
      details,
      referenceProfile,
      assessmentValues,
      newUsers = [],
    } = payload.updateRoleDto;

    const updateRefProfile = referenceProfile && typeof referenceProfile === 'object';
    let refProfileResponse;

    if (updateRefProfile) {
      // update of reference profile for manual roles
      if (referenceProfile.type === REFERENCE_PROFILE_TYPES.MANUAL) {
        refProfileResponse = yield call(api.put, `/core/profiles/${referenceProfile.id}`, {
          name: referenceProfile.name,
          type: referenceProfile.type,
          profile: referenceProfile.profile,
        });
      } else {
        // update of reference profile for auto role
        refProfileResponse = yield call(api.put, `core/profiles/${referenceProfile.id}`, {
          name: referenceProfile.name,
          type: referenceProfile.type,
          references: referenceProfile.references,
          assessments: referenceProfile.assessments,
        });

        // update of current assessment values
        if (assessmentValues) {
          yield call(api.put, `core/profiles/${referenceProfile.id}/values`, {
            profile: assessmentValues,
          });
        }
      }
    }

    let roleResponse;
    if (updateRefProfile && refProfileResponse.ok && refProfileResponse.status === 200) {
      roleResponse = yield call(api.put, `/rolemapping/roles/${id}`, {
        name,
        description,
        careerLevel,
        jobFamily,
        referenceProfile: referenceProfile.id,
      });
    } else {
      roleResponse = yield call(api.put, `/rolemapping/roles/${id}`, {
        name,
        description,
        careerLevel,
        jobFamily,
        details,
        referenceProfile,
      });
    }

    if (roleResponse.ok && roleResponse.status === 200) {
      // add new users to company
      if (newUsers.length > 0) {
        const addCompanyUsersResponse = yield call(api.put, '/core/company/users', {
          mails: newUsers.map((newUserMail) => ({
            mail: newUserMail, role: PLATFORM_ROLES.COMPANY_USER,
          })),
        });

        if (addCompanyUsersResponse.ok && addCompanyUsersResponse.status === 200) {
          const { users } = addCompanyUsersResponse.data;
          const usersIds = users.map((user) => ({ userId: user }));

          // if users were invited successfully,
          // reference profile of the role should be updated with new references
          yield call(api.put, `core/profiles/${referenceProfile.id}`, {
            name: 'RP',
            type: referenceProfile.type,
            references: [ ...referenceProfile.references, ...usersIds ],
            assessments: referenceProfile.assessments,
          });
        } else {
          const { error } = addCompanyUsersResponse.data;
          if (error?.errorCode === 8001) {
            error.errorMessage = translate('exceeded_trial_limit');
            // eslint-disable-next-line no-underscore-dangle
            const message = error.errorMessage.props.dangerouslySetInnerHTML.__html;
            const [ , title, content ] = message.match(/<strong>(.+)<\/strong>[\w\W]*<p>(.+)<\/p>/);
            yield put(showToast(title, content));
          }

          yield put(fromAdminActions.addCompanyUsersRejected({ error }));
          yield put(fromActions.updateOrgRoleRejected({ error }));
          return;
        }

        yield put(fromAdminActions.addCompanyUsersFulfilled());
      }

      yield put(fromActions.updateOrgRoleFulfilled());
    } else {
      const { error } = roleResponse.data;
      yield put(fromActions.updateOrgRoleRejected({ error }));
    }
  } catch (error) {
    yield put(fromActions.updateOrgRoleRejected({ error }));
  }
}

function* createOrgRole({ payload }) {
  try {
    const {
      name, description, careerLevel, jobFamily, referenceProfile, newUsers = [],
    } = payload.newRoleDto;

    // ADD NEW USERS TO COMPANY
    let newUsersIds = [];
    if (newUsers.length > 0) {
      try {
        const addCompanyUsersResponse = yield call(api.put, '/core/company/users', {
          mails: newUsers.map((newUserMail) => ({
            mail: newUserMail, role: PLATFORM_ROLES.COMPANY_USER,
          })),
        });

        if (addCompanyUsersResponse.ok && addCompanyUsersResponse.status === 200) {
          const { users } = addCompanyUsersResponse.data;
          newUsersIds = users.map((user) => ({ userId: user }));
        } else {
          const { error } = addCompanyUsersResponse.data;
          if (error?.errorCode === 8001) {
            error.errorMessage = translate('exceeded_trial_limit');
            // eslint-disable-next-line no-underscore-dangle
            const message = error.errorMessage.props.dangerouslySetInnerHTML.__html;
            const [ , title, content ] = message.match(/<strong>(.+)<\/strong>[\w\W]*<p>(.+)<\/p>/);
            yield put(showToast(title, content));
          }

          yield put(fromAdminActions.addCompanyUsersRejected({ error }));
          yield put(fromActions.createOrgRoleRejected({ error }));
          return;
        }

        yield put(fromAdminActions.addCompanyUsersFulfilled());
      } catch (error) {
        yield put(fromAdminActions.addCompanyUsersRejected({ error }));
      }
    }

    // CREATE REFERENCE PROFILE
    const referenceProfilePayload = { ...referenceProfile };
    if (referenceProfile && referenceProfile.references) {
      Object.assign(referenceProfilePayload, {
        references: referenceProfile.references.concat(newUsersIds),
      });
    }

    const referenceProfileResponse = yield call(post, '/core/profiles', referenceProfilePayload);
    const referenceProfileId = referenceProfileResponse.data.id;

    if (referenceProfileResponse.ok && referenceProfileResponse.status === 200) {
      // CREATE ORGANIZATIONAL ROLE
      const roleResponse = yield call(post, '/rolemapping/roles', {
        name,
        description,
        careerLevel,
        jobFamily,
        referenceProfile: referenceProfileId,
      });

      if (roleResponse.ok && roleResponse.status === 200) {
        yield put(fromActions.createOrgRoleFulfilled({ roleId: roleResponse.data.id }));
      } else {
        const { error } = roleResponse.data;
        yield put(fromActions.createOrgRoleRejected({ error }));
      }
    }
  } catch (error) {
    yield put(fromActions.createOrgRoleRejected({ error }));
  }
}

function* deleteOrgRole({ payload }) {
  try {
    const { roleId, roleName } = payload;
    const { ok, status, data } = yield call(_delete, `/rolemapping/roles/${roleId}`);

    if (ok && status === 200) {
      yield put(fromActions.deleteOrgRoleFulfilled({
        deletedRoleId: roleId,
        deletedRoleName: roleName,
      }));
    } else {
      yield put(fromActions.deleteOrgRoleRejected({ error: data.error }));
    }
  } catch (error) {
    yield put(fromActions.deleteOrgRoleRejected({ error }));
  }
}

function* getReferencesProfiles({ payload }) {
  try {
    const { ids } = payload;

    const userProfiles = yield all(ids.map((id) => call(get, 'core/user/profile', { id })));

    const validUserProfiles = {};
    userProfiles
    .filter((response) => response.ok && response.status === 200)
    .forEach((response) => {
      validUserProfiles[response.config.params.id] = response.data.profile;
    });

    yield put(fromActions.getReferencesProfilesFulfilled({
      referencesProfiles: validUserProfiles,
    }));
  } catch (error) {
    yield put(fromActions.getReferencesProfilesRejected({
      error: { errorMessage: error.message },
    }));
  }
}

function* getExpandedReferences({ payload }) {
  try {
    const { ids } = payload;

    const userInfos = yield all(ids.map((id) => call(get, 'core/user/info', {
      id,
      expand: 'roles',
    })));

    const validUserInfos = {};
    userInfos
    .filter((response) => response.ok && response.status === 200)
    .forEach((response) => {
      validUserInfos[response.config.params.id] = response.data;
    });

    yield put(fromActions.getExpandedReferencesFulfilled({
      expandedReferences: validUserInfos,
    }));
  } catch (error) {
    yield put(fromActions.getExpandedReferencesRejected({
      error: { errorMessage: error.message },
    }));
  }
}

export function* watchGetOrgRoles() {
  yield takeEvery(fromActionTypes.GET_ORG_ROLES, getOrgRoles);
}

export function* watchGetOrgRole() {
  yield takeEvery(fromActionTypes.GET_ORG_ROLE, getOrgRole);
}

export function* watchCreateOrgRole() {
  yield takeEvery(fromActionTypes.CREATE_ORG_ROLE, createOrgRole);
}

export function* watchUpdateOrgRole() {
  yield takeEvery(fromActionTypes.UPDATE_ORG_ROLE, updateOrgRole);
}

export function* watchDeleteOrgRole() {
  yield takeEvery(fromActionTypes.DELETE_ORG_ROLE, deleteOrgRole);
}

export function* watchGetReferencesProfiles() {
  yield takeEvery(fromActionTypes.GET_REFERENCES_PROFILES, getReferencesProfiles);
}

export function* watchGetExpandedReferences() {
  yield takeLeading(fromActionTypes.GET_EXPANDED_REFERENCES, getExpandedReferences);
}
